import validateJs from 'validate.js' // eslint-disable-line

const validateNotEmail = (value, options) => {
  if (!options || !value) return

  const defaultMessage = ' cannot be in email format.'
  const emailRegEx = /\S+@\S+\.\S+/

  if (emailRegEx.test(value)) return options.message || defaultMessage
}

export default validateNotEmail

validateJs.validators.validateNotEmail = validateNotEmail