import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'

const reducerKey = 'boats'
const slice = 'root.memberPortal.memberships.boats'

const initialState = {}

const reducer = handleActions({}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  homePortType: select('homePortType'),
}

export { reducer, selectors, reducerKey }
