import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ACCOUNT_DETAIL_TYPE, API_KEY_MAP, HEROKU_ID_KEY } from 'types'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { MemberContactInfoForm } from 'member-portal/forms'
import { COUNTRY, PATH, makeMembershipsPath } from 'config'
import {
  apiValuesWithMappedKeys,
  stripNoneTrailerCareType,
  useConfirm,
  handleSubmitFailWithFlashMessage,
} from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  createMembershipApplicationAccount: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  shoppingCartMembershipType: PropTypes.string.isRequired,
  successPath: PropTypes.string.isRequired,
  verifyUniquePrimaryEmail: PropTypes.func.isRequired,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function NewMemberContactInfo({
  autoCompleteAddress,
  createMembershipApplicationAccount,
  fetchPlaceDetails,
  selectedTrailerCareType,
  shoppingCartMembershipType,
  successPath,
  verifyUniquePrimaryEmail,
  updateMembershipApplicationAccount,
}) {
  const history = useHistory()
  const { isConfirmed: confirmRenewal } = useConfirm()

  const renewalModalAttributes = {
    heading: 'Email Address Already on File',
    prompt: `The email provided is already associated with a Sea Tow membership(s),
      would you instead like to log in and renew your existing membership?
      If you would like to create a new account with this email or add a new
      membership to your existing account, please contact Sea Tow for assistance.`,
    confirmLabel: 'Log In and Renew',
    cancelLabel: 'Cancel',
  }

  const checkForRenewal = useCallback(
    (email) =>
      new Promise((resolve, reject) => {
        // Check if primary contact already has a membership with this email
        verifyUniquePrimaryEmail(email).then((account) => {
          const {
            contact_exists,
            abandoned_account,
            account_hc_ext_id,
            membership_hc_ext_id,
          } = account
          if (contact_exists) {
            // If contact exists but account creation process was abandoned before completing
            if (abandoned_account) {
              resolve({
                isAbandoned: true,
                accountId: account_hc_ext_id,
                membershipId: membership_hc_ext_id,
              })
            } else {
              // If yes, check if they want to renew or continue to create a new account
              confirmRenewal(renewalModalAttributes).then((confirmed) => {
                if (confirmed) {
                  history.push(
                    makeMembershipsPath(
                      PATH.SELECT_YOUR_MEMBERSHIP,
                      PATH.RENEW_MEMBERSHIP
                    )
                  )
                } else {
                  reject({ isAbandoned: false })
                }
              })
            }
          } else {
            resolve({ isAbandoned: false })
          }
        })
      }),
    []
  )

  return (
    <MemberContactInfoForm
      autoCompleteAddress={autoCompleteAddress}
      fetchPlaceDetails={fetchPlaceDetails}
      initialValues={{
        account: {
          country: COUNTRY.UNITED_STATES,
          accountDetailType: ACCOUNT_DETAIL_TYPE.CUSTOMER_RETAIL,
        },
      }}
      onSubmit={async (formValues) => {
        const checkForAccount = await checkForRenewal(formValues.contact.email)
        const {
          isAbandoned,
          accountId = null,
          membershipId = null,
        } = checkForAccount

        const accountApiValues = apiValuesWithMappedKeys(
          formValues.account,
          API_KEY_MAP.ACCOUNT_KEY_MAP
        )
        const contactApiValues = apiValuesWithMappedKeys(
          formValues.contact,
          API_KEY_MAP.CONTACT_KEY_MAP
        )

        // If this account is not abanadoned (new/rejected renewal) call create
        if (!isAbandoned) {
          return createMembershipApplicationAccount({
            account: accountApiValues,
            contact: contactApiValues,
            membership: {
              shopping_cart_membership_type__c: shoppingCartMembershipType,
              shopping_cart_trailer_care_type__c: stripNoneTrailerCareType(
                selectedTrailerCareType
              ),
            },
          })
        }

        // If it was abadoned, patch to existing account
        return updateMembershipApplicationAccount({
          account: {
            [HEROKU_ID_KEY]: accountId,
            ...accountApiValues,
          },
          contact: contactApiValues,
          membership: {
            [HEROKU_ID_KEY]: membershipId,
            shopping_cart_membership_type__c: shoppingCartMembershipType,
            shopping_cart_trailer_care_type__c: stripNoneTrailerCareType(
              selectedTrailerCareType
            ),
          },
        })
      }}
      onSubmitSuccess={() => history.push(successPath)}
      onSubmitFail={handleSubmitFailWithFlashMessage}
    />
  )
}

NewMemberContactInfo.propTypes = propTypes

export default pure(NewMemberContactInfo)
