import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

// An internal link styled as a button...
function ButtonLink({ children, className = 'button-secondary', to, ...rest }) {
  return (
    <Link to={to} {...rest} className={className}>
      {children}
    </Link>
  )
}

ButtonLink.propTypes = propTypes

export default pure(ButtonLink)
