/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { isNil } from 'lodash'
import { AccountProvider } from 'global-account/components'
import { PATH, USER_METADATA, makeMemberPortalPath } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function MemberAccountProvider({ children }) {
  const { user } = useAuth0()
  const memberAccountId = user[USER_METADATA.MEMBER_ACCOUNT_ID]

  if (isNil(memberAccountId)) {
    // In this scenario, no membership metadata exists in Auth0. This will
    // occur if either a brand new member (a member directed here after completing the
    // new member application process) or an existing member (a member that already has
    // a membership but never logged into the Member Portal before and manually
    // executed the "Sign Up" flow) is trying to log in for the first time.
    // Brand new members will have their membership number and expiration date
    // included as location.state data. Existing memberss won't have any prior state.
    const { search, state } = useLocation()
    const membershipExpirationDate = state?.membershipExpirationDate
    const membershipNumber = state?.membershipNumber

    if (membershipExpirationDate && membershipNumber) {
      return (
        <Redirect
          to={{
            pathname: makeMemberPortalPath(PATH.REGISTRATION, PATH.NEW_MEMBER),
            state,
          }}
        />
      )
    }

    return (
      <Redirect
        to={{
          search,
          pathname: makeMemberPortalPath(
            PATH.REGISTRATION,
            PATH.EXISTING_MEMBER
          ),
          state,
        }}
      />
    )
  }

  return (
    <AccountProvider accountId={memberAccountId}>{children}</AccountProvider>
  )
}

MemberAccountProvider.propTypes = exact(propTypes)

export default pure(MemberAccountProvider)
