import { difference, union } from 'lodash'
import { EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES } from 'types'

export default function mergeCommunicationPreferences(
  newPreferencesString,
  currentPreferencesString
) {
  // First, remove all of the preferences settable by the user from the current
  // preferences...
  const currentPreferences =
    currentPreferencesString.length > 0
      ? currentPreferencesString.split(';')
      : []
  const nonUserPreferences = difference(
    currentPreferences,
    Object.values(EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES)
  )

  // Then merge the new preferences with the non-user-settable preferences...
  const newPreferences =
    newPreferencesString.length > 0 ? newPreferencesString.split(';') : []
  const mergedPreferences = union(newPreferences, nonUserPreferences)

  // Finally, return the merged string result...
  return mergedPreferences.join(';')
}
