import validateJs from 'validate.js' // eslint-disable-line
import { intersection } from 'lodash'
import { isContact2Path, validateNotEmail } from 'utils'


const validateStreetAddress = (value, options, _attributes, key) => {
  if (!options || !value) return

  const isNewContactForm = isContact2Path()

  const emailValidationError = validateNotEmail(value, options)
  if (emailValidationError) {
    return emailValidationError
  }

  const defaultMessage = ` must be a selection from the provided suggested options${isNewContactForm ? ', or manually entered by clicking the button below' : ''}.`

  const formKeys = Object.keys(key.account)

  if (intersection(['state', 'city', 'postalCode'], formKeys).length === 0) {
    return options.message || defaultMessage
  }
}

export default validateStreetAddress

validateJs.validators.validateStreetAddress = validateStreetAddress