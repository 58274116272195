import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'

const propTypes = {
  linkText: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

function BackLink({ linkText = 'Back', to }) {
  return (
    <Link to={to} className="back-link">
      <MdKeyboardArrowLeft />
      {linkText}
    </Link>
  )
}

BackLink.propTypes = exact(propTypes)

export default pure(BackLink)
