import { flashErrorMessage } from 'redux-flash'
import errorMessageFromFormErrors from './errorMessageFromFormErrors'

// Generate a flash error message for form submission failures
export default function handleSubmitFailWithFlashMessage(
  errors,
  dispatch,
  submitError
) {
  const errorMessage = errorMessageFromFormErrors(errors, submitError)
  errorMessage && dispatch(flashErrorMessage(errorMessage))
}
