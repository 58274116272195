import React from 'react'
import { PATH, makeMemberApplicationPath } from 'config'
import { LocatePortSection } from 'memberships/boats/components'

const propTypes = {}

function NewMemberLocatePort() {
  return (
    <>
      <LocatePortSection
        previousStep={makeMemberApplicationPath(
          PATH.BOATS,
          PATH.HOME_PORT_ADDRESS
        )}
      />
    </>
  )
}

NewMemberLocatePort.propTypes = propTypes

export default NewMemberLocatePort
