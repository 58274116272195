import { APPLICATION, PATH } from 'config'

const getApplicationPath = (application) => {
  switch (application) {
    case APPLICATION.MEMBER_PORTAL:
      return PATH.MEMBER_PORTAL

    case APPLICATION.PROGRAMS_PORTAL:
      return PATH.PROGRAMS_PORTAL

    default:
      return PATH.ROOT
  }
}

export default getApplicationPath
