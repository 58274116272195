import { handleActions } from 'redux-actions'
import { compose } from 'recompose'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { set, unset } from 'lodash/fp'
import * as actions from './actions'
import { GIFT_DELIVERY_METHOD } from 'types'

const reducerKey = 'seaTowAsAGift'
const slice = 'root.memberPortal.seaTowAsAGift'

const initialState = {
  deliveryMethod: GIFT_DELIVERY_METHOD.MAILED,
  giftWrapped: false,
  mailedToGifter: false,
}
const reducer = handleActions(
  {
    // Membership type selection
    [actions.setSelectedMembershipType]: setState('selectedMembershipType'),
    // Delivery method
    [actions.clearForNewDeliveryMethod]: (state) => {
      return compose(
        unset('billingContact'),
        set('giftWrapped', false),
        set('mailedToGifter', false),
        unset('recipientContact'),
        unset('shippingMethod')
      )(state)
    },
    [actions.setDeliveryMethod]: setState('deliveryMethod'),
    [actions.setGiftWrapped]: setState('giftWrapped'),
    [actions.setShippingMethod]: setState('shippingMethod'),
    // Gift Recipient
    [actions.setGiftMessage]: setState('giftMessage'),
    [actions.setMailedToGifter]: setState('mailedToGifter'),
    [actions.setRecipientContact]: setState('recipientContact'),
    // Payment
    [actions.setBillingContact]: setState('billingContact'),
    [actions.setCreditCard]: setState('creditCard'),
    // Confirmation
    [actions.clearAllGiftState]: () => initialState,
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  billingContact: select('billingContact'),
  creditCard: select('creditCard'),
  deliveryMethod: select('deliveryMethod'),
  giftMessage: select('giftMessage'),
  giftWrapped: select('giftWrapped'),
  invoice: select('invoice'),
  mailedToGifter: select('mailedToGifter'),
  recipientContact: select('recipientContact'),
  recipientType: select('recipientType'),
  selectedMembershipType: select('selectedMembershipType'),
  shippingMethod: select('shippingMethod'),
}

export { reducer, selectors, reducerKey }
