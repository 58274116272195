import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  message: PropTypes.string.isRequired,
}

function UnexpectedError({ message }) {
  return (
    <div>
      <h1>🐞 Unexpected Error</h1>
      <p>
        <strong>{message}</strong>
      </p>
    </div>
  )
}

UnexpectedError.propTypes = exact(propTypes)

export default pure(UnexpectedError)
