import { isNil } from 'lodash'
import { format } from 'date-fns'

const formatISODate = (date) => {
  if (isNil(date)) return 'N/A'

  return format(date, 'yyyy-MM-dd')
}

export default formatISODate
