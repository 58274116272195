import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { reset } from 'redux-form'
import { flashErrorMessage, flashSuccessMessage } from 'redux-flash'
import { ButtonLink } from 'components'
import {
  DigitalWalletPass,
  MembershipPageHeader,
  MembershipTypeDisplay,
} from 'memberships/components'
import { NewMembershipCardForm } from '../forms'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors as membershipsSelector } from 'memberships/reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import {
  API_KEY_MAP,
  AccountType,
  FulfillmentArrayType,
  MembershipType,
} from 'types'
import { DIGITAL_WALLET_TYPE, makeMemberPortalPath, PATH } from 'config'
import { apiValuesWithMappedKeys, makeQueryPath, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType,
  clearMembershipCardOrderStatus: PropTypes.func.isRequired,
  fetchMembershipCardOrderStatus: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  fulfillments: FulfillmentArrayType,
  membership: MembershipType,
  requestCardReprint: PropTypes.func.isRequired,
}

function MembershipCard({
  account,
  clearMembershipCardOrderStatus,
  fetchMembershipCardOrderStatus,
  flashSuccessMessage,
  fulfillments,
  membership,
  requestCardReprint,
}) {
  const {
    shippingstreet,
    shippingcity,
    shippingstate,
    shippingpostalcode,
    shippingcountry,
  } = account
  const {
    apple_pass__c: applePassUrl,
    google_pass__c: googlePassUrl,
    membership_number__c,
    membership_type__c,
    sfid: membershipId,
  } = membership

  const hasEitherDigitalPass = !!applePassUrl || !!googlePassUrl

  useEffect(() => {
    fetchMembershipCardOrderStatus(membershipId)

    return () => clearMembershipCardOrderStatus()
  }, [])

  if (!fulfillments) return <Spinner />

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Membership Dashboard"
        backLinkTo={makeMemberPortalPath(PATH.MEMBERSHIPS)}
        membership={membership}
      />

      <section className="form-block-container">
        <h2 className="cards-title">My Membership Card</h2>
        <div className="card">
          <MembershipTypeHeader
            membershipId={membershipId}
            memberNumber={membership_number__c}
            memberType={membership_type__c}
          />
          {hasEitherDigitalPass && (
            <div className="card-inner">
              <div className="form-block">
                <div className="group-block">
                  <h3 className="group-title">Add to Wallet</h3>
                  <p>Have easy access to your membership card on your phone:</p>
                  <div className="digital-passes">
                    <DigitalWalletPass
                      type={DIGITAL_WALLET_TYPE.APPLE}
                      url={applePassUrl}
                    />
                    <DigitalWalletPass
                      type={DIGITAL_WALLET_TYPE.GOOGLE}
                      url={googlePassUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <NewMembershipCardForm
          address={shippingstreet}
          city={shippingcity}
          state={shippingstate}
          postalcode={shippingpostalcode}
          country={shippingcountry}
          onSubmit={(cardRequestFormValues) => {
            const cardRequestApiValues = apiValuesWithMappedKeys(
              cardRequestFormValues,
              API_KEY_MAP.CARD_REPRINT_MAP,
            )

            return requestCardReprint({
              ...cardRequestApiValues,
              membershipid: membershipId,
            })
          }}
          onSubmitSuccess={(_, dispatch) => {
            fetchMembershipCardOrderStatus(membershipId)
            dispatch(reset('NewMembershipCardForm'))
            flashSuccessMessage('Your new card request was successful')
          }}
        />
      </section>
    </>
  )
}

MembershipCard.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: membershipsSelector.account(state),
    fulfillments: selectors.fulfillments(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  clearMembershipCardOrderStatus: actions.clearMembershipCardOrderStatus,
  flashErrorMessage: flashErrorMessage,
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  fetchMembershipCardOrderStatus: apiActions.fetchMembershipCardOrderStatus,
  requestCardReprint: apiActions.requestCardReprint,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps),
)(MembershipCard)

function MembershipTypeHeader({ membershipId, memberNumber, memberType }) {
  const memberCardQuery = {
    membershipId,
  }

  return (
    <header className="with-card-preview with-actions">
      <div className="icon-and-header">
        <img
          src="/assets/images/imagery/ST_Icon_membercard.svg"
          alt=""
          className="card-icon"
        />
        <div>
          <MembershipTypeDisplay type={memberType} />
          <div className="subheader">
            <p>Membership #{memberNumber}</p>
          </div>
        </div>
      </div>
      <div className="actions">
        <ButtonLink
          to={makeQueryPath(
            makeMemberPortalPath(PATH.MEMBERSHIP_CARD_VIEW_PRINT),
            memberCardQuery,
          )}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`View & print card for membership ${memberNumber}`}
        >
          View & Print
        </ButtonLink>
      </div>
    </header>
  )
}
