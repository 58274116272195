import React, { useState } from 'react'
import { compose } from 'recompose'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

function CreateAccountForm({ handleSubmit }) {
  const [passwordHints, showPasswordHints] = useState(false)

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field component={Input} label="Email" name="email" readOnly />
      <Field
        component={Input}
        label="Password"
        name="password"
        onChange={() => showPasswordHints(true)}
        required
        requiredIndicator="*"
        type="password"
      />
      <Field
        component={Input}
        label="Password Confirmation"
        name="passwordConfirmation"
        required
        requiredIndicator="*"
        type="password"
      />

      {passwordHints && (
        <ul className="password-rules">
          <li>
            Your password must contain:
            <ul>
              <li>At least 8 characters</li>
              <li>
                At least 3 of the following:
                <ul>
                  <li>Lower case letters (a-z)</li>
                  <li>Upper case letters (A-Z)</li>
                  <li>Numbers (0-9)</li>
                  <li>Special characters (ex. !@#$%^&*)</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      )}

      <SubmitButton>Create Login</SubmitButton>
    </form>
  )
}

CreateAccountForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'CreateAccountForm',
    constraints: {
      password: { presence: { allowEmpty: false } },
      passwordConfirmation: {
        presence: { allowEmpty: false },
        equality: {
          attribute: 'password',
        },
      },
    },
    submitFilters: { reject: ['passwordConfirmation'] },
  })
)(CreateAccountForm)
