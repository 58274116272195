import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AccountType, SavingClubOfferArrayType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import OfferCard from './OfferCard'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  clearSavingsClubOffers: PropTypes.func.isRequired,
  fetchRandomOffers: PropTypes.func.isRequired,
  offers: SavingClubOfferArrayType,
}

function OfferCards({
  account,
  clearSavingsClubOffers,
  fetchRandomOffers,
  offers,
}) {
  const { shippingcountry: country } = account
  useEffect(() => {
    fetchRandomOffers()

    return () => clearSavingsClubOffers()
  }, [])

  if (!offers) return <Spinner />

  return (
    <>
      {offers.map((offer, index) => (
        <OfferCard key={index} country={country} savingsClubOffer={offer} />
      ))}
    </>
  )
}

OfferCards.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    offers: selectors.offers(state),
  }
}

const mapDispatchToProps = {
  clearSavingsClubOffers: actions.clearSavingsClubOffers,
}

const mapApiAuthToProps = {
  fetchRandomOffers: apiActions.fetchRandomOffers,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(OfferCards)
