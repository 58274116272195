import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { compose } from 'recompose'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { handleSuccess, setOnSuccess } from 'lp-redux-api'
import * as actions from './global-actions'
import * as apiActions from 'api-actions'
import { set } from 'lodash/fp'
import { ACCOUNT_DETAIL_TYPE, PRIMARY_CONTACT } from 'types'

const reducerKey = 'global'
const slice = 'root.global'
const initialState = {}

const reducer = handleActions(
  {
    [actions.clearAccount]: unsetState('account'),
    [actions.clearContacts]: unsetState('contacts'),

    [apiActions.fetchAccount]: handleSuccess((state, { payload: { data } }) => {
      return compose(
        set('account', data),
        set(
          'isMonthlyAccountType',
          data.account_detail_type__c === ACCOUNT_DETAIL_TYPE.CUSTOMER_MONTHLY
        ),
        set(
          'isSubsidizedAccountType',
          data.account_detail_type__c ===
            ACCOUNT_DETAIL_TYPE.CUSTOMER_SUBSIDIZED
        )
      )(state)
    }),
    [apiActions.fetchContacts]: setOnSuccess('contacts'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  account: select('account'),
  contacts: select('contacts'),
  isMonthlyAccountType: select('isMonthlyAccountType'),
  isSubsidizedAccountType: select('isSubsidizedAccountType'),
}

selectors.primaryContact = createSelector([selectors.contacts], (contacts) => {
  if (!contacts) return
  return contacts.find(
    ({ contact_type__c }) => contact_type__c === PRIMARY_CONTACT
  ) || {}
})

export { reducer, selectors, reducerKey }
