import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MembershipQuestionnaire } from 'member-portal/components'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { PATH, makeMembershipIdPath } from 'config'
import { MembershipType } from 'types'

const propTypes = {
  membershipToRenew: MembershipType.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
}

function RenewalMembershipQuestionnaire({
  membershipToRenew,
  setSelectedMembershipType,
}) {
  return (
    <section className="form-block-container questionnaire-flow">
      <div className="questionnaire-flow-inner">
        <header className="header-title">
          <h1>Membership Advisor</h1>
        </header>
        <div className="card card-medium">
          <div className="card-inner">
            <MembershipQuestionnaire
              setSelectedMembershipType={setSelectedMembershipType}
              postSelectionPath={makeMembershipIdPath(
                membershipToRenew.sfid,
                PATH.RENEW_MEMBERSHIP,
                PATH.SELECTION
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

RenewalMembershipQuestionnaire.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membershipToRenew: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalMembershipQuestionnaire
)
