import { isEmpty } from 'lodash'
import { parse } from 'date-fns'

const parseISODateTime = (dateTimeString) => {
  if (isEmpty(dateTimeString)) return null

  return parse(dateTimeString, 'yyyy-MM-dd HH:mm:ss', new Date())
}

export default parseISODateTime
