import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { selectors } from '../reducer'
import { makeGiftPath } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
  selectedMembershipType: PropTypes.string,
}

const INITIAL_GIFT_APPLICATION_PATHS = [makeGiftPath()]

function GiftSelectionRedirect({ children, selectedMembershipType }) {
  const location = useLocation()

  if (
    !selectedMembershipType &&
    !INITIAL_GIFT_APPLICATION_PATHS.includes(location.pathname)
  ) {
    // Push user back to gift selection if no membership
    // type has been selected yet
    return <Redirect to={makeGiftPath()} />
  }

  return children
}

GiftSelectionRedirect.propTypes = propTypes

function mapStateToProps(state) {
  return {
    selectedMembershipType: selectors.selectedMembershipType(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GiftSelectionRedirect
)
