// util to help navigate to specific url flow with state
const navigateToNextStepWithState = (
  history,
  nextStep,
  formValues,
  location,
  boat
) => {
  const nextLocation = {
    pathname: nextStep,
    state: {
      portWizardData: {},
      formData: formValues,
      returnUrl: location.pathname,
      boat,
    },
  }
  history.push(nextLocation)
}

export default navigateToNextStepWithState
