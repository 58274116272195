import React from 'react'
import PropTypes from 'prop-types'
import {
  ProductPrices,
  BOAT_USE_TYPE,
  MEMBERSHIP_DETAIL,
  MEMBERSHIP_TYPE,
} from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { RadioGroup } from 'components'
import { MembershipTypeInputLabel } from '../components'
import { values, pickBy, keys } from 'lodash'

const propTypes = {
  ...formPropTypes,
  headingText: PropTypes.string,
  membershipPrices: ProductPrices.isRequired,
  selectedUseTypes: PropTypes.arrayOf(PropTypes.string),
}

function getMembershipDetailsForUseType(useType) {
  // Get all Membership Details Except for Trial Memberships
  delete MEMBERSHIP_DETAIL[MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP]
  return pickBy(MEMBERSHIP_DETAIL, ({ boatUse }) => boatUse === useType)
}

function MembershipTypeForm({
  handleSubmit,
  headingText = 'What membership makes the most sense for you? (select one)',
  submitting,
  membershipPrices,
  selectedUseTypes = values(BOAT_USE_TYPE), // *All* use types are available for selection...,
}) {
  const membershipOptionGroups = selectedUseTypes.map((useType) => {
    const membershipDetails = getMembershipDetailsForUseType(useType)
    return {
      name: useType,
      options: keys(membershipDetails),
    }
  })

  return (
    <form onSubmit={handleSubmit} noValidate>
      <h2 id="membership-type-form-header">{headingText}</h2>
      <Field
        name="selectedMembershipType"
        label={false}
        component={RadioGroup}
        inputLabelComponent={MembershipTypeInputLabel}
        optionGroups={membershipOptionGroups}
        membershipPrices={membershipPrices}
        className="membership-type-options radio-inputs"
        ariaLabelledby="membership-type-form-header"
      />
      <div className="button-group">
        <SubmitButton {...{ submitting }}>Continue</SubmitButton>
      </div>
    </form>
  )
}

MembershipTypeForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'MembershipTypeForm',
    constraints: {
      selectedMembershipType: { presence: true },
    },
  }),
)(MembershipTypeForm)
