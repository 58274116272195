import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, Button } from 'lp-components'

function EditPasswordForm({ handleSubmit, toggleEditPasswordForm }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <p>
        <b>Note: </b>You will be required to re-login after updating your
        password.
      </p>
      <div className="button-group">
        <Button
          onClick={toggleEditPasswordForm}
          className="button-primary-outline"
        >
          Cancel
        </Button>
        <SubmitButton>
          Update Password
        </SubmitButton>
      </div>
    </form>
  )
}

export default compose(
  lpForm({
    name: 'EditPasswordForm',
  })
)(EditPasswordForm)
