import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import FlashMessageContainer from './FlashMessageContainer'
import Navigation from './Navigation'
import NetworkErrorReporter from './NetworkErrorReporter'
import { ContactType, MenuItemType } from 'types'
import { makePath, PATH, SEATOW_PHONE } from 'config'

const propTypes = {
  applicationLinks: PropTypes.arrayOf(MenuItemType),
  contactLink: PropTypes.string,
  logoLink: PropTypes.string,
  primaryContact: ContactType,
}

function Header({
  applicationLinks,
  contactLink,
  logoLink = PATH.SEATOW,
  primaryContact,
}) {
  return (
    <header>
      <ul className="mobile-subnav">
        <li>
          <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a>
        </li>
        <li>
          <a href={makePath(PATH.SEATOW, PATH.CONTACT)}>Contact</a>
        </li>
      </ul>
      <Navigation
        {...{ applicationLinks, contactLink, logoLink, primaryContact }}
      />
      <FlashMessageContainer />
      <NetworkErrorReporter />
    </header>
  )
}

Header.propTypes = exact(propTypes)

export default pure(Header)
