import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { isNil } from 'lodash'
import { ERROR_STATUS } from 'types'
import { REACT_APP_API_REQUEST_TOKEN } from 'config'
import { handleClientError, handleServerError } from 'utils'

// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before() {
  return {
    headers: {
      'Request-Token': REACT_APP_API_REQUEST_TOKEN,
    },
  }
}

// Any transformations of successful responses can go here.
function onSuccess(res) {
  return res
}

// Any transformations of failed responses can go here.
function onFailure(httpError) {
  const clientErrors = [401, 403, 404]

  // An undefined httpError.status indicates a failure to send an API request, likely due
  // to the connection with the backend API not being available.
  const status = httpError?.status

  if (isNil(status)) return handleClientError(ERROR_STATUS.API_DOWN)
  if (clientErrors.includes(status)) return handleClientError(status)

  const serverErrors = status >= 500 && status <= 599

  if (serverErrors) return handleServerError()

  return httpError
}

export const config = {
  root: process.env.REACT_APP_API_URL,
  before,
  onSuccess,
  onFailure,
  mode: 'cors',

  // Turn off key (de)camelization...
  camelizeResponse: false,
  decamelizeBody: false,
  decamelizeQuery: false,
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)
