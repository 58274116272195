import { BOAT_STATUS } from 'types'

function findPrimaryBoat(boats) {
  if (!boats) return

  return boats.find(
    ({ boat_status__c, primary_boat__c }) =>
      boat_status__c === BOAT_STATUS.ACTIVE && primary_boat__c === true
  )
}

export default findPrimaryBoat
