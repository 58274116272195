import { isEmpty } from 'lodash'
import { parse } from 'date-fns'

const parseUSMonthDayYearDate = (dateString) => {
  if (isEmpty(dateString)) return null

  return parse(dateString, 'MM/dd/yyyy', new Date())
}

export default parseUSMonthDayYearDate
