import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { PageHeader, SectionHeader, SiteMetadata } from 'components'
import { NewParticipantApplicationForm } from '../forms'
import * as apiActions from 'api-actions'
import {
  COUNTRY,
  PATH,
  QUERY_KEY,
  makeProgramsApplicationPath,
  SITE_METADATA,
} from 'config'
import { API_KEY_MAP, API_RESPONSE } from 'types'
import {
  apiValuesWithMappedKeys,
  handleSubmitFailWithFlashMessage,
  makeQueryPath,
} from 'utils'

const propTypes = {
  applyAsAParticipant: PropTypes.func.isRequired,
}

function NewParticipantApplication({ applyAsAParticipant }) {
  const history = useHistory()
  const { metaTitle, description, keywords } = SITE_METADATA.PROGRAMS_APPLY
  return (
    <>
      <SiteMetadata
        metaTitle={metaTitle}
        description={description}
        keywords={keywords}
      />
      <PageHeader headerText="Application" />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader
                sectionHeaderName="Apply to Participate in Sea Tow Programs"
                sectionDescription="Thank you for your interest in our Sea Tow programs. Please fill
                  out the form below to begin your application process. Once
                  submitted, our team will review and get back to you with next
                  steps, usually in 72 hours or less. We look forward to working
                  with you."
              />
              <NewParticipantApplicationForm
                initialValues={{
                  account: {
                    mailingAddressSameAsPublic: true,
                    mailingCountry: COUNTRY.UNITED_STATES,
                    publicCountry: COUNTRY.UNITED_STATES,
                  },
                }}
                onSubmit={(formValues) => {
                  const mappedAccountApiValues = apiValuesWithMappedKeys(
                    formValues.account,
                    API_KEY_MAP.BUSINESS_INFORMATION_KEYMAP
                  )
                  const mappedContactApiValues = apiValuesWithMappedKeys(
                    formValues.contact,
                    API_KEY_MAP.CONTACT_KEY_MAP
                  )
                  const { userName } = formValues

                  return applyAsAParticipant({
                    account: mappedAccountApiValues,
                    contact: mappedContactApiValues,
                    auth0_email: userName,
                  })
                }}
                onSubmitFail={handleSubmitFailWithFlashMessage}
                onSubmitSuccess={(response) => {
                  history.push(nextPageFromResponse(response))
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

NewParticipantApplication.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  applyAsAParticipant: apiActions.applyAsAParticipant,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewParticipantApplication
)

function nextPageFromResponse(response) {
  const { auth0_status } = response

  switch (auth0_status) {
    case API_RESPONSE.AUTH0_ACCOUNT_FOUND:
      return makeQueryPath(makeProgramsApplicationPath(PATH.LOGIN), {
        [QUERY_KEY.STATUS]: auth0_status,
      })
    case API_RESPONSE.AUTH0_ACCOUNT_NOT_FOUND: {
      const { account_uuid, auth0_email } = response

      return makeQueryPath(makeProgramsApplicationPath(PATH.CREATE_ACCOUNT), {
        [QUERY_KEY.ACCOUNT_ID]: account_uuid,
        [QUERY_KEY.STATUS]: auth0_status,
        [QUERY_KEY.USERNAME]: auth0_email,
      })
    }
    case API_RESPONSE.NO_AUTH0_CHECK_PERFORMED:
      return makeProgramsApplicationPath(PATH.THANK_YOU)
  }
}
