import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationSteps, MembershipSummary } from 'member-portal/components'
import { selectors } from '../reducer'
import { selectors as memberSelectors } from 'member-reducer'
import { GIFT_APPLICATION_STEP_LINKS, PATH, makeGiftPath } from 'config'
import { ProductPrices } from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  giftWrapped: PropTypes.bool,
  membershipPrices: ProductPrices.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  shippingMethod: PropTypes.string,
}

function GiftStepsLayout({
  children,
  giftWrapped,
  membershipPrices,
  selectedMembershipType,
  shippingMethod,
}) {
  const { pathname } = useLocation()

  return (
    <>
      <div className="form-with-aside-wrapper">
        <header className="header-title">
          <h1>Gift Membership</h1>
        </header>
        <div className="form-with-aside-block">
          <div className="form-with-aside">
            <ApplicationSteps
              pathname={pathname}
              stepLinks={GIFT_APPLICATION_STEP_LINKS}
            >
              {children}
            </ApplicationSteps>
          </div>
          <aside>
            <MembershipSummary
              giftWrapped={giftWrapped}
              membershipPrices={membershipPrices}
              membershipType={selectedMembershipType}
              shippingMethod={shippingMethod}
              updateLink={makeGiftPath(PATH.ROOT)}
            />
          </aside>
        </div>
      </div>
    </>
  )
}

GiftStepsLayout.propTypes = propTypes

function mapStateToProps(state) {
  return {
    giftWrapped: selectors.giftWrapped(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    selectedMembershipType: selectors.selectedMembershipType(state),
    shippingMethod: selectors.shippingMethod(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GiftStepsLayout
)
