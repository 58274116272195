import { isNil } from 'lodash'
import { COUNTRY } from 'config'
import formatFixedNumber from './formatFixedNumber'

export default function formatDistance(distance, country) {
  if (isNil(distance) || isNil(country)) return 'N/A'

  let units = 'miles'
  let formattedDistance = formatFixedNumber(distance, 1)

  if (country !== COUNTRY.UNITED_STATES) {
    units = 'km'
    formattedDistance = formatFixedNumber(distance * 1.609, 1)
  }

  return `${formattedDistance} ${units}`
}
