import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { ExternalLink, LinkButton } from 'components'
import OfferCategoryIcon from './OfferCategoryIcon'
import OfferRedemptionInstructions from './OfferRedemptionInstructions'
import { PATH, makeProgramsPortalPath } from 'config'
import { SavingsClubOfferType } from 'types'
import { getOfferTypeDescription } from 'utils'

const propTypes = {
  offer: SavingsClubOfferType.isRequired,
  removeOffer: PropTypes.func,
  showActions: PropTypes.bool,
  withFeaturedBadge: PropTypes.bool,
}

function SavingsClubOffer({
  offer,
  removeOffer,
  showActions = false,
  withFeaturedBadge = false,
}) {
  const {
    featured__c: isFeatured,
    primary_category__c: primaryCategory,
    online_promotional_url__c: websiteUrl,
    offer_description__c: description,
  } = offer
  const offerTypeDescription = getOfferTypeDescription(offer)

  return (
    <div className="card">
      <header
        className={classnames('with-actions', {
          featured: withFeaturedBadge && isFeatured,
        })}
      >
        <OfferCategoryIcon category={primaryCategory} />
        <h3>{`${primaryCategory} - ${offerTypeDescription}`}</h3>
        {showActions && (
          <div className="actions">
            <LinkButton className="link-secondary" onClick={removeOffer}>
              Remove
            </LinkButton>
            <Link
              className="link-primary"
              to={{
                pathname: makeProgramsPortalPath(
                  PATH.SAVINGS_CLUB_OFFERS,
                  PATH.EDIT_OFFER
                ),
                state: { offer },
              }}
            >
              Edit
            </Link>
          </div>
        )}
        {isFeatured && (
          <p className="featured-banner">
            <span>Featured</span>
          </p>
        )}
      </header>
      <div className="card-inner offer-details">
        <OfferRedemptionInstructions offer={offer} />
        <p>{description}</p>
        {websiteUrl && (
          <>
            <ExternalLink href={websiteUrl}>
              <strong>{websiteUrl}</strong>
            </ExternalLink>
          </>
        )}
      </div>
    </div>
  )
}

SavingsClubOffer.propTypes = exact(propTypes)

export default pure(SavingsClubOffer)
