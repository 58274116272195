import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { RadioGroup } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { BOAT_OWNERSHIP_TYPE } from 'types'

const propTypes = {
  ...formPropTypes,
}

function BoatTypeSeletorForm() {
  return (
    <form noValidate>
      <Field
        component={RadioGroup}
        label="How do you primarily boat?"
        name="boatOwnershipType"
        options={Object.values(BOAT_OWNERSHIP_TYPE)}
      />
    </form>
  )
}

BoatTypeSeletorForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'BoatTypeSeletorForm',
    constraints: {},
  })
)(BoatTypeSeletorForm)
