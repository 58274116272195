import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ConfirmContext } from 'contexts'

const propTypes = {
  children: PropTypes.node.isRequired,
}

// Provides the initial state for a confirm/reject context
// typically used with a Promise and a Modal
function ConfirmContextProvider({ children }) {
  const [confirm, setConfirm] = useState({
    heading: '',
    prompt: '',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    isOpen: false,
    proceed: null,
    cancel: null,
  })

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  )
}

ConfirmContextProvider.propTypes = exact(propTypes)

export default pure(ConfirmContextProvider)
