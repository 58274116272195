import { isNil } from 'lodash'
// Formats a Number with the specified fixed number of decimal places using
// the in-effect locale.

const formatFixedNumber = (value, fixed = 0) => {
  if (isNil(value)) return 'N/A'

  return value.toLocaleString(undefined, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  })
}

export default formatFixedNumber
