function calculateDiscountedCost(originalCost, discountCode) {
  if (!discountCode) return originalCost

  const reducedCost = reduceCost(originalCost, discountCode)
  return reducedCost > 0 ? reducedCost : 0
}

// Helpers
function reduceCost(originalCost, discountCode) {
  const { dollarsOff, percentOff } = discountCode
  if (dollarsOff) return originalCost - dollarsOff
  if (percentOff) return originalCost * (1 - percentOff / 100)

  return originalCost
}

export default calculateDiscountedCost
