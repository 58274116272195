import { HOME_PORT_TYPE } from 'types'

export default function homePortTitleByType(homePortType) {
  switch (homePortType) {
    case HOME_PORT_TYPE.MARINA:
      return 'Name & Address of Marina:'
    case HOME_PORT_TYPE.TRAILER:
      return 'Trailer Ramp Used Most Often:'
    case HOME_PORT_TYPE.MOORING:
      return 'Body of Water/Facility:'
    case HOME_PORT_TYPE.HOME_DOCK:
      return 'Address of Home Dock:'
    case HOME_PORT_TYPE.OTHER:
      return 'Name & Address of Where Boat is Kept:'
    default:
      return 'Invalid Homeport Type'
  }
}
