import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { PATH, makePath } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, PATH.ROOT)}>
          <Views.ReferABoater />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes

export default Routes
