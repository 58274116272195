import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'
import { useUID } from 'react-uid'

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
}

function ExternalLink({
  children,
  className = '',
  href,
  showIcon = true,
  ...rest
}) {
  const descriptionUid = 'new-window-' + useUID()

  // Ensure that all web addresses begin with a protocol (the expectation). If
  // they don't, assume the current protocol (typically https) by prepending
  // "//" to the URL.
  const normalizedUrl = href.startsWith('http') ? href : `//${href}`

  return (
    <>
      <a
        className={classnames('external-link', className, {
          'with-icon': showIcon,
        })}
        target="_blank"
        rel="noopener noreferrer"
        href={normalizedUrl}
        aria-describedby={descriptionUid}
        {...rest}
      >
        {children}
      </a>
      <span id={descriptionUid} hidden>
        Opens in a new window
      </span>
    </>
  )
}

ExternalLink.propTypes = propTypes

export default pure(ExternalLink)
