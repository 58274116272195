import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Boat } from '../components'
import { MembershipPageHeader } from 'memberships/components'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { AccountType, BoatType, MembershipType, PERMISSIONS } from 'types'
import { PATH, makeMembershipIdPath, makeMemberPortalPath } from 'config'
import { hasPermission, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType,
  additionalBoats: PropTypes.arrayOf(BoatType),
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  membership: MembershipType,
  primaryBoat: BoatType,
  setOldInactiveAndSetNewPrimary: PropTypes.func,
  updateBoat: PropTypes.func.isRequired,
}

function Boats({
  account,
  additionalBoats,
  fetchAllMembershipsForAccount,
  membership,
  primaryBoat,
  setOldInactiveAndSetNewPrimary,
  updateBoat,
}) {
  const { membership_type__c } = membership
  const canAddMultipleBoats = hasPermission(
    membership_type__c,
    PERMISSIONS.MULTIPLE_BOATS
  )

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Membership Dashboard"
        backLinkTo={makeMemberPortalPath(PATH.MEMBERSHIPS)}
        membership={membership}
      />
      <section className="form-block-container">
        <h2 className="cards-title">Boat & Home Port Information</h2>
        <PrimaryBoat
          account={account}
          fetchAllMembershipsForAccount={fetchAllMembershipsForAccount}
          membership={membership}
          primaryBoat={primaryBoat}
          setOldInactiveAndSetNewPrimary={setOldInactiveAndSetNewPrimary}
          updateBoat={updateBoat}
        />
        {canAddMultipleBoats && (
          <AdditionalBoats
            account={account}
            fetchAllMembershipsForAccount={fetchAllMembershipsForAccount}
            membership={membership}
            additionalBoats={additionalBoats}
            primaryBoat={primaryBoat}
            setOldInactiveAndSetNewPrimary={setOldInactiveAndSetNewPrimary}
            updateBoat={updateBoat}
          />
        )}
      </section>
    </>
  )
}

Boats.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    additionalBoats: selectors.additionalBoats(state),
    membership: memberSelectors.membership(state),
    primaryBoat: selectors.primaryBoat(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  deleteBoat: apiActions.deleteBoat,
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
  setOldInactiveAndSetNewPrimary: apiActions.setOldInactiveAndSetNewPrimary,
  updateBoat: apiActions.updateBoat,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Boats)

function PrimaryBoat({
  account,
  fetchAllMembershipsForAccount,
  membership,
  setOldInactiveAndSetNewPrimary,
  primaryBoat,
  updateBoat,
}) {
  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Prim-Boat.svg"
          alt=""
          className="icon"
        />
        <h2>Primary Boat</h2>
      </header>
      <div className="card-inner">
        <MembershipCoverageSelector primary={true} />
        <Boat
          account={account}
          boat={primaryBoat}
          fetchAllMembershipsForAccount={fetchAllMembershipsForAccount}
          membership={membership}
          setOldInactiveAndSetNewPrimary={setOldInactiveAndSetNewPrimary}
          updateBoat={updateBoat}
        />
      </div>
    </div>
  )
}

function AdditionalBoats({
  account,
  additionalBoats,
  fetchAllMembershipsForAccount,
  membership,
  primaryBoat,
  setOldInactiveAndSetNewPrimary,
  updateBoat,
}) {
  const { sfid: membershipId } = membership

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Add-Boat.svg"
          alt=""
          className="icon"
        />
        <h2>Additional Boats</h2>
      </header>
      <div className="card-inner">
        <MembershipCoverageSelector primary={false} />
        {additionalBoats.map((boat) => (
          <Boat
            account={account}
            boat={boat}
            fetchAllMembershipsForAccount={fetchAllMembershipsForAccount}
            key={boat.id}
            membership={membership}
            primaryBoat={primaryBoat}
            setOldInactiveAndSetNewPrimary={setOldInactiveAndSetNewPrimary}
            updateBoat={updateBoat}
          />
        ))}
        <Link
          className="button-primary-outline plus-left-button-icon"
          to={makeMembershipIdPath(membershipId, PATH.BOATS, PATH.NEW)}
        >
          Add Another Boat
        </Link>
        <Link
          className="button-primary-outline plus-left-button-icon"
          to={makeMembershipIdPath(membershipId, PATH.BOATS, PATH.OTHER)}
        >
          Add Other (Boat Club Member, etc)
        </Link>
      </div>
    </div>
  )
}

function MembershipCoverageSelector({ primary }) {
  if (primary) {
    return (
      <>
        <p>
          For Gold Card, Lake Card and Professional Mariner Card members, your
          primary boat should be the one you use most often and is the one from
          which we determine your home Sea Tow area.
        </p>
        <p>
          For Trial, Commercial and Fleet membership types, your Primary Boat is
          the single vessel eligible for membership services.
        </p>
      </>
    )
  }
  return (
    <>
      <p>
        Gold Card, Lake Card and Professional Mariner Card members: List any
        boats with an engine that you own in addition to your primary vessel. If
        you are a member of a Boat Club please add your boat club information.
        This enables us to service you more efficiently.
      </p>
      <p>
        Trial, Commercial Card and Fleet membership types: Your membership does
        not provide service for additional vessels beyond your primary vessel.
        See the Sea Tow Membership Agreement for complete membership privilege
        details.
      </p>
    </>
  )
}
