import React from 'react'
import PropTypes from 'prop-types'
import HomePortMap from './HomePortMap'
import HomePortAddress from './HomePortAddress'

const propTypes = {
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  homePortName: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
}

function HomePortMapAndAddress({
  address,
  city,
  country,
  homePortName,
  lat,
  lng,
  state,
}) {
  return (
    <>
      <HomePortMap lat={lat} lng={lng} />
      <div className="map-content">
        <HomePortAddress
          address={address}
          city={city}
          country={country}
          name={homePortName}
          state={state}
        />
      </div>
    </>
  )
}

HomePortMapAndAddress.propTypes = propTypes

export default React.memo(HomePortMapAndAddress)
