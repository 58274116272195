import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MembershipType } from 'types'
import { selectors as memberSelectors } from 'member-reducer'
import { HomePortTypeSection } from 'memberships/boats/components/'
import { PATH, makeMembershipIdPath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
}

function NewMemberHomePortType({ membership }) {
  return (
    <HomePortTypeSection
      nextStep={makeMembershipIdPath(
        membership.sfid,
        PATH.RENEW_MEMBERSHIP,
        PATH.BOATS,
        PATH.HOME_PORT_ADDRESS
      )}
    />
  )
}

NewMemberHomePortType.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberHomePortType
)
