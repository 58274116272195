import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { selectors as globalSelectors } from 'global-reducer'
import { addDays, isBefore, isWithinInterval } from 'date-fns'
import { NotificationCenter } from 'components'
import {
  MEMBERSHIP_STATUS,
  NOTIFICATION_CLASS,
  MembershipArrayType,
} from 'types'
import { calculateDaysUntilExpiration, parseISODateTime } from 'utils'
import { isNil } from 'lodash'

const propTypes = {
  isMonthlyAccountType: PropTypes.bool,
  isSubsidizedAccountType: PropTypes.bool,
  memberships: MembershipArrayType,
}

function MembershipNotificationCenter({
  isMonthlyAccountType,
  isSubsidizedAccountType,
  memberships,
}) {
  const expirationNotifications = generateExpirationNotifications(
    isMonthlyAccountType,
    isSubsidizedAccountType,
    memberships
  )
  const newMemberNotifications = generateNewMembershipNotifications(memberships)
  const notificationCount =
    expirationNotifications.length + newMemberNotifications.length

  return (
    <NotificationCenter notificationCount={notificationCount}>
      <MembershipNotificationMessages
        expirationNotifications={expirationNotifications}
        newMemberNotifications={newMemberNotifications}
      />
    </NotificationCenter>
  )
}

MembershipNotificationCenter.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isMonthlyAccountType: globalSelectors.isMonthlyAccountType(state),
    isSubsidizedAccountType: globalSelectors.isSubsidizedAccountType(state),
  }
}

export default compose(connect(mapStateToProps, null))(
  MembershipNotificationCenter
)

function MembershipNotificationMessages({
  expirationNotifications,
  newMemberNotifications,
}) {
  return (
    <>
      {expirationNotifications.length > 0 && (
        <>
          <h3>Expiring Memberships</h3>
          {expirationNotifications.map(
            ({ message, memberNumber, notificationClass }) => (
              <p key={memberNumber} className={notificationClass}>
                {message}
              </p>
            )
          )}
        </>
      )}
      {newMemberNotifications.length > 0 && (
        <>
          <h3>New Membership(s)</h3>
          {newMemberNotifications.map(
            ({ message, memberNumber, notificationClass }) => (
              <p key={memberNumber} className={notificationClass}>
                {message}
              </p>
            )
          )}
        </>
      )}
    </>
  )
}

const generateExpirationNotifications = (
  isMonthlyAccountType,
  isSubsidizedAccountType,
  memberships
) => {
  return memberships.reduce(
    (
      notifications,
      {
        membership_expiration_date__c: expirationDateString,
        membership_number__c: memberNumber,
        membership_status__c: status,
      }
    ) => {
      if (isMonthlyAccountType || isSubsidizedAccountType) return notifications
      if (status === MEMBERSHIP_STATUS.PENDING_ACTIVATION) return notifications
      if (
        status !== MEMBERSHIP_STATUS.ACTIVE &&
        status !== MEMBERSHIP_STATUS.FUTURE_START
      ) {
        notifications.push({
          notificationClass: NOTIFICATION_CLASS.DANGER,
          memberNumber,
          message: `Membership #${memberNumber}: Your membership has expired. Should you need a tow or other on-water services there will be a charge. Avoid having to pay out-of-pocket. Renew now and your membership will reactivate in 24 hours.`,
        })
      } else {
        const daysUntilExpiration =
          calculateDaysUntilExpiration(expirationDateString)

        if (daysUntilExpiration > 45) return notifications

        if (daysUntilExpiration <= 45 && daysUntilExpiration >= 31) {
          notifications.push({
            notificationClass: NOTIFICATION_CLASS.DEFAULT,
            memberNumber,
            message: `Membership #${memberNumber}: Your membership is set to expire in 45 days or less. Renew now!`,
          })
        } else if (daysUntilExpiration <= 30 && daysUntilExpiration >= 11) {
          notifications.push({
            notificationClass: NOTIFICATION_CLASS.WARNING,
            memberNumber,
            message: `Membership #${memberNumber}: Your membership is set to expire in 30 days or less. Renew now!`,
          })
        } else if (daysUntilExpiration <= 10 && daysUntilExpiration >= 1) {
          notifications.push({
            notificationClass: NOTIFICATION_CLASS.DANGER,
            memberNumber,
            message: `Membership #${memberNumber}: Your membership is set to expire in 10 days or less. Renew now to avoid any lapse in membership services.`,
          })
        } else if (daysUntilExpiration === 0) {
          notifications.push({
            notificationClass: NOTIFICATION_CLASS.DANGER,
            memberNumber,
            message: `Membership #${memberNumber}: Your membership and all of the services included with it, will expire TODAY. Renew now to avoid any lapse in membership.`,
          })
        }
      }

      return notifications
    },
    []
  )
}

const generateNewMembershipNotifications = (memberships) => {
  const today = new Date()

  return memberships.reduce(
    (
      notifications,
      {
        membership_effective_date__c: effectiveDateString,
        membership_number__c: memberNumber,
        membership_status__c: status,
      }
    ) => {
      if (status === MEMBERSHIP_STATUS.PENDING_ACTIVATION) {
        notifications.push({
          notificationClass: NOTIFICATION_CLASS.DEFAULT,
          memberNumber,
          message:
            'Pending Activation: Your membership will activate 24 hours from time of payment.',
        })
      }
      if (
        (status !== MEMBERSHIP_STATUS.FUTURE_START &&
          status !== MEMBERSHIP_STATUS.ACTIVE) ||
        isNil(effectiveDateString)
      ) {
        return notifications
      }

      const effectiveDate = parseISODateTime(effectiveDateString)
      const warningEndDate = addDays(effectiveDate, 30)

      if (
        isBefore(today, effectiveDate) ||
        isWithinInterval(today, {
          start: effectiveDate,
          end: warningEndDate,
        })
      ) {
        notifications.push({
          notificationClass: NOTIFICATION_CLASS.DEFAULT,
          memberNumber,
          message: `Membership #${memberNumber}: Your membership has been processed. Expect to receive your membership card in the mail approximately two weeks after your payment was received. Click on Membership Card below to print a copy of your membership card or to see mailing status.`,
        })
      }

      return notifications
    },
    []
  )
}
