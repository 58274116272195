import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MembershipType } from 'types'
import { selectors as memberSelectors } from 'member-reducer'
import { SearchAddressSection } from 'memberships/boats/components/'
import { PATH, makeMembershipIdPath, makePath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
}

function RenewalSearchAddress({ membership }) {
  const basePath = makeMembershipIdPath(
    membership.sfid,
    PATH.RENEW_MEMBERSHIP,
    PATH.BOATS
  )
  return (
    <SearchAddressSection
      nextStep={makePath(basePath, PATH.HOME_PORT_LOCATION)}
      previousStep={makePath(basePath, PATH.HOME_PORT_TYPE)}
    />
  )
}

RenewalSearchAddress.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalSearchAddress
)
