import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'
import { isEmpty, isNil, omitBy } from 'lodash'
import { Button, Modal } from 'lp-components'
import { ExternalLink, UnexpectedError } from 'components'
import { PaymentMethodEditForm } from '../forms'
import { API_KEY_MAP, MembershipType } from 'types'
import { PATH, makePath, makeMembershipIdPath } from 'config'
import {
  apiValuesWithMappedKeys,
  formatUSMonthDayYearDate,
  handleSubmitFailWithFlashMessage,
  isAutoRenewDisabled,
  parseISODate,
  useToggle,
} from 'utils'

const propTypes = {
  membership: MembershipType.isRequired,
  refresh: PropTypes.func.isRequired,
  unenroll: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

function AutoRenewal({ membership, refresh, unenroll, update }) {
  const [editView, toggleEditView] = useToggle()
  const {
    auto_renew__c,
    auto_renew_date,
    communication_member_renewal_amount__c,
    membership_expiration_date__c,
    sfid: membershipId,
    account__r__heroku_external_id__c,
    membership_contact__r__heroku_external_id__c: contactId,
    heroku_external_id__c,
  } = membership
  const creditCard = creditCardForMembership(membership)

  return (
    <div className="card">
      <AutoRenewalHeader
        autorenews={auto_renew__c}
        membershipExpirationDate={membership_expiration_date__c}
        membershipId={membershipId}
        refresh={refresh}
        unenroll={unenroll}
      />
      {auto_renew__c === true && (
        <div className="card-inner">
          {isEmpty(creditCard) && (
            <UnexpectedError
              message={`Missing credit card for membership ${membershipId}`}
            />
          )}
          {editView ? (
            <PaymentMethodEditForm
              closeEditForm={toggleEditView}
              onSubmit={(creditCardFormValues) => {
                const creditCardApiValues = apiValuesWithMappedKeys(
                  creditCardFormValues,
                  API_KEY_MAP.CREDIT_CARD_KEY_MAP
                )
                const updatedApiValues = omitBy(
                  creditCardApiValues,
                  (value, key) => creditCard[key] === value
                )

                return update({
                  ...updatedApiValues,
                  amount: communication_member_renewal_amount__c,
                  arb_start_date: auto_renew_date,
                  membershipid: membershipId,
                  account__r__heroku_external_id__c,
                  contact__r__heroku_external_id__c: contactId,
                  membership__r__heroku_external_id__c: heroku_external_id__c,
                })
              }}
              onSubmitFail={handleSubmitFailWithFlashMessage}
              onSubmitSuccess={() => {
                refresh()
                toggleEditView()
              }}
              renewalDateString={auto_renew_date}
              submitButtonText="Update Payment Method"
            />
          ) : (
            <PaymentMethod
              creditCard={creditCard}
              openEditView={toggleEditView}
            />
          )}
        </div>
      )}
    </div>
  )
}

AutoRenewal.propTypes = exact(propTypes)

export default pure(AutoRenewal)

function AutoRenewalHeader({
  autorenews,
  membershipExpirationDate,
  membershipId,
  refresh,
  unenroll,
}) {
  return (
    <header className="with-actions">
      <div className="icon-and-header">
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Auto-renew.svg"
          alt=""
          className="icon"
        />
        <h2>Auto Renewal</h2>
      </div>
      <AutoRenewalControl
        autorenews={autorenews}
        membershipExpirationDate={membershipExpirationDate}
        membershipId={membershipId}
        refresh={refresh}
        unenroll={unenroll}
      />
    </header>
  )
}

function AutoRenewalControl({
  autorenews,
  membershipExpirationDate: expirationDateString,
  membershipId,
  refresh,
  unenroll,
}) {
  const history = useHistory()
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const membershipExpirationDate = parseISODate(expirationDateString)
  const isDisabled = isAutoRenewDisabled(expirationDateString)

  return (
    <>
      <div className="actions">
        <div className="switch-wrapper">
          <p>You are currently {autorenews === false && 'not'} enrolled</p>
          <label className="switch">
            <input
              type="checkbox"
              checked={autorenews}
              disabled={isDisabled}
              onChange={(event) => {
                if (event.target.checked) {
                  // Auto-renew is being enabled
                  history.push(
                    makeMembershipIdPath(
                      membershipId,
                      PATH.BILLING,
                      PATH.AUTO_RENEW_ENROLLMENT
                    )
                  )
                } else {
                  // Auto-renew is being disabled
                  setDeleteModalVisible(true)
                }
              }}
            />
            <span
              className={classnames('slider round', {
                checked: autorenews,
                locked: isAutoRenewDisabled,
              })}
            ></span>
          </label>
        </div>
      </div>

      {deleteModalVisible && (
        <Modal onClose={() => setDeleteModalVisible(false)}>
          <h2>Are you sure you want to unenroll in Auto Renewal?</h2>
          <p>
            Auto Renewal helps gives peace of mind.{' '}
            <strong>
              By unenrolling, you’ll need to make sure to log back into your
              account before{' '}
              {formatUSMonthDayYearDate(membershipExpirationDate)} or your
              membership will expire.
            </strong>
          </p>
          <div className="button-group">
            <Button
              onClick={() => setDeleteModalVisible(false)}
              className="button-primary-outline"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await unenroll({ membershipid: membershipId })
                await refresh()
                setDeleteModalVisible(false)
              }}
              className="button-warn"
            >
              Yes, Unenroll
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

function PaymentMethod({ creditCard, openEditView }) {
  const { last_4_digits__c } = creditCard

  return (
    <div className="row">
      <div className="col-6">
        <h3>Payment Method</h3>
        <Button onClick={openEditView} className="link-primary">
          (Edit)
        </Button>
        <p>Ending in {last_4_digits__c}</p>
      </div>
      <div className="col-6 align-right">
        <ExternalLink
          href={makePath(
            PATH.SEATOW,
            PATH.MEMBERSHIP,
            PATH.MEMBERSHIP_AGREEMENT
          )}
          className="link-small"
        >
          Auto Renew Agreement
        </ExternalLink>
      </div>
    </div>
  )
}

const creditCardForMembership = (membership) => {
  // The credit card data for a membership is found in the "arb_details"
  // object part of the membership's payment profile.
  let last_4_digits__c
  const { payment_profile } = membership

  if (!isNil(payment_profile)) {
    const { arb_details } = payment_profile
    if (!isNil(arb_details)) {
      const { pymt__last_4_digits__c } = arb_details
      last_4_digits__c = pymt__last_4_digits__c
    }
  }

  return omitBy(
    {
      last_4_digits__c,
    },
    isNil
  )
}
