import React from 'react'
import PropTypes from 'prop-types'
import { ProductPrices, TRAILER_CARE_TYPE_NONE } from 'types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { MembershipTypeForm } from 'member-portal/forms'
import { makeMemberApplicationPath, PATH } from 'config'

const propTypes = {
  setSelectedMembershipType: PropTypes.func.isRequired,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  membershipPrices: ProductPrices.isRequired,
}

function AllMembershipTypes({
  setSelectedMembershipType,
  setSelectedTrailerCareType,
  selectedMembershipType,
  membershipPrices,
}) {
  const history = useHistory()

  return (
    <>
      <section className="form-block-container">
        <header className="header-title">
          <h1>Sea Tow Membership Options</h1> 
        </header>
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <MembershipTypeForm
                initialValues={{ selectedMembershipType }}
                membershipPrices={membershipPrices}
                onSubmit={({ selectedMembershipType }) => {
                  setSelectedMembershipType(selectedMembershipType)
                  setSelectedTrailerCareType(TRAILER_CARE_TYPE_NONE)
                }}
                onSubmitSuccess={() =>
                  history.push(
                    makeMemberApplicationPath(PATH.MEMBERSHIP_TYPE_SELECTED)
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

AllMembershipTypes.propTypes = exact(propTypes)

function mapStateToProps(state) {
  return {
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    membershipPrices: memberSelectors.membershipPrices(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
  setSelectedTrailerCareType: memberActions.setSelectedTrailerCareType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AllMembershipTypes
)
