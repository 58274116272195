import { isEmpty } from 'lodash'

// Checkbox group values (provided as an array)
// must be passed to API as a string separated by semicolons

function checkboxGroupJoin(arrayOfValues) {
  if (isEmpty(arrayOfValues)) return ''

  return arrayOfValues.join(';')
}

export default checkboxGroupJoin
