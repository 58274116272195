import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  type: PropTypes.string.isRequired,
}

function MembershipTypeDisplay({ type }) {
  return <h3>{type} Membership</h3>
}

MembershipTypeDisplay.propTypes = exact(propTypes)

export default pure(MembershipTypeDisplay)
