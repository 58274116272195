import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { isNil } from 'lodash'
import { Aor } from 'components'
import { Button } from 'lp-components'
import {
  BoatAndHomePort,
  MembershipBenefits,
  MembershipHeader,
  RenewalStatus,
  TrailerCare,
} from '../components'
import {
  AccountType,
  MEMBERSHIP_TYPE,
  MembershipType,
  PERMISSIONS,
  ProductPrices,
} from 'types'
import { IMAGE_PATH, PATH, makeMembershipIdPath } from 'config'
import { hasPermission, useToggle } from 'utils'

const propTypes = {
  account: AccountType,
  isMonthlyAccountType: PropTypes.bool,
  isSubsidizedAccountType: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membership: MembershipType,
  membershipPrices: ProductPrices.isRequired,
}

function Membership({
  account,
  isMonthlyAccountType,
  isSubsidizedAccountType,
  isTrialMembershipsActive,
  membership,
  membershipPrices,
}) {
  const {
    aor,
    apple_pass__c,
    auto_renew__c,
    auto_renew_date,
    boats,
    google_pass__c,
    membership_number__c,
    membership_status__c,
    membership_effective_date__c,
    membership_expiration_date__c,
    membership_type__c,
    multiyear_product,
    sfid: membershipSfid,
    trailer_care_type__c,
    renewal_product__c,
  } = membership
  const { parent_account: { name } = {} } = account
  const [benefitsVisible, toggleBenefitsVisisble] = useToggle()

  const isTrialMembership =
    membership_type__c === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
  const canAddMultipleBoats = hasPermission(
    membership_type__c,
    PERMISSIONS.MULTIPLE_BOATS,
  )

  const showTrailerCare = useMemo(() => {
    const isTrialMembership = isTrialMembership && isTrialMembershipsActive
    const isMonthlyOrSubsidizedAccount =
      isMonthlyAccountType || isSubsidizedAccountType

    // If is the Membership Type is NOT a Trial Membership AND if the
    // Account Type is NOT 'Customer - MONTHLY' AND 'Customer - Subsidized'
    // type OR if a Trail Care Type is active, then show the Trailer
    // Care Component in the Dashboard
    return (
      (!isTrialMembership && !isMonthlyOrSubsidizedAccount) ||
      !!trailer_care_type__c
    )
  }, [])

  return (
    <div className="card">
      <MembershipHeader
        applePassUrl={apple_pass__c}
        googlePassUrl={google_pass__c}
        membershipSfid={membershipSfid}
        memberNumber={membership_number__c}
        status={membership_status__c}
        type={membership_type__c}
      />
      <div className="card-inner">
        <div className="detail-block-wrapper">
          <div className="detail-block-groups">
            <div className="detail-block-group">
              <RenewalStatus
                autorenews={auto_renew__c}
                autorenewDateString={auto_renew_date}
                canAutoRenew={
                  membership_type__c !== MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
                }
                effectiveDateString={membership_effective_date__c}
                expirationDateString={membership_expiration_date__c}
                membershipSfid={membershipSfid}
                memberNumber={membership_number__c}
                status={membership_status__c}
                subsidizedName={name ?? 'N/A'}
                {...(isTrialMembershipsActive && {
                  isTrialMembership,
                  membershipPrices,
                  renewalProduct: renewal_product__c,
                })}
              />
              {showTrailerCare && (
                <TrailerCare
                  enrolledInTrailerCare={!isNil(trailer_care_type__c)}
                  membershipSfid={membershipSfid}
                  membershipStatus={membership_status__c}
                  multiYearEnrollment={multiyear_product}
                  memberNumber={membership_number__c}
                  trailerCareAvailable={
                    membership_type__c === MEMBERSHIP_TYPE.GOLD_CARD ||
                    membership_type__c === MEMBERSHIP_TYPE.LAKE_CARD
                  }
                  trailerCareType={trailer_care_type__c}
                />
              )}
            </div>
            <BoatAndHomePort
              boats={boats}
              canAddMultipleBoats={canAddMultipleBoats}
              memberNumber={membership_number__c}
              membershipSfid={membershipSfid}
            />
          </div>
          {/* AOR might not exist if the account was created outside the normal account creation flow. 
            No boat info was entered and so it may not be available on the membership*/}
          {aor && (
            <div className="detail-block aor-block">
              <Aor aor={aor} />
            </div>
          )}
        </div>
        <div
          className={
            benefitsVisible
              ? 'accordion-block-minimal is-open'
              : 'accordion-block-minimal'
          }
        >
          <div className="accordion-header">
            <div className="trigger">
              <Button
                aria-label={`View membership benefits for membership ${membership_number__c}`}
                className="link-secondary"
                onClick={toggleBenefitsVisisble}
              >
                View Membership Benefits
                <img alt="" className="icon" src={IMAGE_PATH.DROPDOWN_ICON} />
              </Button>
            </div>
          </div>
          {benefitsVisible && (
            <MembershipBenefits
              upgradePath={makeMembershipIdPath(
                membershipSfid,
                PATH.UPGRADES,
                PATH.MEMBERSHIP,
                PATH.SELECTION,
              )}
              memberType={membership_type__c}
              membershipPrices={membershipPrices}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isMonthlyAccountType: globalSelectors.isMonthlyAccountType(state),
    isSubsidizedAccountType: globalSelectors.isSubsidizedAccountType(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

const mapDispatchToProps = {}

Membership.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(Membership)
