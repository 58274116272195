import { isNil } from 'lodash'
import { COUNTRY_STATES } from 'config'

const stateListFromCountry = (country) => {
  const stateList = COUNTRY_STATES[country]

  if (isNil(stateList)) return ['Select country...']

  return stateList
}

export default stateListFromCountry
