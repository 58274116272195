import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { BackLink } from 'components'
import { MembershipType, MEMBERSHIP_TYPE } from 'types'
import { PATH, makeMemberPortalPath } from 'config'

const propTypes = {
  backLinkText: PropTypes.string,
  backLinkTo: PropTypes.string,
  isTrialMembershipsActive: PropTypes.bool,
  membership: MembershipType,
}

function MembershipPageHeader({
  backLinkText = 'Back to Membership Dashboard',
  backLinkTo = makeMemberPortalPath(PATH.MEMBERSHIPS),
  isTrialMembershipsActive,
  membership,
}) {
  const { membership_number__c, membership_type__c } = membership
  const isTrialMembership =
    membership_type__c === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP

  return (
    <header className="page-header">
      <div className="inner-block-container">
        <div className="inner">
          <div className="text-block">
            <h1>
              {isTrialMembership && isTrialMembershipsActive
                ? membership_type__c
                : `${membership_type__c} Membership`}
            </h1>
            <p>Membership #{membership_number__c}</p>
          </div>
          <div className="actions">
            <BackLink linkText={backLinkText} to={backLinkTo} />
          </div>
        </div>
      </div>
    </header>
  )
}

MembershipPageHeader.propTypes = exact(propTypes)

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, {}))(MembershipPageHeader)
