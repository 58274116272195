import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { matchPath } from 'react-router-dom'
import { ButtonLink } from 'components'
import classnames from 'classnames'
import { StepLinkType } from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  stepLinks: PropTypes.arrayOf(StepLinkType).isRequired,
}

function ApplicationSteps({ pathname, stepLinks, children }) {
  const [openStepIndex, setOpenStepIndex] = useState(0)

  const updateStepIndex = (index) => {
    // don't update if no change to avoid infinite re-renders
    if (index === openStepIndex) return
    setOpenStepIndex(index)
  }

  return stepLinks.map(({ stepName, path, stepIcon }, index) => {
    const isOpen = !!matchPath(pathname, { path, exact: true })
    if (isOpen) updateStepIndex(index)
    const isFutureStep = index > openStepIndex
    const canEdit = !isFutureStep && !isOpen

    return (
      <section
        key={stepName}
        className={classnames({ 'unavailable-step': isFutureStep, 'completed-step': canEdit, })}
      >
        <div className="card">
          <header>
            <img
              src={stepIcon}
              alt=""
              className="icon"
            />
            <h3>{`${stepName}`}</h3>
            {canEdit && (
              <ButtonLink to={path} aria-label={`Edit ${stepName}`} className="link-primary">
                Edit
              </ButtonLink>
            )}
          </header>
          <div className="card-inner">
            {isOpen && children}
          </div>
        </div>
      </section>
    )
  })
}

ApplicationSteps.propTypes = exact(propTypes)

export default pure(ApplicationSteps)
