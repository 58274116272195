import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { SortableTable, TableColumn } from 'lp-components'
import { formatCurrency, formatUSMonthDayYearDateString } from 'utils'

const propTypes = {}

function PaymentHistory() {
  return (
    <div>
      <PaymentHistoryHeader />
      <PaymentHistoryDetail />
    </div>
  )
}

PaymentHistory.propTypes = exact(propTypes)

export default pure(PaymentHistory)

function PaymentHistoryHeader() {
  return <h4>Payment History</h4>
}

function PaymentHistoryDetail() {
  const mockInvoiceData = [
    {
      date: '2021-01-10',
      type: 'Gold Card + Trailer Care Marine + Sea Tow Foundation Donation',
      amount: 210.0,
    },
    {
      date: '2020-01-10',
      type: 'Gold Card + Trailer Care Marine',
      amount: 200.0,
    },
    {
      date: '2019-01-10',
      type: 'Gold Card',
      amount: 179.0,
    },
    {
      date: '2018-01-10',
      type: 'Gold Card + Trailer Care Marine',
      amount: 199.0,
    },
  ]

  return (
    <SortableTable data={mockInvoiceData} initialColumn="date">
      <TableColumn name="date" format={formatUSMonthDayYearDateString} />
      <TableColumn name="type" />
      <TableColumn name="amount" format={(amount) => formatCurrency(amount)} />
    </SortableTable>
  )
}
