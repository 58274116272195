import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { PageHeader, ButtonLink } from 'components'
import { PATH, makeProgramsPortalPath } from 'config'
import checkmarkIcon from 'utility-icons/completed-yellow.svg'

const propTypes = {}

function ConfirmOffer() {
  return (
    <>
      <PageHeader headerText="Savings Club" />
      <section className="form-block-container">
        <div className="card card-small centered">
          <div className="card-inner">
            <header>
              {/* Image will need to be replaced with black checkmark icon */}
              <img src={checkmarkIcon} alt="" className="icon" />
              <h2>Offer Created!</h2>
            </header>
            <p>
              Your offer will be reviewed by the Sea Tow team. Within 72 hours
              we will be in touch with the deal approval.
            </p>
            <ButtonLink
              className="button-primary"
              to={makeProgramsPortalPath(PATH.SAVINGS_CLUB_OFFERS)}
            >
              Back to Savings Club
            </ButtonLink>
          </div>
        </div>
      </section>
    </>
  )
}

ConfirmOffer.propTypes = exact(propTypes)

export default pure(ConfirmOffer)
