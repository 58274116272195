import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'lp-components'
import { AccountHolderForm } from '../forms'
import {
  API_KEY_MAP,
  AccountType,
  ContactType,
  isMemberBusinessAccountType,
} from 'types'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  useToggle,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  formOpen: PropTypes.bool,
  primaryContact: ContactType.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

function AccountHolder({
  account,
  fetchAccount,
  formOpen,
  primaryContact,
  updateAccount,
}) {
  const [accountFormOpen, toggleAccountForm] = useToggle(formOpen)
  const isBusinessAccount = isMemberBusinessAccountType(
    account.account_detail_type__c
  )

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Account-Holder.svg"
          alt=""
          className="icon"
        />
        <h2>Account Holder Information</h2>
      </header>
      <div className="card-inner">
        {accountFormOpen ? (
          <AccountHolderForm
            isBusinessAccount={isBusinessAccount}
            toggleAccountForm={toggleAccountForm}
            primaryContact={primaryContact}
            initialValues={formValuesWithMappedKeys(
              account,
              API_KEY_MAP.ACCOUNT_KEY_MAP
            )}
            onSubmit={(formValues) => {
              const apiValues = apiValuesWithMappedKeys(
                formValues,
                API_KEY_MAP.ACCOUNT_KEY_MAP
              )
              apiValues.sfid = account.sfid
              apiValues.id = account.id
              return updateAccount(apiValues)
            }}
            onSubmitSuccess={() => {
              fetchAccount(account.sfid)
              toggleAccountForm()
            }}
          />
        ) : (
          <div className="row">
            <div className="col-6">
              <h3>Account Holder</h3>
              <p>{fullName(primaryContact)}</p>
            </div>
            <div className="col-6">
              <h3>Mailing Address</h3>
              <Button onClick={toggleAccountForm} className="link-primary">
                (Edit)
              </Button>
              <p>{address(account)}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function address(account) {
  return `${account.shippingstreet}, ${account.shippingcity}, ${account.shippingstate} ${account.shippingpostalcode} ${account.shippingcountry}`
}

function fullName(primaryContact) {
  return [primaryContact.firstname, primaryContact.lastname].filter(Boolean).join(' ') || 'N/A'
}

AccountHolder.propTypes = propTypes

export default AccountHolder
