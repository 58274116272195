// Return address with establishment name if available

function formatDisplayFromAddressSearchResult(searchResult) {
  const { formatted_address, name, types } = searchResult

  return types.includes('establishment')
    ? `${name}, ${formatted_address}`
    : formatted_address
}

export default formatDisplayFromAddressSearchResult
