import { isEmpty, pick } from 'lodash'

export default function getHomePortFromFormValues(formValues) {
  if (isEmpty(formValues)) {
    return {}
  }
  return pick(formValues, [
    'lat',
    'lng',
    'address',
    'city',
    'country',
    'homePortName',
    'state',
    'homePortType',
    'aor',
  ])
}
