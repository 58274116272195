import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DISCOUNT_CODE_TYPE } from 'types'
import * as LS from 'local-storage'
import { snakeCase } from 'lodash'
import { QUERY_KEY } from 'config'
import { isTrialPromoType, parseQuery } from 'utils'

const useTrackingParams = () => {
  const { search } = useLocation()
  const query = parseQuery(search)
  const utmQueryParams = Object.fromEntries(
    Object.entries(query)
      .filter(([key]) => key.toLowerCase().includes('utm'))
      .map(([key, value]) => [snakeCase(key), value]),
  )
  setAdTrackingToLS(utmQueryParams)

  if (QUERY_KEY.MSCLKID in query && !!query[QUERY_KEY.MSCLKID]) {
    LS.setMemberApplicationMSClickId(query[QUERY_KEY.MSCLKID])
  }

  const storedActivationCode = useMemberPortalSelector('activationCode')
  const storedPromoCode = useMemberPortalSelector('promoCode')
  const storedGCLid = useMemberPortalSelector('gclid')
  const storedReferralInfo = useMemberPortalSelector('referralInfo')
  const storedTestVariant = useMemberPortalSelector('testVariant')

  const storedMembership = useMemberPortalSelector('membership')
  const renewalPromoCode = storedMembership?.renewal_promotion_details?.name

  return {
    activationCode:
      query[DISCOUNT_CODE_TYPE.ACTIVATION] ?? storedActivationCode,
    promoCode: isTrialPromoType(storedPromoCode)
      ? storedPromoCode
      : query[DISCOUNT_CODE_TYPE.PROMO] ?? storedPromoCode ?? renewalPromoCode,
    gclid: query[QUERY_KEY.GCLID] ?? storedGCLid,
    referralId: query[QUERY_KEY.REFERRAL] ?? storedReferralInfo?.referralId,
    testVariant: query[QUERY_KEY.TEST_VARIANT] ?? storedTestVariant,
  }
}

export default useTrackingParams

// Use custom selector to access values from the Redux store
const useMemberPortalSelector = (key) => {
  return useSelector((state) => state.root?.memberPortal?.global[key])
}

// Helpers
function setAdTrackingToLS(queryParams) {
  let adTrackingToUpdate = LS.getMemberApplicationAdTracking() ?? {}

  Object.keys(queryParams).forEach((key) => {
    if (!queryParams[key]) return
    else if (key.endsWith('_first') || key.endsWith('_last')) {
      adTrackingToUpdate[key] = queryParams[key]
    } else {
      const isFirstTime = adTrackingToUpdate[`${key}_first`] === undefined
      const mappedKey = isFirstTime ? `${key}_first` : `${key}_last`
      adTrackingToUpdate[mappedKey] = queryParams[key]
    }
  })

  if (Object.keys(queryParams).length) {
    LS.setMemberApplicationAdTracking(adTrackingToUpdate)
  }
}