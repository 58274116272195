import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useLocation } from 'react-router-dom'
import { ButtonLink, PageHeader } from 'components'
import completed from 'utility-icons/completed-yellow.svg'
import { PATH, QUERY_KEY, makePath } from 'config'
import { API_RESPONSE } from 'types'
import { parseQuery } from 'utils'

const propTypes = {}

function PostApplicationLogin() {
  const { search } = useLocation()
  const query = parseQuery(search)
  const status = query[QUERY_KEY.STATUS]

  return (
    <>
      <PageHeader headerText="Log In" />
      <div className="dashboard-block-container">
        <div className="card card-small centered">
          <div className="card-inner">
            <header>
              <img alt="success" src={completed} className="icon" />
              <h2>Application Submitted!</h2>
            </header>
            <p>{accountInstructionsFromStatus(status)}</p>
            <ButtonLink
              className="button-primary"
              to={makePath(PATH.PROGRAMS_PORTAL)}
            >
              Get Started
            </ButtonLink>
          </div>
        </div>
      </div>
    </>
  )
}

PostApplicationLogin.propTypes = exact(propTypes)

export default pure(PostApplicationLogin)

function accountInstructionsFromStatus(status) {
  if (status === API_RESPONSE.AUTH0_ACCOUNT_FOUND) {
    return "It looks like you already have an account with Sea Tow. You may now use those credentials to log in to your program's portal."
  }

  return 'While your application is pending approval, you may still access your account and get started.'
}
