import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  Field,
  FormSection,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { lpForm } from 'lp-form'
import {
  Checkbox,
  RadioGroup,
  Select,
  SubmitButton,
  Textarea,
} from 'lp-components'
import { GiftContactFields } from '../components'
import {
  ADDRESS_FORM_VALIDATOR,
  COST,
  GIFT_MESSAGE_LENGTH,
  SHIPPING_METHOD_SELECTION,
} from 'config'
import { GIFT_MAILING_OPTIONS } from 'types'
import {
  formatCurrency,
  getLocalityFromGooglePlace,
  formatSubpremise,
} from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  country: PropTypes.string,
  fetchPlaceDetails: PropTypes.func.isRequired,
  mailedToGifter: PropTypes.bool,
  ...formPropTypes,
}

function MailedRecipientForm({
  autoCompleteAddress,
  change,
  country,
  fetchPlaceDetails,
  handleSubmit,
  mailedToGifter,
  submitting,
}) {
  const updateAddressValues = useCallback(async (searchResult) => {
    const googlePlace = await fetchPlaceDetails(searchResult.place_id)
    const {
      streetNumber,
      street,
      city,
      state,
      country,
      postalCode,
      subpremise,
    } = getLocalityFromGooglePlace(googlePlace)

    if (subpremise) {
      const formattedSubpremise = formatSubpremise(subpremise)
      change(
        'recipientContact.address',
        `${streetNumber} ${street}, ${formattedSubpremise}`
      )
    } else {
      change('recipientContact.address', `${streetNumber} ${street}`)
    }
    change('recipientContact.city', city)
    change('recipientContact.state', state)
    change('recipientContact.country', country)
    change('recipientContact.postalCode', postalCode)
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        component={RadioGroup}
        format={mailedToGifterToMailingOption}
        label="Select Where the Gift Will Be Mailed"
        name="mailedToGifter"
        normalize={mailingOptionToMailedToGifter}
        options={Object.values(GIFT_MAILING_OPTIONS)}
      />

      <FormSection name="recipientContact">
        <GiftContactFields
          {...{
            autoCompleteAddress,
            change,
            country,
            updateAddressValues,
          }}
          withEmail={mailedToGifter}
          usageLabel="Mailing"
        />
      </FormSection>

      <Field
        component={Select}
        label="Shipping Options"
        name="shippingMethod"
        options={SHIPPING_METHOD_SELECTION}
        required
        requiredIndicator="*"
      />
      <Field
        component={Textarea}
        label="Gift Message"
        maxLength={GIFT_MESSAGE_LENGTH}
        name="giftMessage"
        placeholder="Optional gift message"
      />
      <Field
        component={Checkbox}
        label={`Include gift wrapping (${formatCurrency(
          COST.GIFT_WRAPPING,
          0
        )})`}
        name="giftWrapped"
      />
      <div className="button-group">
        <SubmitButton {...{ submitting }}>Save & Continue</SubmitButton>
      </div>
    </form>
  )
}

MailedRecipientForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'MailedRecipientForm',
    constraints: {
      ['recipientContact.address']: ADDRESS_FORM_VALIDATOR,
      ['recipientContact.city']: { presence: { allowEmpty: false } },
      ['recipientContact.country']: { presence: { allowEmpty: false } },
      ['recipientContact.email']: { email: true, presence: true },
      ['recipientContact.firstName']: { presence: { allowEmpty: false } },
      ['recipientContact.lastName']: { presence: { allowEmpty: false } },
      ['recipientContact.phone']: { presence: { allowEmpty: false } },
      ['recipientContact.postalCode']: { presence: { allowEmpty: false } },
      ['recipientContact.state']: { presence: { allowEmpty: false } },
    },
  }),
  formValues({
    country: 'recipientContact.country',
    mailedToGifter: 'mailedToGifter',
  })
)(MailedRecipientForm)

function mailingOptionToMailedToGifter(value) {
  return value === GIFT_MAILING_OPTIONS.TO_ME
}

function mailedToGifterToMailingOption(mailedToGifter) {
  return mailedToGifter
    ? GIFT_MAILING_OPTIONS.TO_ME
    : GIFT_MAILING_OPTIONS.TO_RECIPIENT
}
