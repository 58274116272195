import React from 'react'
import PropTypes from 'prop-types'
import { SavingsClubOfferType, OFFER_STATUS } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Modal, Button } from 'lp-components'
import { SavingsClubOffer } from 'components'
import { useToggle } from 'utils'

const propTypes = {
  offer: SavingsClubOfferType.isRequired,
  updateOffer: PropTypes.func.isRequired,
  fetchOffers: PropTypes.func.isRequired,
  showActions: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
}

function Offer({ offer, updateOffer, fetchOffers, showActions, accountId }) {
  const { sfid } = offer
  const [showModal, toggleModal] = useToggle()

  return (
    <>
      <SavingsClubOffer removeOffer={toggleModal} {...{ offer, showActions }} />
      {showModal && (
        <Modal onClose={() => toggleModal()}>
          <h2>Are you sure you want to remove this offer?</h2>
          <p>
            By removing this offer it will no longer show for Sea Tow members
            and it will be archived permanently.
          </p>
          <div className="button-group">
            <Button onClick={toggleModal} className="button-primary-outline">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await updateOffer({
                  sfid,
                  offer_status__c: OFFER_STATUS.INACTIVE,
                })
                await fetchOffers(accountId)
              }}
              className="button-warn"
            >
              Remove this Offer
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

Offer.propTypes = exact(propTypes)

export default pure(Offer)
