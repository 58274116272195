import { isEmpty } from 'lodash'
import apiErrorToErrorMessage from './apiErrorToErrorMessage'

export default function errorMessageFromFormErrors(errors, submitError) {
  // Redux-form processes and returns form submission errors as follows:
  //  + If form validation errors were raised without any external interaction
  //    (e.g., an API call) then "errors" will be set with a property set for each
  //    validation error and "submitError" will be undefined.
  //  + If form validation errors were raised due to custom validations generating
  //    a SubmissionError against a form field, both "errors" and "submitError"
  //    will be defined. Note that this case is differentiated by the content
  //    of the SubmissionError. See the external errors case below.
  //  + If external errors were raised, "errors._error" is set and
  //    "submitError" will be defined with an SubmissionError instance whose
  //    "meta.error" property contains the contents of the HttpError.
  //
  // If validation errors have been raised, this function will return either the
  // overall form validation error (errors_error) if it has been set or a generic
  // message. Otherwise, if external errors were raised, this function will
  // return the value of apiErrorToErrorMessage() called with errors._error.
  if (isEmpty(errors)) return null

  const hasValidationErrors = !isEmpty(errors) && isEmpty(submitError)
  const hasCustomValidationErrors =
    submitError?.meta?.error?.name !== 'HttpError'
  const { _error: formWideError } = errors

  if (hasValidationErrors || hasCustomValidationErrors) {
    return (
      formWideError ??
      'Please review the information provided and correct all form errors'
    )
  }

  return apiErrorToErrorMessage(formWideError)
}
