import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink } from 'components'

const propTypes = {
  image: PropTypes.string.isRequired,
  infoLink: PropTypes.string.isRequired,
  programDescription: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
}

function ProgramsSignup({ image, infoLink, programDescription, programName }) {
  return (
    <div className="card image-card with-inline-image">
      <div className="image-block">
        <img src={image} alt="" />
      </div>
      <div className="text-block">
        <h3>{programName}</h3>
        <p>{programDescription}</p>
        <ButtonLink
          aria-label={`Learn more about ${programName}`}
          className="button-primary"
          to={infoLink}
        >
          Learn More
        </ButtonLink>
      </div>
    </div>
  )
}

ProgramsSignup.propTypes = exact(propTypes)

export default pure(ProgramsSignup)
