import { COST } from 'config'

function calculateReferralCreditsUsed(cost, totalReferralCredits) {
  // Referral credits are applied in multiples of COST.REFERRAL_CREDIT_MULTIPLER
  // with whole multipler values always used. For example, if the multipler is 25
  // and the cost is $100, 4 credits would be used. If the cost is $101, 5
  // credits would be used.
  //
  // The total number of credits multipled by the multiplier is returned. So, to
  // continue the previous example, is the cost was $100, 100 would be returned.
  // If the cost was $101, $125 would be returned.
  if (cost <= 0) return 0

  // If the total number of credits cover the entire cost, return the number of
  // total amount of credits used multipled by the multipler.
  const multipler = COST.REFERRAL_CREDIT_MULTIPLIER
  if (cost - totalReferralCredits <= 0) {
    return Math.ceil(cost / multipler) * multipler
  }

  // Otherwise, use all existing credits...
  return totalReferralCredits
}

export default calculateReferralCreditsUsed
