import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {}

function PostLogin() {
  return null
}

PostLogin.propTypes = exact(propTypes)

export default pure(PostLogin)
