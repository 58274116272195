import React from 'react'
import { compose } from 'recompose'
import { Field, formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

function ReferABoaterForm({ handleSubmit, pristine, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group">
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="First Name"
              name="firstName"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-6">
            <Field
              component={Input}
              label="Last Name"
              name="lastName"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
        <Field
          component={Input}
          label="Email"
          name="email"
          required
          requiredIndicator="*"
        />

        <SubmitButton
          {...{ pristine, submitting }}
        >
          Submit
        </SubmitButton>
      </div>
    </form>
  )
}

ReferABoaterForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'ReferABoaterForm',
    constraints: {
      firstName: { presence: true },
      lastName: { presence: true },
      email: { email: true, presence: true },
    },
  })
)(ReferABoaterForm)
