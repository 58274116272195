import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { isEmpty } from 'lodash'
import { lpForm } from 'lp-form'
import { RadioGroup, SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { GIFT_DELIVERY_DESCRIPTION } from 'types'

const propTypes = {
  clearForNewDeliveryMethod: PropTypes.func.isRequired,
  ...formPropTypes,
}

function DeliverySelectionForm({
  clearForNewDeliveryMethod,
  handleSubmit,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        component={RadioGroup}
        label="Select Delivery Method"
        name="deliveryMethod"
        onChange={clearForNewDeliveryMethod}
        options={deliveryOptions(GIFT_DELIVERY_DESCRIPTION)}
      />
      <div className="button-group">
        <SubmitButton className="button-primary" {...{ submitting }}>
          Save & Continue
        </SubmitButton>
      </div>
    </form>
  )
}

DeliverySelectionForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'DeliverySelectionForm',
    constraints: {
      deliveryMethod: { presence: true },
    },
  })
)(DeliverySelectionForm)

function deliveryOptions(deliveryDescription) {
  if (isEmpty(deliveryDescription)) {
    throw new Error('Empty option descriptions')
  }

  return Object.entries(deliveryDescription).map(([key, value]) => ({
    key: value,
    value: key,
  }))
}
