import React from 'react'
import PropTypes from 'prop-types'
import { AccountType, ContactType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { NewMemberContactInfo } from '../components'
import { EditMemberContactInfo } from 'member-portal/components'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { makeMemberApplicationApplyPath, PATH } from 'config'

const propTypes = {
  initialAccount: AccountType,
  initialContact: ContactType,
  applicationMembershipId: PropTypes.string,
  autoCompleteAddress: PropTypes.func.isRequired,
  createMembershipApplicationAccount: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  selectedTrailerCareType: PropTypes.string,
  shoppingCartMembershipType: PropTypes.string,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
  verifyUniquePrimaryEmail: PropTypes.func.isRequired,
}

function MemberContactInfo({
  initialAccount,
  initialContact,
  applicationMembershipId,
  autoCompleteAddress,
  createMembershipApplicationAccount,
  fetchPlaceDetails,
  selectedTrailerCareType,
  shoppingCartMembershipType,
  updateMembershipApplicationAccount,
  verifyUniquePrimaryEmail,
}) {
  const isEditing = !!(initialAccount && initialContact)
  const successPath = makeMemberApplicationApplyPath(PATH.BOATS)

  if (isEditing)
    return (
      <EditMemberContactInfo
        {...{
          initialAccount,
          initialContact,
          applicationMembershipId,
          autoCompleteAddress,
          fetchPlaceDetails,
          successPath,
          updateMembershipApplicationAccount,
        }}
      />
    )

  return (
    <NewMemberContactInfo
      {...{
        autoCompleteAddress,
        createMembershipApplicationAccount,
        fetchPlaceDetails,
        selectedTrailerCareType,
        shoppingCartMembershipType,
        successPath,
        verifyUniquePrimaryEmail,
        updateMembershipApplicationAccount,
      }}
    />
  )
}

MemberContactInfo.propTypes = propTypes

function mapStateToProps(state) {
  return {
    initialAccount: memberSelectors.account(state),
    initialContact: memberSelectors.contact(state),
    applicationMembershipId: memberSelectors.applicationMembershipId(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    shoppingCartMembershipType: memberSelectors.shoppingCartMembershipType(
      state
    ),
  }
}

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
  createMembershipApplicationAccount:
    apiActions.createMembershipApplicationAccount,
  fetchPlaceDetails: apiActions.fetchPlaceDetails,
  updateMembershipApplicationAccount:
    apiActions.updateMembershipApplicationAccount,
  verifyUniquePrimaryEmail: apiActions.verifyUniquePrimaryEmail,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MemberContactInfo
)
