import * as LS from 'local-storage'
import { DISCOUNT_CODE_TYPE } from 'types'
import parseCreditCardAndBillingAddress from './parseCreditCardAndBillingAddress'
import { mapKeys } from 'lodash'

const apiValuesWithPaymentDetails = ({
  account,
  billing,
  discountCodeDetails,
  discountedMembershipCost,
  gclid,
  membership,
  referralCreditsUsed,
  referralInfo,
  referredByMemberNumber,
  oppGuid = null,
  smsOptIn,
  testVariant,
}) => {
  const { heroku_external_id__c: herokuAccountId } = account
  const { finalDonation: donation_amount, subtotalWithoutDonations: subtotal } =
    billing
  const {
    heroku_external_id__c: herokuMembershipId,
    shopping_cart_membership_type__c,
    shopping_cart_trailer_care_type__c,
  } = membership
  const { referralId, fetchedReferrerMemberNumber } = referralInfo || {}
  const isReferralMatch = fetchedReferrerMemberNumber === referredByMemberNumber

  const cc_details = parseCreditCardAndBillingAddress(billing)

  // Payload contains total_cost which includes costs associated with the
  // membership type (and and applied discounts), any trailer care packages added,
  // plus the donation_amount. The API will query the selected
  // products, adds them together, and verifies the total matches the payload.
  // Any mismatch will result in an error reponse PRICE_MISMATCH
  let payload = {
    cc_details,
    donation_amount,
    total_cost: subtotal + donation_amount,
    account: {
      heroku_external_id__c: herokuAccountId,
    },
    membership__c: {
      heroku_external_id__c: herokuMembershipId,
    },
    opp_guid: oppGuid,
    shopping_cart_membership_type__c,
    shopping_cart_trailer_care_type__c,
    sms_opt_in__c: smsOptIn,
  }

  // Get Bing Ad Tracking ID from LS
  const msclkid = LS.getMemberApplicationMSClickId()

  // Set Ad Tracking to payload
  const adTracking = LS.getMemberApplicationAdTracking() ?? {}
  const hasAdTracking = Object.values(adTracking).some((param) => !!param)
  if (hasAdTracking) {
    const mappedAdTracking = mapKeys(adTracking, (_, key) => `${key}__c`)
    payload = { ...payload, ...mappedAdTracking }
  }
  

  if (referredByMemberNumber) payload.referred_by = referredByMemberNumber
  if (isReferralMatch) payload.referral_id = referralId
  if (testVariant) payload.test_variant__c = testVariant
  if (gclid) payload.gclid__c = gclid
  if (msclkid) payload.msclkid__c = msclkid

  // Referral credits reduce the total amount but apply *only* to the membership
  // cost. Determine if the credits used will cover the total cost of the
  // membership. If so, charge just for the trailer care package and the
  // donation. Otherwise, simply reduce the total by the referral credits used.
  if (referralCreditsUsed) {
    payload.referral_credits_used = referralCreditsUsed

    if (referralCreditsUsed >= discountedMembershipCost) {
      const trailerCareCost = subtotal - discountedMembershipCost

      payload.total_cost = trailerCareCost + donation_amount
    } else {
      // Remove the referral credits used from the total
      payload.total_cost -= referralCreditsUsed
    }
  }

  return insertDiscountCodeId(payload, discountCodeDetails)
}

function insertDiscountCodeId(payload, discountCodeDetails) {
  if (!discountCodeDetails) return payload
  if (discountCodeDetails.type === DISCOUNT_CODE_TYPE.ACTIVATION)
    return { ...payload, activation_code_id: discountCodeDetails.id }
  return { ...payload, promo_code_id: discountCodeDetails.id }
}

export default apiValuesWithPaymentDetails
