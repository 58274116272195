import React from 'react'
import PropTypes from 'prop-types'
import { OFFER_CATEGORY_ICON_PATH } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  category: PropTypes.string.isRequired,
}

function OfferCategoryIcon({ category }) {
  const imagePath = OFFER_CATEGORY_ICON_PATH[category]
  if (!imagePath) return null

  return <img src={imagePath} alt="" className="icon" />
}

OfferCategoryIcon.propTypes = exact(propTypes)

export default pure(OfferCategoryIcon)
