import { pick, mapKeys } from 'lodash'

// NOTE: the provided key map is of the form:
//    { apiKey: formKey }
// For example: { billingcity: 'city' }
const formValuesWithMappedKeys = (object, keyMap) => {
  // First, create a subset object containing only those API keys in the provided key map.
  const formValues = pick(object, Object.keys(keyMap))
  const mapToFormKeys = (_, key) => keyMap[key]

  // Then, return the subset object with the API keys mapped to the form keys.
  return mapKeys(formValues, mapToFormKeys)
}

export default formValuesWithMappedKeys
