// API for interacting with localStorage

// Selected Membership Type
const SELECTED_MEMBERSHIP_TYPE_KEY = 'selected-membership-type'

export function getSelectedMembershipType() {
  return getItem(SELECTED_MEMBERSHIP_TYPE_KEY)
}

export function setSelectedMembershipType(membershipType) {
  return setItem(SELECTED_MEMBERSHIP_TYPE_KEY, membershipType)
}

export function clearSelectedMembershipType() {
  return removeItem(SELECTED_MEMBERSHIP_TYPE_KEY)
}

// Trial Membership Type
const TRIAL_MEMBERSHIP_TYPE_KEY = 'trial-membership-type'

export function getTrialMembershipType() {
  return getItem(TRIAL_MEMBERSHIP_TYPE_KEY)
}

export function setTrialMembershipType(trialType) {
  return setItem(TRIAL_MEMBERSHIP_TYPE_KEY, trialType)
}

export function clearTrialMembershipType() {
  return removeItem(TRIAL_MEMBERSHIP_TYPE_KEY)
}

// Selected Trailer Care Type
const SELECTED_TRAILER_CARE_TYPE_KEY = 'selected-trailer-care-type'

export function getSelectedTrailerCareType() {
  return getItem(SELECTED_TRAILER_CARE_TYPE_KEY)
}

export function setSelectedTrailerCareType(trailerCareType) {
  return setItem(SELECTED_TRAILER_CARE_TYPE_KEY, trailerCareType)
}

export function clearSelectedTrailerCareType() {
  return removeItem(SELECTED_TRAILER_CARE_TYPE_KEY)
}

// Member Application Account ID
const MEMBER_APPLICATION_ACCOUNT_ID = 'application-account-id'

export function getMemberApplicationAccountId() {
  return getItem(MEMBER_APPLICATION_ACCOUNT_ID)
}

export function setMemberApplicationAccountId(accountId) {
  return setItem(MEMBER_APPLICATION_ACCOUNT_ID, accountId)
}

export function clearMemberApplicationAccountId() {
  return removeItem(MEMBER_APPLICATION_ACCOUNT_ID)
}

// Member Application Membership ID
const MEMBER_APPLICATION_MEMBERSHIP_ID = 'application-membership-id'

export function getMemberApplicationMembershipId() {
  return getItem(MEMBER_APPLICATION_MEMBERSHIP_ID)
}

export function setMemberApplicationMembershipId(membershipId) {
  return setItem(MEMBER_APPLICATION_MEMBERSHIP_ID, membershipId)
}

export function clearMemberApplicationMembershipId() {
  return removeItem(MEMBER_APPLICATION_MEMBERSHIP_ID)
}

// Discount Codes
const MEMBER_APPLICATION_ACTIVATION_CODE = 'application-activation-code'

export function getMemberApplicationActivationCode() {
  return getItem(MEMBER_APPLICATION_ACTIVATION_CODE)
}

export function setMemberApplicationActivationCode(activationCode) {
  return setItem(MEMBER_APPLICATION_ACTIVATION_CODE, activationCode)
}

export function clearMemberApplicationActivationCode() {
  return removeItem(MEMBER_APPLICATION_ACTIVATION_CODE)
}

// Promo Code
const MEMBER_APPLICATION_PROMO_CODE = 'application-promo-code'

export function getMemberApplicationPromoCode() {
  return getItem(MEMBER_APPLICATION_PROMO_CODE)
}

export function setMemberApplicationPromoCode(promoCode) {
  return setItem(MEMBER_APPLICATION_PROMO_CODE, promoCode)
}

export function clearMemberApplicationPromoCode() {
  return removeItem(MEMBER_APPLICATION_PROMO_CODE)
}

// Ad Tracking
const MEMBER_APPLICATION_AD_TRACKING = 'application-ad-tracking'

export function getMemberApplicationAdTracking() {
  const now = new Date().getTime()
  const data = JSON.parse(getItem(MEMBER_APPLICATION_AD_TRACKING))
  
  if (!data) return
  if (now < data.expiry) return data
  removeItem(MEMBER_APPLICATION_AD_TRACKING)
}

export function setMemberApplicationAdTracking(adTrackingData) {
  const data = {
    ...adTrackingData,
    expiry: new Date().getTime() + 30 * 24 * 3600 * 1000
  }
  return setItem(MEMBER_APPLICATION_AD_TRACKING, JSON.stringify(data))
}

// *TODO: The following LS item is intended to capture additional campaign
// information for Sea Tow and then store the data into SF through the url's
// query parameters. However, there is still uncertainty what those query
// parameters are and still being decided by the Marketing Team. In the
// intermediary, the request is to use "test-variant" as a placeholder until
// this is decided.
// Test Variant
const MEMBER_APPLICATION_TEST_VARIANT = 'application-test-variant'

export function getMemberApplicationTestVariant() {
  return getItem(MEMBER_APPLICATION_TEST_VARIANT)
}

export function setMemberApplicationTestVariant(testVariantId) {
  return setItem(MEMBER_APPLICATION_TEST_VARIANT, testVariantId)
}

export function clearMemberApplicationTestVariant() {
  return removeItem(MEMBER_APPLICATION_TEST_VARIANT)
}

// Referral Id
const MEMBER_APPLICATION_REFERRAL_INFO = 'application-referral-info'

export function getMemberApplicationReferralInfo() {
  return getItem(MEMBER_APPLICATION_REFERRAL_INFO)
}

export function setMemberApplicationReferralInfo(referralInfo) {
  return setItem(MEMBER_APPLICATION_REFERRAL_INFO, referralInfo)
}

export function clearMemberApplicationReferralInfo() {
  return removeItem(MEMBER_APPLICATION_REFERRAL_INFO)
}

// Google Ads (gclid)
const MEMBER_APPLICATION_GCLID = 'application-gclid'

export function getMemberApplicationGCLid() {
  return getItem(MEMBER_APPLICATION_GCLID)
}

export function setMemberApplicationGCLid(gclid) {
  return setItem(MEMBER_APPLICATION_GCLID, gclid)
}

export function clearMemberApplicationGCLid() {
  return removeItem(MEMBER_APPLICATION_GCLID)
}

// Bing Ads (msclkid)
const MEMBER_APPLICATION_MS_CLICK_ID = 'application-ms-click-id'

export function getMemberApplicationMSClickId() {
  return getItem(MEMBER_APPLICATION_MS_CLICK_ID)
}

export function setMemberApplicationMSClickId(msclkid) {
  return setItem(MEMBER_APPLICATION_MS_CLICK_ID, msclkid)
}

export function clearMemberApplicationMSClickId() {
  return removeItem(MEMBER_APPLICATION_MS_CLICK_ID)
}

/* HELPERS */

// Get item from localStorage
function getItem(key) {
  const item = localStorage.getItem(key)

  try {
    return JSON.parse(item)
  } catch (e) {
    return item
  }
}

// Set item in local storage
function setItem(key, value) {
  const stringifiedValue = JSON.stringify(value)
  localStorage.setItem(key, stringifiedValue)
}

// Remove item from local storage
function removeItem(key) {
  localStorage.removeItem(key)
}
