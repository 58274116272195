import React from 'react'
import { pure } from 'recompose'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import { PageHeader } from 'components'
import { ERROR_STATUS } from 'types'
import {
  APPLICATION,
  AUTHORIZED_APPLICATION,
  PATH,
  QUERY_KEY,
  SEATOW_PROGRAMS_CONTACT_EMAIL,
  makePath,
} from 'config'
import { getApplicationPath, parseQuery } from 'utils'

const propTypes = {}

function ClientError() {
  const location = useLocation()
  const { search } = location
  const query = parseQuery(search)
  const application = query[QUERY_KEY.APPLICATION]

  switch (query[QUERY_KEY.STATUS]) {
    case ERROR_STATUS.API_DOWN:
      return <ApiDownError />

    case ERROR_STATUS.FORBIDDEN:
    case ERROR_STATUS.UNAUTHORIZED:
      return <AuthorizationError app={application} />

    case ERROR_STATUS.NOT_FOUND:
      return <NotFoundError app={application} />

    default:
      throw new Error('Unknown client error')
  }
}

ClientError.propTypes = propTypes

export default pure(ClientError)

function ApiDownError() {
  const history = useHistory()

  return (
    <>
      <PageHeader headerText="Network Error" />
      <section className="form-block-container">
        <div className="empty-state">
          <header>
            <h2>Network Unavailable</h2>
          </header>
          <div className="card-inner">
            <p>The network is unavailable. Please retry your request again.</p>
            <Button
              className="button-primary"
              onClick={() => history.goBack()}
            >
              Retry Request
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

// Authorization errors will be handled only for applications that require
// login...
function AuthorizationError({ app }) {
  const history = useHistory()
  const isAuthorizedApplication = AUTHORIZED_APPLICATION.includes(app)
  const applicationLogoutPath = makePath(getApplicationPath(app), PATH.LOGOUT)

  return (
    <>
      <PageHeader headerText="Authorization Error" />
      <section className="form-block-container">
        <div className="empty-state">
          <header>
            <h2>Unauthorized Request</h2>
          </header>
          <div className="card-inner">
            {isAuthorizedApplication ? (
              <>
                <p>
                  You are unauthorized. Please re-login and try your request
                  again.
                </p>
                <Button
                  className="button-primary"
                  onClick={() => history.replace(applicationLogoutPath)}
                >
                  Logout and Retry
                </Button>
              </>
            ) : (
              <p>
                You performed an unauthorized operation on a non-Sea Tow
                application. Please retry your request.
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

function NotFoundError({ app }) {
  const history = useHistory()
  const NotFoundMessage =
    app === APPLICATION.PROGRAMS_PORTAL
      ? ProgramsPortalNotFoundMessage
      : DefaultNotFoundMessage

  return (
    <>
      <PageHeader headerText="Unknown Page Error" />
      <section className="form-block-container">
        <div className="empty-state">
          <header>
            <h2>Page Not Found</h2>
          </header>
          <div className="card-inner">
            <NotFoundMessage />
            <Button
              className="button-primary"
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

function DefaultNotFoundMessage() {
  return (
    <p>
      Oops there appears to be a problem. The page you requested doesn’t exist.
      If you believe this is an error please{' '}
      <a href={makePath(PATH.SEATOW, PATH.CONTACT)}>contact us</a>.
    </p>
  )
}

function ProgramsPortalNotFoundMessage() {
  return (
    <p>
      Oops there appears to be a problem. The page you requested doesn’t exist.
      If you believe this is an error please email us at:{' '}
      <a href={`mailto:${SEATOW_PROGRAMS_CONTACT_EMAIL}`}>
        {SEATOW_PROGRAMS_CONTACT_EMAIL}
      </a>
    </p>
  )
}
