import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { UnauthenticatedLayout } from 'components'
import { ThankYou } from '../components'
import * as Views from './views'
import { PATH, makePath } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <UnauthenticatedLayout>
      <Switch>
        <Route exact path={makePath(path, PATH.ROOT)}>
          <Views.NewParticipantApplication />
        </Route>
        <Route path={makePath(path, PATH.CREATE_ACCOUNT)}>
          <Views.CreateAccount />
        </Route>
        <Route path={makePath(path, PATH.LOGIN)}>
          <Views.PostApplicationLogin />
        </Route>
        <Route path={makePath(path, PATH.THANK_YOU)}>
          <ThankYou
            backLinkText="Back to Website"
            backLinkTo={makePath(PATH.ROOT)}
            headerText="Application"
          />
        </Route>
      </Switch>
    </UnauthenticatedLayout>
  )
}

Routes.propTypes = propTypes

export default Routes
