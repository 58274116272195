import { isNil } from 'lodash'

function getLocalityFromGooglePlace(googlePlace) {
  if (isNil(googlePlace)) return {}

  const {
    address_components,
    formatted_address,
    geometry: {
      location: { lat, lng },
    },
    types,
  } = googlePlace
  const addressComponent = address_components.reduce(
    (locality, { long_name, types }) => {
      if (types.includes('street_number')) {
        locality.streetNumber = long_name
      } else if (types.includes('route')) locality.street = long_name
      else if (types.includes('locality') || types.includes('sublocality'))
        locality.city = long_name
      else if (types.includes('subpremise')) locality.subpremise = long_name
      else if (types.includes('administrative_area_level_1'))
        // Do not overwrite a previously set state value. Especially important for
        // Puerto Rico, which we will treat as a state, not as a country
        // (see below).
        locality.state = locality.state ?? long_name
      else if (types.includes('country')) {
        // Sea Tow treats Puerto Rico as a state, not a country. Adjust the
        // result accordingly...
        if (long_name === 'Puerto Rico') {
          locality.state = long_name
          locality.country = 'United States'
        } else {
          locality.country = long_name
        }
      } else if (types.includes('postal_code')) locality.postalCode = long_name

      return locality
    },
    {}
  )

  return { ...addressComponent, formatted_address, lat, lng, types }
}

export default getLocalityFromGooglePlace
