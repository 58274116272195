import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton } from 'lp-components'
import { propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

function ReviewOrderForm({ handleSubmit, submitting }) {
  return (
    <div className="group-block small-spacing">
      <form onSubmit={handleSubmit} noValidate>
        <p>
          Please review and confirm that all information above is correct. Your
          credit card will be charged upon submission.
        </p>
        <div className="button-group">
          <SubmitButton {...{ submitting }}>Confirm Purchase</SubmitButton>
        </div>
      </form>
    </div>
  )
}

ReviewOrderForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'ReviewOrderForm',
    constraints: {},
  })
)(ReviewOrderForm)
