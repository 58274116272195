import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { QuickLinks } from 'components'
import { Referrals } from 'referrals/components'
import { MEMBER_PORTAL_QUICKLINKS } from 'config'
import { AccountType } from 'types'

const propTypes = {
  account: AccountType.isRequired,
}

function SidePanel({ account }) {
  const { referral_credits__c } = account

  return (
    <aside>
      <Referrals credits={referral_credits__c} />
      <QuickLinks links={MEMBER_PORTAL_QUICKLINKS} />
    </aside>
  )
}

SidePanel.propTypes = exact(propTypes)

export default pure(SidePanel)
