function handleGoogleApiLoaded(map, maps, lat, lng, draggable) {
  new maps.Marker({
    position: {
      lat: lat,
      lng: lng,
    },
    map,
    draggable: draggable,
  })
}

export default handleGoogleApiLoaded
