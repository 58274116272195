import { AUTORENEW_BEFORE_EXPIRATION_DAYS } from 'config'
import calculateDaysUntilExpiration from './calculateDaysUntilExpiration'

const isAutoRenewDisabled = (expirationDate) => {
  return (
    calculateDaysUntilExpiration(expirationDate) <=
    AUTORENEW_BEFORE_EXPIRATION_DAYS
  )
}

export default isAutoRenewDisabled
