import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { PaymentStep } from 'member-portal/components'
import { AccountType, MembershipType } from 'types'
import { makeMembershipIdPath, PATH } from 'config'

const propTypes = {
  account: AccountType.isRequired,
  membership: MembershipType.isRequired,
}

function RenewalPayment({ account, membership }) {
  const { sfid: membershipId } = membership
  return (
    <div>
      <PaymentStep
        account={account}
        membership={membership}
        basePath={makeMembershipIdPath(
          membershipId,
          PATH.RENEW_MEMBERSHIP,
          PATH.PAYMENT
        )}
        nextStep={makeMembershipIdPath(
          membershipId,
          PATH.RENEW_MEMBERSHIP,
          PATH.REVIEW_ORDER
        )}
        showAutoRenew
        showDiscountForm
        showDonationForm
        showMembershipSummaryCost
        showTrailerCareSummaryCost
      />
    </div>
  )
}

RenewalPayment.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalPayment
)
