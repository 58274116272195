import { isNil } from 'lodash'
import formatFixedNumber from './formatFixedNumber'

export default function boatMake(boat) {
  if (isNil(boat)) return 'N/A'

  const { no_boat_reason__c } = boat

  return (
    no_boat_reason__c ??
    `${boat.year__c} · ${formatFixedNumber(boat.length__c)}ft · ${
      boat.boat_make__c
    }`
  )
}
