import { isEmpty } from 'lodash'
import { API_ERROR_MAP } from 'types'

export default function apiErrorToErrorMessage(error) {
  if (isEmpty(error)) return API_ERROR_MAP.UNKNOWN_ERROR

  let errorMessages = []

  if (!Array.isArray(error)) {
    errorMessages.push(API_ERROR_MAP[error] ?? API_ERROR_MAP.UNKNOWN_ERROR)
  } else {
    errorMessages = error.map((_error) => API_ERROR_MAP[_error]).filter(Boolean)

    if (errorMessages.length === 0) {
      errorMessages.push(API_ERROR_MAP.UNKNOWN_ERROR)
    }
  }

  return `${errorMessages.join('<br/><br/>')}<br/><br/>[${[error].flat().join(', ')}]`
}
