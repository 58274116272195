import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {}

function Or() {
  return (
    <p className="text-divider">
      <span>Or</span>
    </p>
  )
}

Or.propTypes = exact(propTypes)

export default pure(Or)
