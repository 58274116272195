import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Modal } from 'lp-components'
import {
  PATH,
  PROGRAMS_PORTAL_URL,
  makeMarketingSitePath,
  makeProgramsPortalPath,
} from 'config'

const propTypes = {}

function NotAProgramsParticipant() {
  const { logout } = useAuth0()
  const logoutAndRetryLogin = () =>
    logout({
      logoutParams: {
        returnTo: PROGRAMS_PORTAL_URL,
      },
    })

  return (
    <Modal className="modal-inner auth-card" onClose={logoutAndRetryLogin}>
      <header>
        <img src="/assets/images/logo-color.svg" alt="" />
        <h2>Not a Registered Programs Participant</h2>
      </header>
      <p>
        You have not been identified as a valid Sea Tow programs participant.
        You cannot use the Sea Tow Programs Portal without first applying as a
        participant. To apply, fill out the{' '}
        <a href={makeProgramsPortalPath(PATH.APPLY)}>
          programs participant application.
        </a>
      </p>
      <p>
        If you <b>have</b> applied as a programs participant you can retry
        logging in. The processing of your application may have been delayed,
        preventing you from logging in.
      </p>
      <div className="button-group stacked">
        <Button onClick={logoutAndRetryLogin}>Retry Login</Button>
        <Button
          className="button-primary-outline"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT),
              },
            })
          }
        >
          Return to Sea Tow
        </Button>
      </div>
    </Modal>
  )
}

NotAProgramsParticipant.propTypes = exact(propTypes)

export default pure(NotAProgramsParticipant)
