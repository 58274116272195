import React from 'react'
import { compose } from 'recompose'
import { Field } from 'redux-form'
import { lpForm } from 'lp-form'
import { Input, SubmitButton, Button } from 'lp-components'

function EditUsernameForm({ handleSubmit, toggleEditUsernameForm }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <p>
        <b>Note: </b>You will be required to re-login after updating your
        username.
      </p>
      <Field
        component={Input}
        label="New Username (Email)"
        name="new_username"
        required
        requiredIndicator="*"
      />
      <Field
        component={Input}
        label="Confirm New Username (Email)"
        name="new_username_confirmation"
        required
        requiredIndicator="*"
      />
      <div className="button-group">
        <Button
          onClick={toggleEditUsernameForm}
          className="button-primary-outline"
        >
          Cancel
        </Button>
        <SubmitButton>
          Update Username
        </SubmitButton>
      </div>
    </form>
  )
}

export default compose(
  lpForm({
    name: 'EditUsernameForm',
    constraints: {
      new_username: {
        email: true,
        presence: true,
      },
      new_username_confirmation: {
        email: true,
        presence: true,
        equality: {
          attribute: 'new_username',
        },
      },
    },
    submitFilters: { reject: ['new_username_confirmation'] },
  })
)(EditUsernameForm)
