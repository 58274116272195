import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { PaymentStep } from 'member-portal/components'
import { AccountType, MembershipType } from 'types'
import { makeMemberApplicationApplyPath, PATH } from 'config'

const propTypes = {
  account: AccountType.isRequired,
  membership: MembershipType.isRequired,
}

function NewMemberPayment({ account, membership }) {
  return (
    <div>
      <PaymentStep
        account={account}
        membership={membership}
        basePath={makeMemberApplicationApplyPath(PATH.PAYMENT)}
        nextStep={makeMemberApplicationApplyPath(PATH.REVIEW_ORDER)}
        showAutoRenew
        showDiscountForm
        showDonationForm
        showMembershipSummaryCost
        showReferral
        showTrailerCareSummaryCost
      />
    </div>
  )
}

NewMemberPayment.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberPayment
)
