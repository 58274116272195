import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import * as apiActions from 'api-actions'
import {
  BoatForm,
  OtherBoatForm,
  BoatTypeSelectorForm,
} from 'memberships/boats/forms'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { isNil, pick } from 'lodash'
import {
  API_KEY_MAP,
  BOAT_OWNERSHIP_TYPE,
  BOAT_STATUS,
  MEMBERSHIP_TYPE,
  MembershipType,
} from 'types'
import {
  makeMemberApplicationApplyPath,
  PATH,
  SEATOW_PHONE,
  HOME_PORT_RESTRICTED_STATES,
} from 'config'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  setObjectIdentifiers,
  findPrimaryBoat,
  useConfirm,
  handleSubmitFailWithFlashMessage,
} from 'utils'

const propTypes = {
  createBoat: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  updateBoat: PropTypes.func.isRequired,
  fetchMembershipApplicationAccount: PropTypes.func.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
  nextBoatStep: PropTypes.string.isRequired,
  nextApplicationStep: PropTypes.string.isRequired,
}

function MembershipBoatSelection({
  createBoat,
  membership,
  updateBoat,
  fetchMembershipApplicationAccount,
  setSelectedMembershipType,
  nextBoatStep,
  nextApplicationStep,
}) {
  // If user skips the contact page during the new member flow
  // (and therefore has no membership created yet), the
  // application should redirect to the Contact Page
  if (!membership) {
    return <Redirect to={makeMemberApplicationApplyPath(PATH.CONTACT)} />
  }

  const history = useHistory()
  const location = useLocation()
  const { isConfirmed: confirmGoldUpgrade } = useConfirm()
  const [otherBoatFormDisplayed, setOtherBoatFormDisplayed] = useState(false)

  const goldUpgradeModalAttributes = {
    heading: 'Lake Card Not Available in Your Area',
    prompt: `Our Lake Card membership provides service only on inland waters in the Continental United States, excluding the states of
    AK, FL, HI, as well as PR and VI. In order to continue please upgrade to our Gold Card membership.
    Have questions? Let us help. Call us at ${SEATOW_PHONE}.`,
    confirmLabel: 'Upgrade and Continue',
    preventClose: true
  }

  const {
    account__r__heroku_external_id__c,
    membership_contact__r__heroku_external_id__c: contactId,
    heroku_external_id__c: membershipId,
    boats,
    shopping_cart_membership_type__c: membershipType,
  } = membership

  const boat = findPrimaryBoat(boats)
  const isNewBoat = !boat
  const { noBoatReason } = location?.state?.formData ?? false
  const BoatFormComponent = otherBoatFormDisplayed ? OtherBoatForm : BoatForm

  useEffect(() => {
    if (noBoatReason) {
      setOtherBoatFormDisplayed(true)
      return
    }

    const otherBoatFormDisplayed = !isNil(boat?.no_boat_reason__c) ?? false
    if (otherBoatFormDisplayed) {
      setOtherBoatFormDisplayed(true)
      return
    }
  }, [noBoatReason])

  const checkForUnsupportedMembership = useCallback(
    (homePortState) =>
      new Promise((resolve, reject) => {
        const triggerGoldUpgradeModal = async () => {
          const confirmed = await confirmGoldUpgrade(goldUpgradeModalAttributes)
          if (confirmed) {
            setSelectedMembershipType(MEMBERSHIP_TYPE.GOLD_CARD)
            resolve()
          } else {
            reject()
          }
        }

        if (
          membershipType === MEMBERSHIP_TYPE.LAKE_CARD &&
          HOME_PORT_RESTRICTED_STATES.includes(homePortState)
        ) {
          triggerGoldUpgradeModal()
        } else {
          resolve()
        }
      }),
    []
  )

  return (
    <>
      <div className="form-block">
        <BoatTypeSelectorForm
          enableReinitialize
          initialValues={{
            boatOwnershipType: otherBoatFormDisplayed
              ? BOAT_OWNERSHIP_TYPE.OTHER_BOAT
              : BOAT_OWNERSHIP_TYPE.MY_BOAT,
          }}
          onChange={() => {
            setOtherBoatFormDisplayed(!otherBoatFormDisplayed)
          }}
          touchOnChange
        />

        <BoatFormComponent
          boat={boat}
          enableReinitialize
          initialValues={setInitialNewMemberValues(
            location,
            boat,
            otherBoatFormDisplayed
              ? API_KEY_MAP.OTHER_BOAT_KEYMAP
              : API_KEY_MAP.BOAT_KEYMAP
          )}
          nextStep={nextBoatStep}
          onSubmit={async (formValues) => {
            await checkForUnsupportedMembership(formValues.state)

            const mappedApiValues = apiValuesWithMappedKeys(
              formValues,
              otherBoatFormDisplayed
                ? API_KEY_MAP.OTHER_BOAT_KEYMAP
                : API_KEY_MAP.BOAT_KEYMAP
            )

            // Ensure that data belonging to a "boat" is appropriate for a
            // boat and data associated with an "other boat" is appropriate for
            // an "other" boat. When switching between a boat and an other boat
            // form data inappropriate for the new type may be left in the
            // boat record recorded by the API and must be removed.
            if (otherBoatFormDisplayed) {
              mappedApiValues.boat_make__c = null
              mappedApiValues.length__c = null
              mappedApiValues.year__c = null
            } else {
              mappedApiValues.no_boat_reason__c = null
            }

            return isNewBoat
              ? createBoat({
                ...mappedApiValues,
                account__r__heroku_external_id__c,
                contact__r__heroku_external_id__c: contactId,
                related_membership__r__heroku_external_id__c: membershipId,
                boat_status__c: BOAT_STATUS.ACTIVE,
                primary_boat__c: true,
              })
              : updateBoat(setObjectIdentifiers(mappedApiValues, boat))
          }}
          onSubmitSuccess={async () => {
            // Fetch the latest membership data with boat updates
            await fetchMembershipApplicationAccount(
              account__r__heroku_external_id__c,
              membershipId
            )
            history.push(nextApplicationStep)
          }}
          onSubmitFail={handleSubmitFailWithFlashMessage}
        />
      </div>
    </>
  )
}

MembershipBoatSelection.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
    account: memberSelectors.account(state),
  }
}

const mapDispatchToProps = {
  createBoat: apiActions.createBoat,
  updateBoat: apiActions.updateBoat,
  fetchMembershipApplicationAccount:
    apiActions.fetchMembershipApplicationAccount,
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MembershipBoatSelection
)

function setInitialNewMemberValues(location, boat, apiKeyMap) {
  // If it exists, return the form data in the location state
  // (set after going through the new boat wizard). Otherwise return the
  // form data associated with the existing boat.
  const locationFormData = location?.state?.formData

  if (locationFormData) return pick(locationFormData, Object.values(apiKeyMap))

  return formValuesWithMappedKeys(boat, apiKeyMap)
}
