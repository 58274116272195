import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import {
  MEMBERSHIP_STATUS,
  ProductPrices,
  TRAILER_CARE_DETAIL,
  TRAILER_CARE_TYPE,
} from 'types'
import { RenewButton, UpgradeButton } from 'member-portal/components'
import {
  PATH,
  SEATOW_PHONE,
  SEATOW_TRAILER_CARE_CONTACT_EMAIL,
  makeMembershipIdPath,
  makePath,
} from 'config'
import { formatCurrency } from 'utils'
import activeIcon from 'utility-icons/completed.svg'
import warnIcon from 'utility-icons/warn.svg'

const propTypes = {
  enrolledInTrailerCare: PropTypes.bool.isRequired,
  isMonthlyAccountType: PropTypes.bool,
  isSubsidizedAccountType: PropTypes.bool,
  membershipSfid: PropTypes.string.isRequired,
  membershipStatus: PropTypes.string.isRequired,
  multiYearEnrollment: PropTypes.bool.isRequired,
  memberNumber: PropTypes.string.isRequired,
  trailerCareAvailable: PropTypes.bool.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
  trailerCareType: PropTypes.string,
}

function TrailerCare({
  enrolledInTrailerCare,
  isMonthlyAccountType,
  isSubsidizedAccountType,
  membershipSfid,
  membershipStatus,
  multiYearEnrollment,
  memberNumber,
  trailerCareAvailable,
  trailerCarePrices,
  trailerCareType,
}) {
  const membershipIdPath = makeMembershipIdPath(membershipSfid)
  const renewPath = makePath(
    membershipIdPath,
    PATH.RENEW_MEMBERSHIP,
    PATH.SELECTION,
  )
  const upgradePath = makePath(
    membershipIdPath,
    PATH.UPGRADES,
    PATH.TRAILER_CARE,
    PATH.SELECTION,
  )
  let TrailerCareComponent

  if (trailerCareAvailable) {
    if (enrolledInTrailerCare) {
      if (trailerCareType === TRAILER_CARE_TYPE.BASIC) {
        TrailerCareComponent = (
          <TrailerCareBasic
            membershipStatus={membershipStatus}
            multiYearEnrollment={multiYearEnrollment}
            memberNumber={memberNumber}
            showUpgradeOption={
              !isMonthlyAccountType && !isSubsidizedAccountType
            }
            renewPath={renewPath}
            upgradePath={upgradePath}
          />
        )
      } else {
        TrailerCareComponent = (
          <TrailerCarePlus
            membershipStatus={membershipStatus}
            memberNumber={memberNumber}
            renewPath={renewPath}
          />
        )
      }
    } else {
      TrailerCareComponent = (
        <TrailerCareOffer
          membershipStatus={membershipStatus}
          multiYearEnrollment={multiYearEnrollment}
          memberNumber={memberNumber}
          renewPath={renewPath}
          trailerCarePrices={trailerCarePrices}
          upgradePath={upgradePath}
        />
      )
    }
  } else {
    TrailerCareComponent = <TrailerCareNotAvailable />
  }

  return (
    <div className="detail-block">
      <h4>Trailer Care Roadside Assistance</h4>
      {TrailerCareComponent}
    </div>
  )
}

TrailerCare.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isMonthlyAccountType: globalSelectors.isMonthlyAccountType(state),
    isSubsidizedAccountType: globalSelectors.isSubsidizedAccountType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrailerCare,
)

function TrailerCareNotAvailable() {
  return <p>Not available with this membership type</p>
}

function TrailerCareBasic({
  membershipStatus,
  multiYearEnrollment,
  memberNumber,
  showUpgradeOption,
  renewPath,
  upgradePath,
}) {
  const trailerCareDetail = TRAILER_CARE_DETAIL[TRAILER_CARE_TYPE.BASIC]
  const { coverageLimit, marketingTitle } = trailerCareDetail
  const upgradedTrailerCareDetail = TRAILER_CARE_DETAIL[TRAILER_CARE_TYPE.PLUS]
  const { marketingTitle: upgradeMarketingTitle } = upgradedTrailerCareDetail

  return (
    <>
      {activeMembershipForTrailerCare(membershipStatus) ? (
        <>
          <p>
            <img src={activeIcon} alt="" className="status-icon" />
            {marketingTitle}: Up to {formatCurrency(coverageLimit, 0)} per
            incident
          </p>
          <p>
            Request reimbursement via{' '}
            <a href={`mailto:${SEATOW_TRAILER_CARE_CONTACT_EMAIL}`}>
              {SEATOW_TRAILER_CARE_CONTACT_EMAIL}
            </a>
          </p>
          {multiYearEnrollment ? (
            <p>
              Call <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> to add to
              your membership today!
            </p>
          ) : (
            // Single year enrollment...
            showUpgradeOption && (
              <UpgradeButton
                to={upgradePath}
                className="link-primary"
                aria-label={`Upgrade to ${upgradeMarketingTitle} for membership ${memberNumber}`}
              >
                Upgrade to {upgradeMarketingTitle}
              </UpgradeButton>
            )
          )}
        </>
      ) : (
        // Membership expired...
        <>
          <p>{marketingTitle}: Expired</p>
          <RenewButton
            to={renewPath}
            className="link-primary"
            aria-label={`Renew Sea Tow & trailer care for membership ${memberNumber}`}
          >
            Renew Sea Tow & Trailer Care
          </RenewButton>
        </>
      )}
    </>
  )
}

function TrailerCarePlus({ membershipStatus, memberNumber, renewPath }) {
  const trailerCareDetail = TRAILER_CARE_DETAIL[TRAILER_CARE_TYPE.PLUS]
  const { coverageLimit, marketingTitle } = trailerCareDetail

  return (
    <>
      {activeMembershipForTrailerCare(membershipStatus) ? (
        <>
          <p>
            <img src={activeIcon} alt="" className="status-icon" />
            {marketingTitle}: Up to {formatCurrency(coverageLimit, 0)} per
            incident
          </p>
          <p>
            Request reimbursement via{' '}
            <a href={`mailto:${SEATOW_TRAILER_CARE_CONTACT_EMAIL}`}>
              {SEATOW_TRAILER_CARE_CONTACT_EMAIL}
            </a>
          </p>
        </>
      ) : (
        // Membership expired...
        <>
          <p>
            <img src={warnIcon} alt="" className="status-icon" />
            {marketingTitle}: Expired
          </p>
          <RenewButton
            to={renewPath}
            className="link-primary"
            aria-label={`Renew Sea Tow & trailer care for membership ${memberNumber}`}
          >
            Renew Sea Tow & Trailer Care
          </RenewButton>
        </>
      )}
    </>
  )
}

function TrailerCareOffer({
  membershipStatus,
  multiYearEnrollment,
  memberNumber,
  renewPath,
  trailerCarePrices,
  upgradePath,
}) {
  const yearlyCost = trailerCarePrices[TRAILER_CARE_TYPE.BASIC]

  return (
    <>
      <p>
        Service for your marine trailers and towing vehicle when trailering to
        and from the water.
      </p>
      {activeMembershipForTrailerCare(membershipStatus) ? (
        multiYearEnrollment ? (
          <p>
            Call <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> to add to
            your membership today!
          </p>
        ) : (
          // Single year enrollment...
          <UpgradeButton
            to={upgradePath}
            className="link-primary"
            aria-label={`Add Trailer Care for as low as ${formatCurrency(
              yearlyCost,
              2,
            )} a year to membership ${memberNumber}`}
          >
            Add as low as {formatCurrency(yearlyCost, 2)} a year
          </UpgradeButton>
        )
      ) : (
        // Membership expired...
        <RenewButton
          to={renewPath}
          className="link-primary"
          aria-label={`Renew Sea Tow & trailer care for membership ${memberNumber}`}
        >
          Renew Sea Tow & Trailer Care
        </RenewButton>
      )}
    </>
  )
}

const activeMembershipForTrailerCare = (membershipStatus) =>
  membershipStatus === MEMBERSHIP_STATUS.ACTIVE ||
  membershipStatus === MEMBERSHIP_STATUS.FUTURE_START ||
  membershipStatus === MEMBERSHIP_STATUS.PENDING_ACTIVATION
