import { differenceInCalendarDays } from 'date-fns'
import { parseISODate } from 'utils'

const calculateDaysUntilExpiration = (expirationDate = '') => {
  if (!expirationDate) return null

  const today = new Date()
  const membershipExpirationDate = parseISODate(expirationDate)

  return differenceInCalendarDays(membershipExpirationDate, today)
}

export default calculateDaysUntilExpiration
