import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { PageHeader } from 'components'
import {
  ActivePrograms,
  AdditionalPrograms,
  ProgramsNotificationCenter,
  SidePanel,
} from '../components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from 'programs-portal/reducer'
import { AccountType, AllSavingsClubTypes } from 'types'

const propTypes = {
  account: AccountType.isRequired,
  allPrograms: AllSavingsClubTypes.isRequired,
}

function Dashboard({ account, allPrograms }) {
  return (
    <>
      <PageHeader headerText="Dashboard" />
      <div className="dashboard-block-container">
        <ProgramsNotificationCenter account={account} />
        <div className="content-with-aside-wrapper">
          <div className="content-with-aside">
            <ActivePrograms programs={allPrograms} />
            <AdditionalPrograms programs={allPrograms} />
          </div>
          <SidePanel account={account} />
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    allPrograms: selectors.allPrograms(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard)
