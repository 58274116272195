import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { PartnerAccountSummary, QuickLinks } from 'components'
import { PROGRAMS_QUICKLINKS } from 'config'
import { AccountType } from 'types'

const propTypes = {
  account: AccountType.isRequired,
}

function SidePanel({ account }) {
  return (
    <aside>
      <header>
        <h2>Account Information</h2>
      </header>
      <PartnerAccountSummary
        account={account}
        withAccountEdit
        withApprovalStatus
      />
      <QuickLinks links={PROGRAMS_QUICKLINKS} />
    </aside>
  )
}

SidePanel.propTypes = exact(propTypes)

export default pure(SidePanel)
