import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { IMAGE_PATH } from 'config'
import ImageLink from './ImageLink'

const propTypes = {
  logoLink: PropTypes.string.isRequired,
}

function Logo({ logoLink }) {
  return (
    <div className="logo">
      <ImageLink
        alt="Sea Tow"
        path={logoLink}
        image_uri={IMAGE_PATH.SEATOW_LOGO}
      />
    </div>
  )
}

Logo.propTypes = exact(propTypes)

export default pure(Logo)
