import { first } from 'lodash'
import { PATH, QUERY_KEY } from 'config'
import { makeQueryPath } from 'utils'

const handleClientError = (status) => {
  const pathname = window.location.pathname

  window.location.assign(
    makeQueryPath(PATH.CLIENT_ERROR, {
      [QUERY_KEY.APPLICATION]: getApplicationFromPath(pathname),
      [QUERY_KEY.STATUS]: status,
    })
  )
}

export default handleClientError

const getApplicationFromPath = (pathname) => {
  // The application will typically be the first component of the pathname...
  const applicationName = first(pathname.slice(1).split('/'))

  return applicationName
}
