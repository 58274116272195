import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink, ExternalLink } from 'components'
import { PartnerLogo } from 'programs-portal/components'
import { APPROVAL_STATUS, AccountType } from 'types'
import { PATH, makeProgramsPortalPath } from 'config'
import { formatPhoneNumber } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  withAccountEdit: PropTypes.bool,
  withApprovalStatus: PropTypes.bool,
}

function PartnerAccountSummary({
  account,
  withAccountEdit = false,
  withApprovalStatus = false,
}) {
  const {
    approval_status__c,
    company_logo__c,
    name,
    phone,
    partner_facebook__c,
    partner_instagram__c,
    partner_linkedin__c,
    public_facing_url__c,
    public_facing_email_address__c,
    shippingcity,
    shippingcountry,
    shippingpostalcode,
    shippingstate,
    shippingstreet,
  } = account

  return (
    <>
      {withApprovalStatus && approval_status__c !== APPROVAL_STATUS.APPROVED && (
        <ul className="notifications">
          <li className="danger">
            <p>Account Status: {approval_status__c}</p>
          </li>
        </ul>
      )}
      <div className="card image-card">
        <div className="circle-image-block">
          <PartnerLogo companyLogo={company_logo__c} companyName={name} />
        </div>
        <h2>{name}</h2>
        {public_facing_url__c && (
          <p>
            <ExternalLink href={public_facing_url__c} aria-label={name}>
              {public_facing_url__c}
            </ExternalLink>
          </p>
        )}
        <p>{formatPhoneNumber(phone)}</p>
        <p>{public_facing_email_address__c}</p>
        <p>
          {shippingstreet}, {shippingcity}, {shippingstate} {shippingpostalcode}
          , {shippingcountry}
        </p>
        <ul className="social-links">
          {partner_instagram__c && (
            <li>
              <ExternalLink href={partner_instagram__c} aria-label="Instagram">
                <img src="/assets/images/social-icons/instagram.svg" alt="" />
              </ExternalLink>
            </li>
          )}
          {partner_facebook__c && (
            <li>
              <ExternalLink href={partner_facebook__c} aria-label="Facebook">
                <img src="/assets/images/social-icons/facebook.svg" alt="" />
              </ExternalLink>
            </li>
          )}
          {partner_linkedin__c && (
            <li>
              <ExternalLink href={partner_linkedin__c} aria-label="LinkedIn">
                <img src="/assets/images/social-icons/linkedin.svg" alt="" />
              </ExternalLink>
            </li>
          )}
        </ul>
        {withAccountEdit && (
          <ButtonLink
            className="button-primary-outline"
            to={makeProgramsPortalPath(PATH.ACCOUNT)}
          >
            View All Account Information
          </ButtonLink>
        )}
      </div>
    </>
  )
}

PartnerAccountSummary.propTypes = exact(propTypes)

export default pure(PartnerAccountSummary)
