import { createAction } from 'redux-actions'

// Membership Selections ---------------------------
export const setSelectedMembershipType = createAction(
  'SET_SELECTED_MEMBERSHIP_TYPE'
)

// Delivery Method ---------------------------------
export const clearForNewDeliveryMethod = createAction(
  'CLEAR_FOR_NEW_DELIVERY_METHOD'
)
export const setDeliveryMethod = createAction('SET_DELIVERY_METHOD')
export const setGiftWrapped = createAction('SET_GIFT_WRAPPED')
export const setShippingMethod = createAction('SET_SHIPPING_METHOD')

// Gift Recipient ----------------------------------
export const setGiftMessage = createAction('SET_GIFT_MESSAGE')
export const setMailedToGifter = createAction('SET_MAILED_TO_GIFTER')
export const setRecipientContact = createAction('SET_RECIPIENT_CONTACT')

// Payment -----------------------------------------
export const setBillingContact = createAction('SET_BILLING_CONTACT')
export const setCreditCard = createAction('SET_CREDIT_CARD')

// Confirmation ------------------------------------
export const clearAllGiftState = createAction('CLEAR_ALL_GIFT_STATE')
