import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { UpgradesConfirmationStep } from 'memberships/upgrades/components'
import { AccountType } from 'types'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
}

function GoldUpgradeConfirmation({ account, fetchAllMembershipsForAccount }) {
  const { sfid: accountId } = account

  return (
    <>
      <UpgradesConfirmationStep
        refreshMemberships={() => fetchAllMembershipsForAccount(accountId)}
        confirmationHeader="Thank you for upgrading your membership."
        confirmationMessage={`It will go into effect in 24 hours. You now have access to Sea Tow Gold Card membership privileges on all fresh and salt water nationwide.`}
      />
    </>
  )
}

GoldUpgradeConfirmation.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(GoldUpgradeConfirmation)
