import { PRIMARY_CONTACT } from 'types'

function sortContacts(contacts, primaryContact) {
  const nonPrimaryContacts = contacts.filter(
    (contact) => contact.contact_type__c !== PRIMARY_CONTACT
  )

  return [primaryContact, ...nonPrimaryContacts]
}

export default sortContacts
