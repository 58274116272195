import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Button, Input, Select, SubmitButton } from 'lp-components'
import { formValues, Field, propTypes as formPropTypes } from 'redux-form'
import { ADDRESS_FORM_VALIDATOR, COUNTRY } from 'config'
import { stateListFromCountry } from 'utils'

const propTypes = {
  change: PropTypes.func.isRequired,
  closeEditForm: PropTypes.func.isRequired,
  country: PropTypes.string,
  ...formPropTypes,
}

function BillingAddressEditForm({
  change,
  closeEditForm,
  country,
  handleSubmit,
  pristine,
  submitting,
}) {
  return (
    <div className="card-inner">
      <form onSubmit={handleSubmit} noValidate>
        <Field
          component={Input}
          label="Street Address"
          name="address"
          required
          requiredIndicator="*"
          type="text"
        />
        <div className="row">
          <div className="col-6">
            <Field
              component={Select}
              label="Country"
              name="country"
              onChange={() => change('state', '')}
              options={Object.values(COUNTRY)}
              placeholder="Country"
              required
              requiredIndicator="*"
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="City"
              name="city"
              required
              requiredIndicator="*"
              type="text"
            />
          </div>
          <div className="col-3">
            <Field
              component={Select}
              label="State"
              name="state"
              options={stateListFromCountry(country)}
              placeholder="State"
              required
              requiredIndicator="*"
              type="text"
            />
          </div>
          <div className="col-3">
            <Field
              component={Input}
              label="Postal Code"
              name="postalCode"
              required
              requiredIndicator="*"
              type="text"
            />
          </div>
        </div>
        <div className="button-group">
          <Button onClick={closeEditForm} className="button-primary-outline">
            Cancel
          </Button>
          <SubmitButton {...{ pristine, submitting }}>
            Update Billing Address
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

BillingAddressEditForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'BillingAddressEditForm',
    constraints: {
      address: ADDRESS_FORM_VALIDATOR,
      city: { presence: { allowEmpty: false } },
      state: { presence: { allowEmpty: false } },
      postalCode: { presence: { allowEmpty: false } },
      country: { presence: { allowEmpty: false } },
    },
  }),
  formValues('country')
)(BillingAddressEditForm)
