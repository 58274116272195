/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Spinner } from 'lp-components'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function AuthenticatedRoutes({ children }) {
  return children
}

AuthenticatedRoutes.propTypes = exact(propTypes)

export default withAuthenticationRequired(AuthenticatedRoutes, {
  onRedirecting: () => <Spinner />,
})
