import React from 'react'
import PropTypes from 'prop-types'
import { DigitalRecipientType } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { DigitalRecipientForm } from '../forms'
import { PATH, makeGiftStepsPath } from 'config'

const propTypes = {
  giftMessage: PropTypes.string,
  recipientContact: DigitalRecipientType,
  setGiftMessage: PropTypes.func.isRequired,
  setRecipientContact: PropTypes.func.isRequired,
}

function DigitalRecipient({
  giftMessage,
  recipientContact,
  setGiftMessage,
  setRecipientContact,
}) {
  const history = useHistory()

  return (
    <DigitalRecipientForm
      enableReinitialize
      initialValues={{
        giftMessage,
        recipientContact,
      }}
      onSubmit={({ giftMessage, recipientContact }) => {
        setGiftMessage(giftMessage)
        setRecipientContact(recipientContact)
      }}
      onSubmitSuccess={() => history.push(makeGiftStepsPath(PATH.PAYMENT))}
    />
  )
}

DigitalRecipient.propTypes = exact(propTypes)

export default pure(DigitalRecipient)
