import React from 'react'
import PropTypes from 'prop-types'
import { ContactType } from 'types'
import { compose } from 'recompose'
import { formValues, Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ExternalLink } from 'components'
import { Input, SubmitButton, Button, Select } from 'lp-components'
import { ADDRESS_FORM_VALIDATOR, COUNTRY, makePath, PATH } from 'config'
import { stateListFromCountry } from 'utils'

const propTypes = {
  ...formPropTypes,
  country: PropTypes.string,
  isBusinessAccount: PropTypes.bool.isRequired,
  toggleAccountForm: PropTypes.func.isRequired,
  primaryContact: ContactType.isRequired,
}

function AccountHolderForm({
  change,
  country,
  handleSubmit,
  initialValues,
  isBusinessAccount,
  toggleAccountForm,
  primaryContact,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block">
        <h3 className="group-title">Account Holder</h3>
        <p>
          If you need to update the name or business on the account please{' '}
          <ExternalLink href={makePath(PATH.SEATOW, PATH.CONTACT)}>
            contact us.
          </ExternalLink>
        </p>
        <div className="read-only">
          {isBusinessAccount ? (
            <>
              <h3>Business Name</h3>
              <p>{initialValues.businessName}</p>
            </>
          ) : (
            <div className="row">
              <div className="col-6">
                <h3>First Name</h3>
                <p>{primaryContact.firstname}</p>
              </div>
              <div className="col-6">
                <h3>Last Name</h3>
                <p>{primaryContact.lastname}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="group-block medium-spacing">
        <h3 className="group-title">Mailing Address</h3>
        <p>
          This is the address we’ll send any important account related
          communication and documents.
        </p>
        <Field
          component={Input}
          label="Street Address"
          name="streetAddress"
          required
          requiredIndicator="*"
        />
        <div className="row">
          <div className="col-6">
            <Field
              component={Select}
              label="Country"
              name="country"
              onChange={() => change('state', '')}
              options={Object.values(COUNTRY)}
              placeholder="Country"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="City"
              name="city"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-3">
            <Field
              component={Select}
              label="State"
              name="state"
              options={stateListFromCountry(country)}
              placeholder="State"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-3">
            <Field
              component={Input}
              label="Postal Code"
              name="postalCode"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
      </div>
      <div className="button-group">
        <Button onClick={toggleAccountForm} className="button-primary-outline">
          Cancel
        </Button>
        <SubmitButton>Update Address</SubmitButton>
      </div>
    </form>
  )
}

AccountHolderForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'AccountHolderForm',
    constraints: {
      streetAddress: ADDRESS_FORM_VALIDATOR,
      city: { presence: { allowEmpty: false } },
      state: { presence: { allowEmpty: false } },
      postalCode: { presence: { allowEmpty: false } },
      country: { presence: { allowEmpty: false } },
    },
  }),
  formValues('country')
)(AccountHolderForm)
