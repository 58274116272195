import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import TrailerCareUpgradeLayout from './TrailerCareUpgradeLayout'
import { ExistingMemberApplicationAccountProvider } from 'memberships/components'
import { makePath, makeMembershipsPath, PATH } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <ExistingMemberApplicationAccountProvider>
      <Layout>
        <Switch>
          <Route exact path={makePath(path, PATH.SELECTION)}>
            <Views.TrailerCareUpgradeOptions />
          </Route>
          <Route exact path={makePath(path, PATH.CONFIRM_AND_LOGIN)}>
            <Views.TrailerCareUpgradeConfirmation />
          </Route>
          <Route>
            <ApplicationStepRoutes />
          </Route>
        </Switch>
      </Layout>
    </ExistingMemberApplicationAccountProvider>
  )
}

Routes.propTypes = propTypes

export default Routes

function ApplicationStepRoutes() {
  return (
    <TrailerCareUpgradeLayout>
      <Switch>
        <Route
          exact
          path={makeMembershipsPath(
            PATH.SFID,
            PATH.UPGRADES,
            PATH.TRAILER_CARE,
            PATH.PAYMENT
          )}
        >
          <Views.TrailerCareUpgradePayment isUpgrade={true} />
        </Route>
        <Route
          exact
          path={makeMembershipsPath(
            PATH.SFID,
            PATH.UPGRADES,
            PATH.TRAILER_CARE,
            PATH.REVIEW_ORDER
          )}
        >
          <Views.TrailerCareUpgradeReview />
        </Route>
      </Switch>
    </TrailerCareUpgradeLayout>
  )
}
