import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { PageHeader } from 'components'
import { ProgramApplication } from 'programs-portal/components'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { IMAGE_PATH, PATH, makeProgramsPortalPath } from 'config'
import { AccountType, ContactType } from 'types'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  applyForSeaCare: PropTypes.func.isRequired,
  primaryContact: ContactType.isRequired,
}

function SeaCareApplication({ account, applyForSeaCare, primaryContact }) {
  return (
    <>
      <PageHeader
        backLinkText="Back to Dashboard"
        backLinkTo={makeProgramsPortalPath(PATH.PROGRAMS_DASHBOARD)}
        headerText="Sea Care"
      />
      <section className="form-block-container">
        <ProgramApplication
          account={account}
          apply={applyForSeaCare}
          contact={primaryContact}
          programImage={IMAGE_PATH.SEA_CARE}
          successPath={PATH.SEA_CARE}
        />
      </section>
    </>
  )
}

SeaCareApplication.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    primaryContact: globalSelectors.primaryContact(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  applyForSeaCare: apiActions.applyForSeaCare,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SeaCareApplication)
