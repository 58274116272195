import calculateDiscountedCost from './calculateDiscountedCost'
import { COST, SHIPPING_COST } from 'config'
import { GIFT_DELIVERY_METHOD } from 'types'

export default function calculateTotalGiftCost({
  deliveryMethod,
  discountCodeDetails,
  giftWrapped,
  membershipPrices,
  selectedMembershipType,
  shippingMethod,
}) {
  const originalMembershipCost = membershipPrices[selectedMembershipType]
  const discountedMembershipCost = calculateDiscountedCost(
    originalMembershipCost,
    discountCodeDetails
  )
  const shippingCost =
    deliveryMethod === GIFT_DELIVERY_METHOD.MAILED
      ? SHIPPING_COST[shippingMethod]
      : 0
  const giftWrappingCost = giftWrapped ? COST.GIFT_WRAPPING : 0

  return {
    originalMembershipCost,
    discountedMembershipCost,
    shippingCost,
    totalCost: discountedMembershipCost + shippingCost + giftWrappingCost,
  }
}
