import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashErrorMessage } from 'redux-flash'
import { useHistory, useLocation } from 'react-router-dom'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { SectionHeader } from 'components'
import { MembershipPageHeader } from 'memberships/components'
import { OtherBoatForm } from '../forms'
import {
  API_KEY_MAP,
  BOAT_STATUS,
  AccountType,
  BoatType,
  MembershipType,
} from 'types'
import { PATH, makeMembershipIdPath } from 'config'
import { apiValuesWithMappedKeys, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType,
  createBoat: PropTypes.func,
  fetchAllMembershipsForAccount: PropTypes.func,
  flashErrorMessage: PropTypes.func.isRequired,
  membership: MembershipType,
  primaryBoat: BoatType,
}

const OTHER_BOAT_KEYMAP = API_KEY_MAP['OTHER_BOAT_KEYMAP']

function OtherBoat({
  account,
  createBoat,
  fetchAllMembershipsForAccount,
  flashErrorMessage,
  membership,
  primaryBoat,
}) {
  const history = useHistory()
  const location = useLocation()
  const { sfid: accountId } = account
  const { account__c, membership_contact__c, sfid: membershipId } = membership

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Boat & Home Port Information"
        backLinkTo={makeMembershipIdPath(membership.sfid, PATH.BOATS)}
        membership={membership}
      />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader
                sectionHeaderName="Add Other (Boat Club Member, etc)"
                sectionDescription="By adding boats, you’ll be able to leverage your membership
                benefits."
              />
              <OtherBoatForm
                initialValues={setInitialValues(location)}
                existingMemberBoat
                name={'OtherBoatForm'}
                nextStep={makeMembershipIdPath(
                  membershipId,
                  PATH.BOATS,
                  PATH.HOME_PORT_TYPE
                )}
                onSubmit={(formValues) => {
                  const mappedApiValues = apiValuesWithMappedKeys(
                    formValues,
                    OTHER_BOAT_KEYMAP
                  )

                  return createBoat({
                    ...mappedApiValues,
                    account__c: accountId,
                    boat_status__c: BOAT_STATUS.ACTIVE,
                    contact__c: membership_contact__c,
                    related_membership__c: membershipId,
                  })
                }}
                onSubmitSuccess={async () => {
                  await fetchAllMembershipsForAccount(account__c)
                  history.push(makeMembershipIdPath(membershipId, PATH.BOATS))
                }}
                onSubmitFail={({ _error }) => {
                  _error && flashErrorMessage(_error)
                }}
                primaryBoat={primaryBoat}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

OtherBoat.propTypes = propTypes

function setInitialValues(location) {
  if (!location.state) return null
  return location.state.formData
}

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    membership: memberSelectors.membership(state),
    primaryBoat: selectors.primaryBoat(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashErrorMessage,
}

const mapApiAuthToProps = {
  createBoat: apiActions.createBoat,
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(OtherBoat)
