import { pick } from 'lodash'
import {
  EMAIL_COMMUNICATION_PREFERENCES,
  EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES,
} from 'types'

export default function formatCommunicationPreferences(formValues) {
  // Pick out the preferences-related keys from the form values...
  const formCommunicationPreferences = pick(
    formValues,
    EMAIL_COMMUNICATION_PREFERENCES
  )
  // Map the truthy preference values to their API key values...
  const mappedCommunicationPreferences = Object.entries(
    formCommunicationPreferences
  ).reduce((preferences, [preferenceKey, preferenceValue]) => {
    // Only consider truthy preference values...
    if (preferenceValue) {
      preferences.push(EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES[preferenceKey])
    }

    return preferences
  }, [])

  return mappedCommunicationPreferences.join(';')
}
