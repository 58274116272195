const mapReferralInfo = (referralInfo) => {
  if (!referralInfo) return referralInfo

  const {
    name: referralId,
    referring_member_name__c: fetchedReferrerMemberName,
    referring_member_number__c: fetchedReferrerMemberNumber,
  } = referralInfo

  return {
    referralId,
    fetchedReferrerMemberName,
    fetchedReferrerMemberNumber,
  }
}

export default mapReferralInfo
