import { PATH, makeMemberPortalPath } from 'config'
// Note: when a new member signs-up, their account is verified by the API call
// verifyMembership. That API will, as a side-effect, update
// the "app_metadata" field of the user's Auth0 account with their Salesforce
// account id. That field is used to determine whether or not the user
// is a valid Sea Tow member. The current "user" information returned from
// useAuth0() does NOT have this updated information and is thus stale. To
// refresh this information requires the application to be reinitialized,
// which occurs when one forcefully changes the URL, as below, rather than
// using React Router history or <Redirect>.

export default function forceMembePortalReload(
  nextUrl = makeMemberPortalPath(PATH.MEMBERSHIPS)
) {
  window.location.assign(nextUrl)
}
