import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { ReviewStep } from 'member-portal/components'
import * as apiActions from 'api-actions'
import {
  AccountType,
  BillingType,
  HEROKU_ID_KEY,
  MembershipType,
  TRAILER_CARE_BASIC_TO_PLUS_UPGRADE,
  TRAILER_CARE_TYPE,
} from 'types'
import { makeMembershipIdPath, PATH } from 'config'
import { parseCreditCardAndBillingAddress, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  addTrailerCare: PropTypes.func.isRequired,
  billing: BillingType,
  chargeMembershipUpgrade: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
}

function TrailerCareUpgradeReview({
  account,
  addTrailerCare,
  billing,
  chargeMembershipUpgrade,
  membership,
  selectedTrailerCareType,
}) {
  const { sfid: membershipId } = membership
  const confirmAndFinalizePurchase =
    selectedTrailerCareType === TRAILER_CARE_BASIC_TO_PLUS_UPGRADE
      ? () =>
          chargeMembershipUpgrade(
            trailerCareUpgradeApiValues({
              billing,
              membership,
            })
          )
      : () =>
          addTrailerCare(
            addTrailerCareApiValues({
              billing,
              membership,
              selectedTrailerCareType,
            })
          )

  return (
    <div>
      <ReviewStep
        account={account}
        confirmAndFinalizePurchase={confirmAndFinalizePurchase}
        membership={membership}
        showTrailerCareCost
        paymentPath={makeMembershipIdPath(
          membershipId,
          PATH.UPGRADES,
          PATH.TRAILER_CARE,
          PATH.PAYMENT
        )}
        nextStep={makeMembershipIdPath(
          membershipId,
          PATH.UPGRADES,
          PATH.TRAILER_CARE,
          PATH.CONFIRM_AND_LOGIN
        )}
        canEditProductSelections
      />
    </div>
  )
}

TrailerCareUpgradeReview.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    billing: memberSelectors.billing(state),
    membership: memberSelectors.membership(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  addTrailerCare: apiActions.addTrailerCare,
  chargeMembershipUpgrade: apiActions.chargeMembershipUpgrade,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(TrailerCareUpgradeReview)

function trailerCareUpgradeApiValues({ billing, membership }) {
  const { subtotalWithoutDonations: total_cost } = billing
  const cc_details = parseCreditCardAndBillingAddress(billing)

  return {
    cc_details,
    total_cost,
    upgrading_from: TRAILER_CARE_TYPE.BASIC,
    upgrading_to: TRAILER_CARE_TYPE.PLUS,
    membership__c: {
      [HEROKU_ID_KEY]: membership[HEROKU_ID_KEY],
    },
  }
}

function addTrailerCareApiValues({
  billing,
  membership,
  selectedTrailerCareType,
}) {
  const { subtotalWithoutDonations: total_cost } = billing
  const cc_details = parseCreditCardAndBillingAddress(billing)

  return {
    cc_details,
    total_cost,
    membership__c: {
      [HEROKU_ID_KEY]: membership[HEROKU_ID_KEY],
    },
    trailer_care_type: selectedTrailerCareType,
  }
}
