import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { clearMessages, flashErrorMessage } from 'redux-flash'
import { clearSubmitErrors } from 'redux-form'
import { useLocation, useHistory } from 'react-router-dom'
import { first, isNil } from 'lodash'
import { api } from 'api'
import * as apiActions from 'api-actions'
import { SectionHeader } from 'components'
import { ProgressSteps } from '../components'
import GoogleMapReact from 'google-map-react'
import { LocatePortForm } from '../forms'
import { MARKER_MAP_ZOOM, REACT_APP_GOOGLE_MAPS_API_KEY } from 'config'
import { apiValuesWithHomePort, getLocalityFromGooglePlace } from 'utils'

const propTypes = {
  clearMessages: PropTypes.func.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  fetchAor: PropTypes.func,
  flashErrorMessage: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
}

function LocatePortSection({
  clearMessages,
  clearSubmitErrors,
  fetchAor,
  flashErrorMessage,
  previousStep,
}) {
  const history = useHistory()
  const location = useLocation()
  const {
    homePortLocality,
    homePortName,
    homePortType,
  } = location.state.portWizardData
  const [currentHomePortLocality, setCurrentHomePortLocality] = useState(
    homePortLocality
  )
  const { lat, lng } = homePortLocality
  const currentMarker = {
    coordinates: {
      lat: lat,
      lng: lng,
    },
    zoom: MARKER_MAP_ZOOM,
  }

  // If the previous wizard step provided an actual address (i.e., not a
  // locality (city/state/country only)) obtain the address components for
  // the address using a reverse address search based on the provided lat/long.
  // Otherwise, if only a locality is provided, the user will have to move the
  // map marker to an actual address...
  // Ideally, the Google Places API used in the previous step would provide
  // the address components as part of the query. Unfortunately, this is currently
  // not supported.
  useEffect(() => {
    const getLocalityDetail = async () => {
      const initialLocality = await getLocalityFromLatLong(lat, lng)

      setCurrentHomePortLocality(initialLocality)
    }

    const { street_number } = currentHomePortLocality

    if (isNil(street_number)) getLocalityDetail()
  }, [])

  return (
    <>
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <ProgressSteps activeStep={3} totalSteps={3} />
              <SectionHeader
                sectionHeaderName="Locate Marina"
                sectionDescription="Time to make sure the pin is placed exactly where your home port is located"
              />
              <div className="note">
                <p>
                  To adjust the pin's location, tap/click and drag it to the
                  proper location.
                </p>
              </div>
              <div className="map large">
                <GoogleMapReact
                  center={currentMarker.coordinates}
                  zoom={currentMarker.zoom}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(
                      map,
                      maps,
                      currentMarker.coordinates,
                      setCurrentHomePortLocality,
                      clearMessages,
                      clearSubmitErrors
                    )
                  }
                  yesIWantToUseGoogleMapApiInternals
                  bootstrapURLKeys={{
                    key: REACT_APP_GOOGLE_MAPS_API_KEY,
                  }}
                />
              </div>
              <LocatePortForm
                homePortLocality={currentHomePortLocality}
                homePortType={homePortType}
                initialValues={{ homePortName }}
                previousFormStep={{
                  pathname: previousStep,
                  state: {
                    portWizardData: {
                      homePortType: location.state.portWizardData.homePortType,
                    },
                    formData: location.state.formData,
                    returnUrl: location.state.returnUrl,
                    boat: location.state.boat,
                  },
                }}
                onSubmit={async (locatePortFormData) => {
                  const { homePortName } = locatePortFormData
                  const { lat, lng } = currentHomePortLocality
                  const aor = await fetchAor({
                    lat: lat,
                    lon: lng,
                  })
                  const mappedApiValues = apiValuesWithHomePort(
                    currentHomePortLocality,
                    homePortName,
                    homePortType,
                    aor
                  )
                  const newLocation = {
                    pathname: location.state.returnUrl,
                    state: {
                      formData: {
                        ...location.state.formData,
                        ...mappedApiValues,
                      },
                      boat: location.state.boat,
                    },
                  }
                  history.push(newLocation)
                }}
                onSubmitFail={({ _error }) => {
                  flashErrorMessage(_error)
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

LocatePortSection.propTypes = propTypes

const mapDispatchToProps = {
  clearMessages: clearMessages,
  clearSubmitErrors: clearSubmitErrors,
  flashErrorMessage: flashErrorMessage,
  fetchAor: apiActions.fetchAor,
  updateBoat: apiActions.updateBoat,
}

export default compose(connect(null, mapDispatchToProps))(LocatePortSection)

async function handleApiLoaded(
  map,
  maps,
  marker,
  setCurrentHomePortLocality,
  clearMessages,
  clearSubmitErrors
) {
  const updateMarker = new maps.Marker({
    position: { lat: marker.lat, lng: marker.lng },
    map,
    draggable: true,
  })
  updateMarker.addListener('dragend', async () => {
    const lat = updateMarker.getPosition().lat()
    const lng = updateMarker.getPosition().lng()
    const updatedLocality = await getLocalityFromLatLong(lat, lng)

    setCurrentHomePortLocality(updatedLocality)
    clearSubmitErrors('LocatePortForm')
    clearMessages()
  })
}

const getLocalityFromLatLong = async (lat, lng) => {
  const allMatchingLocations = await api.get(`/geocoding?latlng=${lat},${lng}`)
  const googlePlace = first(allMatchingLocations.results) // Only consider the first returned result

  return getLocalityFromGooglePlace(googlePlace)
}
