import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {}

function Footer() {
  return <footer></footer>
}

Footer.propTypes = exact(propTypes)

export default pure(Footer)
