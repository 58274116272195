import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Slider from 'react-slick'

const propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
  }),
}

function Carousel({
  children,
  settings = {
    autoplay: true,
    autoplaySpeed: 3000, // length of time (in ms) on a slide
    dots: true,
    infinite: true,
  },
}) {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
        />
      </Helmet>
      <div className="carousel">
        <Slider {...settings}>{children}</Slider>
      </div>
    </>
  )
}

Carousel.propTypes = propTypes

export default React.memo(Carousel)
