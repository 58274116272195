import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ThankYou } from 'programs-portal/components'
import * as Views from './views'
import Layout from './Layout'
import { PATH, makePath } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route path={makePath(path, PATH.APPLY)}>
          <Views.MembershipKitApplication />
        </Route>
        <Route path={makePath(path, PATH.THANK_YOU)}>
          <ThankYou headerText="Membership Kits" />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes

export default Routes
