import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  Field,
  SubmissionError,
  getFormValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { useHistory, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'
import { lpForm } from 'lp-form'
import { HomePortAddress, HomePortMap } from '../components'
import { Checkbox, Input, SubmitButton, Button, Select } from 'lp-components'
import { OTHER_BOATING_OPTIONS, BoatType } from 'types'
import classnames from 'classnames'
import { SEATOW_PHONE, SEATOW_PHONE_DIGITS } from 'config'
import {
  areValuesDefined,
  boatMake,
  getHomePortFromFormValues,
  homePortTitleByType,
  navigateToNextStepWithState,
  useToggle,
} from 'utils'

const propTypes = {
  boat: BoatType,
  existingMemberBoat: PropTypes.bool,
  nextStep: PropTypes.string.isRequired,
  primaryBoat: BoatType,
  ...formPropTypes,
}

// This form is reused for both editing an existing boat, in which case the
// "boat" property has a value. Otherwise, a new boat is being created and
// "boat" does not have a value.

function OtherBoatForm({
  boat,
  change,
  existingMemberBoat = false,
  formValues,
  handleSubmit,
  nextStep,
  primaryBoat,
  pristine,
  submitting,
}) {
  const [noBoatReasonField, setNoBoatReasonField] = useState(
    formValues?.noBoatReason
  )
  const [isPrimaryBoat, changeIsPrimaryBoat] = useToggle()
  const location = useLocation()
  const history = useHistory()
  const newOtherBoat = isNil(boat)
  const primary = !newOtherBoat ? boat.primary_boat__c : false

  const homePort = getHomePortFromFormValues(formValues)
  const {
    address,
    city,
    country,
    homePortName,
    homePortType,
    state,
    lat,
    lng,
  } = homePort
  const hasHomePort = areValuesDefined(lat, lng)
  const newMemberBoat = !existingMemberBoat

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="group-block">
        <Field
          component={Select}
          label="How you go boating"
          name="noBoatReason"
          options={Object.values(OTHER_BOATING_OPTIONS)}
          placeholder="Select"
          onChange={(e) => {
            change('boatClubName', null)
            setNoBoatReasonField(e.target.value)
          }}
          required
          requiredIndicator="*"
        />

        {noBoatReasonField === OTHER_BOATING_OPTIONS.BOAT_CLUB_MEMBER && (
          <Field
            name="boatClubName"
            component={Input}
            label="Name of Boat Club"
            required
            requiredIndicator="*"
          />
        )}
      </div>

      <div className="group-block spacing">
        <h3 className="group-title home-port">Other Boat Home Port</h3>
        <div className="inner-text-action">
          <p>
            Indicate the marina, home dock, or mooring where this boat is kept,
            or the trailer ramp you use the majority of the time.
          </p>
          {hasHomePort && (
            <Button
              className="button-primary-outline"
              onClick={() =>
                navigateToNextStepWithState(
                  history,
                  nextStep,
                  formValues,
                  location,
                  boat
                )
              }
            >
              Edit Other Boat Home Port
            </Button>
          )}
        </div>

        {hasHomePort && (
          <>
            <HomePortMap lat={lat} lng={lng} />
            <div className="map-content">
              {newMemberBoat ? (
                <h3>{homePortTitleByType(homePortType)}</h3>
              ) : (
                <h3>Other Boat Home Port Address</h3>
              )}
              <Button
                className="link-primary"
                onClick={() =>
                  navigateToNextStepWithState(
                    history,
                    nextStep,
                    formValues,
                    location,
                    boat
                  )
                }
              >
                (Edit Other Boat Home Port)
              </Button>
              <HomePortAddress
                address={address}
                city={city}
                country={country}
                name={homePortName}
                state={state}
              />
            </div>
          </>
        )}

        {newOtherBoat && isNil(location?.state?.formData) && (
          <Button
            className="button-primary-outline plus-left-button-icon"
            onClick={() =>
              navigateToNextStepWithState(
                history,
                nextStep,
                formValues,
                location,
                boat
              )
            }
          >
            Add Home Port
          </Button>
        )}
      </div>

      {existingMemberBoat && !primary && (
        <div className="group-block medium-spacing">
          <h3 className="group-title">Assign as your Primary Boat</h3>
          {newOtherBoat ? (
            <>
              <p>Your current primary boat is {boatMake(primaryBoat)}</p>
              <div className="switch-wrapper">
                <label className="switch">
                  <Field
                    component={Checkbox}
                    label=" "
                    name="primaryBoat"
                    onChange={changeIsPrimaryBoat}
                  />
                  <span
                    className={classnames('slider', 'round', {
                      checked: isPrimaryBoat,
                    })}
                  ></span>
                </label>
                <p>{isPrimaryBoat ? 'Yes' : 'No'}</p>
              </div>
            </>
          ) : (
            <p>
              Your current primary boat is {boatMake(primaryBoat)}. Please call{' '}
              <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> (
              {SEATOW_PHONE_DIGITS}) to reassign this boat as your primary
            </p>
          )}
        </div>
      )}

      <div className="button-group">
        <SubmitButton
          submitting={submitting}
          pristine={
            !newMemberBoat && pristine && isNil(location?.state?.formData)
          }
        >
          {newMemberBoat
            ? 'Save & Continue'
            : newOtherBoat
            ? 'Submit'
            : 'Save Other Boat Updates'}
        </SubmitButton>
      </div>
    </form>
  )
}

OtherBoatForm.propTypes = propTypes

const beforeSubmit = (formValues, { boat, existingMemberBoat }) => {
  // Check to see if for new other boats, the user has gone through the
  // homeport wizard. If so, the type collected through the wizard will not be
  // undefined
  if (isNil(formValues.homePortType)) {
    throw new SubmissionError({
      _error: 'Add a home port to your boat!',
    })
  }

  // For new boats of existing members and it is not a brand new member account
  // ("boat" is undefined), the primaryBoat Boolean must be set,
  // even if the user does not change its value in the form...
  if (existingMemberBoat) {
    if (isNil(boat)) {
      const { primaryBoat } = formValues

      formValues = { ...formValues, primaryBoat: primaryBoat ?? false }
    }
  }

  return formValues
}

function mapStateToProps(state, { form }) {
  return {
    formValues: getFormValues(form)(state),
  }
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'OtherBoatForm',
    constraints: {
      noBoatReason: { presence: true },
      boatClubName: { presence: { allowEmpty: false } },
    },
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(OtherBoatForm)
