import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DeliverySelectionForm } from '../forms'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { PATH, makeGiftStepsPath } from 'config'

const propTypes = {
  clearForNewDeliveryMethod: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
  setDeliveryMethod: PropTypes.func.isRequired,
}

function Delivery({
  clearForNewDeliveryMethod,
  deliveryMethod,
  setDeliveryMethod,
}) {
  const history = useHistory()

  return (
    <DeliverySelectionForm
      {...{ clearForNewDeliveryMethod }}
      initialValues={{
        deliveryMethod,
      }}
      onSubmit={({ deliveryMethod }) => setDeliveryMethod(deliveryMethod)}
      onSubmitSuccess={() => history.push(makeGiftStepsPath(PATH.RECIPIENT))}
    />
  )
}

function mapStateToProps(state) {
  return {
    deliveryMethod: selectors.deliveryMethod(state),
  }
}

const mapDispatchToProps = {
  clearForNewDeliveryMethod: actions.clearForNewDeliveryMethod,
  setDeliveryMethod: actions.setDeliveryMethod,
}

Delivery.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(Delivery)
