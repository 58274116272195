import { combineReducers } from 'redux'
import {
  reducer as globalMemberReducer,
  reducerKey as globalMemberReducerKey,
} from './global-member-reducer'
import {
  reducer as membershipCardViewPrintReducer,
  reducerKey as membershipCardViewPrintKey,
} from './membership_card_view_print'
import {
  reducer as membershipsReducer,
  reducerKey as membershipsReducerKey,
} from './memberships'
import {
  reducer as savingsClubReducer,
  reducerKey as savingsClubReducerKey,
} from './savings_club'
import {
  reducer as seaTowAsAGiftReducer,
  reducerKey as seaTowAsAGiftReducerKey,
} from './sea_tow_as_a_gift'

const reducerKey = 'memberPortal'

const reducer = combineReducers({
  [globalMemberReducerKey]: globalMemberReducer,
  [membershipCardViewPrintKey]: membershipCardViewPrintReducer,
  [membershipsReducerKey]: membershipsReducer,
  [savingsClubReducerKey]: savingsClubReducer,
  [seaTowAsAGiftReducerKey]: seaTowAsAGiftReducer,
})

export { reducer, reducerKey }
