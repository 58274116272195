import { invert, mapKeys } from 'lodash'

// NOTE: the provided key map is of the form:
//    { apiKey: formKey }
// For example: { billingcity: 'city' }
const apiValuesWithMappedKeys = (object, keyMap) => {
  // First, invert the keys of the provided key map to create a mapping from the
  // form keys to the API keys.
  const formKeys = invert(keyMap)
  const mapToApiKeys = (_, key) => formKeys[key]

  // Then return the object with the form keys mapped to the API keys
  return mapKeys(object, mapToApiKeys)
}

export default apiValuesWithMappedKeys
