import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { Link, useHistory } from 'react-router-dom'
import {
  differenceInCalendarDays,
  isFuture,
  isWithinInterval,
  subDays,
} from 'date-fns'
import { Button } from 'lp-components'
import { MEMBERSHIP_STATUS, ProductPrices } from 'types'
import { RenewButton } from 'member-portal/components'
import {
  MEMBERSHIP_EXPIRATION_WARNING_DAYS,
  PATH,
  SEATOW_PHONE,
  makePath,
  makeMembershipIdPath,
} from 'config'
import { formatUSMonthDayYearDate, parseISODate, parseISODateTime } from 'utils'
import activeIcon from 'utility-icons/completed.svg'
import warnIcon from 'utility-icons/warn.svg'
import { isEmpty } from 'lodash'

const propTypes = {
  autorenewDateString: PropTypes.string,
  autorenews: PropTypes.bool.isRequired,
  canAutoRenew: PropTypes.bool.isRequired,
  effectiveDateString: PropTypes.string,
  expirationDateString: PropTypes.string.isRequired,
  isMonthlyAccountType: PropTypes.bool,
  isSubsidizedAccountType: PropTypes.bool,
  isTrialMembership: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  memberNumber: PropTypes.string.isRequired,
  membershipPrices: ProductPrices,
  membershipSfid: PropTypes.string.isRequired,
  renewalProduct: PropTypes.string,
  status: PropTypes.string.isRequired,
  subsidizedName: PropTypes.string,
}

function RenewalStatus({
  autorenewDateString,
  autorenews,
  canAutoRenew,
  effectiveDateString,
  expirationDateString,
  isMonthlyAccountType,
  isSubsidizedAccountType,
  isTrialMembership,
  isTrialMembershipsActive,
  memberNumber,
  membershipPrices,
  membershipSfid,
  renewalProduct,
  status,
  subsidizedName,
}) {
  const expirationDate = parseISODate(expirationDateString)
  const formattedExpirationDateString = formatUSMonthDayYearDate(expirationDate)
  const membershipIdPath = makeMembershipIdPath(membershipSfid)
  const billingPath = makePath(membershipIdPath, PATH.BILLING)
  const renewPath = makePath(
    membershipIdPath,
    PATH.RENEW_MEMBERSHIP,
    PATH.SELECTION
  )
  const history = useHistory()
  const renewalMembershipType = renewalProduct ?? 'Gold Card'
  const renewalMembershipPrice =
    isTrialMembershipsActive && membershipPrices[renewalMembershipType]

  if (status === MEMBERSHIP_STATUS.EXPIRED)
    return (
      <ExpiredView
        expirationDateString={formattedExpirationDateString}
        memberNumber={memberNumber}
        renewPath={renewPath}
      />
    )
  if (
    status === MEMBERSHIP_STATUS.CANCELLED ||
    status === MEMBERSHIP_STATUS.INACTIVE
  )
    return <CancelledInactiveView status={status} />

  if (isMonthlyAccountType || isSubsidizedAccountType)
    return (
      <MonthlyOrSubsidizedView
        showExpirationDate={isSubsidizedAccountType}
        subsidizedName={subsidizedName}
        expirationDateString={formattedExpirationDateString}
      />
    )

  let futureServiceDateString = null
  if (!isEmpty(effectiveDateString)) {
    const effectiveDate = parseISODateTime(effectiveDateString)
    futureServiceDateString = isFuture(effectiveDate)
      ? formatUSMonthDayYearDate(effectiveDate)
      : null
  }
  const autorenewEnrollmentPath = makePath(
    billingPath,
    PATH.AUTO_RENEW_ENROLLMENT
  )

  if (autorenews)
    return (
      <AutorenewView
        autorenewDateString={autorenewDateString}
        billingPath={billingPath}
        expirationDateString={formattedExpirationDateString}
        futureServiceDateString={futureServiceDateString}
        memberNumber={memberNumber}
        renewPath={renewPath}
        {...(isTrialMembershipsActive && {
          isTrialMembership,
          isTrialMembershipsActive,
          renewalMembershipType,
          renewalMembershipPrice,
        })}
      />
    )

  return (
    <NotEnrolledInAutorenewView
      autorenewEnrollmentPath={autorenewEnrollmentPath}
      canAutoRenew={canAutoRenew}
      expirationDate={expirationDate}
      futureServiceDateString={futureServiceDateString}
      formattedExpirationDate={formattedExpirationDateString}
      history={history}
      memberNumber={memberNumber}
      renewPath={renewPath}
    />
  )
}

RenewalStatus.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isMonthlyAccountType: globalSelectors.isMonthlyAccountType(state),
    isSubsidizedAccountType: globalSelectors.isSubsidizedAccountType(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(RenewalStatus)

function ExpiredView({ expirationDateString, memberNumber, renewPath }) {
  return (
    <div className="detail-block">
      <h4>Membership has Expired</h4>
      <p>
        <img src={warnIcon} alt="" className="status-icon" />
        Membership expiration date: {expirationDateString}
      </p>
      <RenewButton
        className="button-secondary"
        aria-label={`Renew membership now for membership ${memberNumber}`}
        to={renewPath}
      >
        Renew Now
      </RenewButton>
    </div>
  )
}

function CancelledInactiveView({ status }) {
  return (
    <div className="detail-block">
      <h4>
        Membership{' '}
        {status === MEMBERSHIP_STATUS.CANCELLED
          ? 'has been Canceled'
          : 'is Inactive'}
      </h4>
      <p>
        Call <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> for information.
      </p>
    </div>
  )
}

function AutorenewView({
  autorenewDateString,
  billingPath,
  expirationDateString,
  futureServiceDateString,
  memberNumber,
  renewPath,
  isTrialMembership,
  isTrialMembershipsActive,
  renewalMembershipType,
  renewalMembershipPrice,
}) {
  const autorenewDate = parseISODate(autorenewDateString)

  return (
    <div
      className={
        isTrialMembership && isTrialMembershipsActive
          ? 'trial-detail-block'
          : 'detail-block'
      }
    >
      <h4>
        <img src={activeIcon} alt="" className="status-icon" />
        Auto Renew: Enrolled
      </h4>
      <Link
        to={billingPath}
        className="link-primary"
        aria-label={`Manage active auto-renew for membership ${memberNumber}`}
      >
        (Manage)
      </Link>
      {isTrialMembership && isTrialMembershipsActive
        ? ''
        : futureServiceDateString && (
            <>
              <p>Membership starts on: {futureServiceDateString}</p>
              <p>Membership expiration date: {expirationDateString}</p>
            </>
          )}
      <p>Automatically renews: {formatUSMonthDayYearDate(autorenewDate)}</p>
      {isTrialMembership && isTrialMembershipsActive && (
        <p>{`(10 days before expiration date with ${renewalMembershipType} at $${renewalMembershipPrice}/year)`}</p>
      )}
      <RenewButton
        to={renewPath}
        className="link-secondary"
        aria-label={`Renew membership early for membership ${memberNumber}`}
      >
        Renew Early
      </RenewButton>
    </div>
  )
}

function MonthlyOrSubsidizedView({
  expirationDateString,
  showExpirationDate,
  subsidizedName,
}) {
  return (
    <div className="detail-block">
      <h4>Membership Provided by:</h4>
      <p>{subsidizedName}</p>
      {showExpirationDate && (
        <p>Membership expiration date: {expirationDateString}</p>
      )}
    </div>
  )
}

function NotEnrolledInAutorenewView({
  autorenewEnrollmentPath,
  canAutoRenew,
  expirationDate,
  futureServiceDateString,
  formattedExpirationDate,
  history,
  memberNumber,
  renewPath,
}) {
  const today = new Date()
  const expirationWarningDate = subDays(
    expirationDate,
    MEMBERSHIP_EXPIRATION_WARNING_DAYS
  )
  const shouldRaiseRenewalAlert = isWithinInterval(today, {
    start: expirationWarningDate,
    end: expirationDate,
  })
  const daysUntilExpiration = differenceInCalendarDays(expirationDate, today)

  return (
    <div className="detail-block">
      {shouldRaiseRenewalAlert ? (
        <>
          <h4>
            Membership expiring in {daysUntilExpiration} day
            {daysUntilExpiration > 1 && 's'}
          </h4>
          <p>
            <img src={activeIcon} alt="" className="status-icon" />
            Membership expiration date: {formattedExpirationDate}
          </p>
          <RenewButton
            className="button-secondary"
            to={renewPath}
            aria-label={`Renew membership now for membership ${memberNumber}`}
          >
            Renew Now
          </RenewButton>
        </>
      ) : (
        <>
          <h4>
            {canAutoRenew ? 'Auto Renew: Not enrolled' : 'Membership Status'}
          </h4>
          {futureServiceDateString && (
            <p>Membership starts on: {futureServiceDateString}</p>
          )}
          <p>Membership expiration date: {formattedExpirationDate}</p>
          <div className="button-group-2">
            {canAutoRenew && (
              <Button
                onClick={() => history.push(autorenewEnrollmentPath)}
                aria-label={`Enroll in auto-renew now for membership ${memberNumber}`}
              >
                Enroll Now
              </Button>
            )}
            <RenewButton
              className={
                canAutoRenew ? 'button-primary-outline' : 'button-primary'
              }
              to={renewPath}
              aria-label={`Renew membership ${memberNumber}`}
            >
              Renew
            </RenewButton>
          </div>
        </>
      )}
    </div>
  )
}
