import { DISCOUNT_CODE_TYPE } from 'types'

function getInitialDiscountCodeValues(discountCodeDetails) {
  const baseValues = { showDiscountCodeFields: !!discountCodeDetails }
  if (!discountCodeDetails) return baseValues

  if (discountCodeDetails.type === DISCOUNT_CODE_TYPE.ACTIVATION)
    return { ...baseValues, activationCode: discountCodeDetails.name }

  return {
    ...baseValues,
    promoCode: discountCodeDetails.name,
  }
}

export default getInitialDiscountCodeValues
