export const isProduction = () => process.env.NODE_ENV === 'production'
export const usingTestApi = () => process.env.REACT_APP_USE_TEST_API === true

export const FEATURES = {
  TRIAL_MEMBERSHIPS: 'trialMemberships',
}
export const FEATURED_FLAGS_LIST = [
  {
    name: FEATURES.TRIAL_MEMBERSHIPS,
    isActive: process.env.REACT_APP_SET_TRIAL_MEMBERSHIPS_ACTIVE === true,
  },
]

export const ADDRESS_FORM_VALIDATOR = {
  presence: { allowEmpty: false },
  validateNotEmail: true,
}

export const AUTOCOMPLETE_ADDRESS_FORM_VALIDATOR = {
  presence: { allowEmpty: false },
  validateStreetAddress: true,
}

export const APPLICATION = {
  MEMBER_PORTAL: 'members',
  PROGRAMS_PORTAL: 'programs',
  SEA_TOW: 'sea-tow',
}

export const APPLICATION_ROOT_URL = window.location.origin

export const AUTHORIZED_APPLICATION = [
  APPLICATION.MEMBER_PORTAL,
  APPLICATION.PROGRAMS_PORTAL,
]

export const AUTORENEW_BEFORE_EXPIRATION_DAYS = 10

export const COST = {
  GIFT_WRAPPING: 3,
  GOLD_UPGRADE: '$80',
  MEMBERSHIP_RENEWAL_CREDIT: '$25',
  REDUCED_CORPORATE_HOURLY_RATE: '$100',
  REFERRAL_CREDIT_MULTIPLIER: 25,
  REIMBURSEMENT_LIMIT: '$5000',
}

export const DIGITAL_WALLET_TYPE = {
  APPLE: 'Apple',
  GOOGLE: 'Google',
}

// Note: if a new country is added, the COUNTRY_STATES map must also be updated to
// map the country to its associated list of states.
//
// Additionally, the following must also be udpated:
//    * the PHONE_REGION_CODE must also be udpated with the phone region code
//      for the added country.
//      See https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
//    * the Cleave.js library that supports each coutnry must also be included in
//      src/entry/incdex.js
export const COUNTRY = {
  CANADA: 'Canada',
  MEXICO: 'Mexico',
  UNITED_STATES: 'United States',
}

export const COUNTRY_STATES = {
  [COUNTRY.CANADA]: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Northwest Territories',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon Territories',
  ],
  [COUNTRY.MEXICO]: [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Chihuahua',
    'Colima',
    'Campeche',
    'Coahuila',
    'Chiapas',
    'Federal District',
    'Durango',
    'Guerrero',
    'Guanajuato',
    'Hidalgo',
    'Jalisco',
    'Mexico State',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'Sinaloa',
    'San Luis Potosí',
    'Sonora',
    'Tabasco',
    'Tlaxcala',
    'Tamaulipas',
    'Veracruz',
    'Yucatán',
    'Zacatecas',
  ],
  [COUNTRY.UNITED_STATES]: [
    'Armed Forces Americas',
    'Armed Forces Europe',
    'Alaska',
    'Alabama',
    'Armed Forces Pacific',
    'Arkansas',
    'American Samoa',
    'Arizona',
    'California',
    'Colorado',
    'Connecticut',
    'District of Columbia',
    'Delaware',
    'Florida',
    'Federated Micronesia',
    'Georgia',
    'Guam',
    'Hawaii',
    'Iowa',
    'Idaho',
    'Illinois',
    'Indiana',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Massachusetts',
    'Maryland',
    'Maine',
    'Marshall Islands',
    'Michigan',
    'Minnesota',
    'Missouri',
    'Northern Mariana Islands',
    'Mississippi',
    'Montana',
    'North Carolina',
    'North Dakota',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Palau',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'United States Minor Outlying Islands',
    'Utah',
    'Virginia',
    'US Virgin Islands',
    'Vermont',
    'Washington',
    'Wisconsin',
    'West Virginia',
    'Wyoming',
  ],
}

export const HOME_PORT_RESTRICTED_STATES = [
  'Florida',
  'Alaska',
  'Hawaii',
  'Puerto Rico',
  'Virgin Islands',
]

export const DEFAULT_MAP_ZOOM = 11
export const DEFAULT_SEARCH_RESULTS_PAGE_SIZE = 12
export const GIFT_MESSAGE_LENGTH = 255

export const IMAGE_PATH = {
  DEFAULT_AOR_IMAGE: '/assets/images/captain-placeholder.png',
  DEFAULT_PARTNER_LOGO: '/assets/images/icons/transparent/ST_Icon_Account.svg',
  DROPDOWN_ICON: '/assets/images/icons/dropdown.svg',
  GIFT_CONFIRMATION: '/assets/images/icons/with-background/ST_YIcon_Yes.svg',
  MEMBER_APPLICATION_CONFIRMATION:
    '/assets/images/icons/with-background/ST_YIcon_Account-Holder.svg',
  MEMBERSHIP_KIT:
    '/assets/images/imagery/programs-portal/horizontal-membership-kits.jpg',
  REFERRALS_HEADER: '/assets/images/imagery/refer-with-text.jpg',
  SEA_CARE: '/assets/images/imagery/programs-portal/horizontal-seacare.jpg',
  SEATOW_LOGO: '/assets/images/logo.svg',
  SEATOW_MEMBER_CARD_LOGO: '/assets/images/member-card-logo.png',
}

export const MARKER_MAP_ZOOM = 13
export const MAXIMUM_DESCRIPTION_LENGTH = 1000 // characters
export const MAXIMUM_LOGO_SIZE = 500000 // bytes (500kb)
export const MEMBERSHIP_EXPIRATION_WARNING_DAYS = 45

export const PATH = {
  AUTO_RENEW_AGREEMENT: '#auto_renew',
  ACCOUNT: '/account',
  AGREEMENTS: '/agreements',
  APPLY: '/apply',
  AUTO_RENEW_ENROLLMENT: '/auto_renew_enrollment',
  BILLING: '/billing',
  BLOG: '/blog',
  BOATS: '/boats',
  CLIENT_ERROR: '/error/client_error',
  CREATE_ACCOUNT: '/create_account',
  CONFIRM_AND_LOGIN: '/confirm_and_login',
  CONFIRM_GIFT: '/confirm_gift',
  CONFIRM_OFFER: '/confirm_offer',
  CONTACT: '/contact',
  CONTACT_2: '/contact-2',
  DEAL: '/deal',
  DELIVERY: '/delivery',
  EDIT: '/edit',
  EDIT_BOAT: '/edit_boat',
  EDIT_OFFER: '/edit_offer',
  EDIT_OTHER_BOAT: '/edit_other_boat',
  ERROR: '/error',
  EXISTING_MEMBER: '/existing_member',
  HOME_PORT_TYPE: '/home_port_type',
  HOME_PORT_ADDRESS: '/home_port_address',
  HOME_PORT_LOCATION: '/home_port_location',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MEMBER_APPLICATION: '/join',
  MEMBER_PORTAL: '/members',
  MEMBERSHIP_AGREEMENT: '/membership-agreement',
  MEMBERSHIP_CARD: '/membership_card',
  MEMBERSHIP_CARD_VIEW_PRINT: '/membership_card_view_print',
  MEMBERSHIP_KIT: '/membership_kit',
  MEMBERSHIP_TYPE_ALL: '/membership_type_all',
  MEMBERSHIP_TYPE_SELECTED: '/membership_type_selected',
  MEMBERSHIP_TRIAL_SELECTED: '/membership_trial_selected',
  MEMBERSHIP: '/membership',
  MEMBERSHIPS: '/memberships',
  NEW: '/new',
  NEW_CONTACT: '/new_contact',
  NEW_MEMBER: '/new_member',
  NEW_MEMBER_QUESTIONNAIRE: '/new_member_questionnaire',
  OFFERS: '/offers',
  OTHER: '/other',
  PARTNER_AGREEMENT: '#partner_agreement',
  PROGRAMS_DASHBOARD: '/dashboard',
  PROGRAMS_PORTAL: '/programs',
  PAYMENT: '/payment',
  POST_LOGIN: '/post_login',
  RECIPIENT: '/recipient',
  REFERRALS: '/referrals',
  REGISTRATION: '/registration',
  RENEW_MEMBERSHIP: '/renew',
  RENEWAL_QUESTIONNAIRE: '/renewal_questionnaire',
  REVIEW_ORDER: '/review_order',
  ROOT: '/',
  SAVINGS_CLUB: '/savings_club',
  SAVINGS_CLUB_OFFERS: '/savings_club_offers',
  SEA_CARE: '/sea_care',
  SELECTION: '/selection',
  SEATOW: process.env.REACT_APP_MARKETING_SITE ?? 'https://seatow.com',
  SEATOW_LOGOUT: '/logged-out',
  SEA_TOW_AS_A_GIFT: '/gift',
  SELECT_YOUR_MEMBERSHIP: '/select_your_membership',
  SERVER_ERROR: '/error/server_error',
  SIGNUP: '/signup',
  SFID: '/:sfid',
  STEPS: '/steps',
  STYLEGUIDE: '/styleguide',
  THANK_YOU: '/thank_you',
  TRAILER_CARE: '/trailer_care',
  TRAILER_CARE_AGREEMENT: '#trailer_care',
  UPGRADES: '/upgrades',
}
// Path helpers...
export const makePath = (...args) => args.join('')
export const makeApplicationPath = (...args) =>
  makePath(APPLICATION_ROOT_URL, ...args)
export const makeGiftPath = (...args) =>
  makeMemberPortalPath(PATH.SEA_TOW_AS_A_GIFT, ...args)
export const makeGiftStepsPath = (...args) => makeGiftPath(PATH.STEPS, ...args)
export const makeMemberApplicationPath = (...args) =>
  makeMemberPortalPath(PATH.MEMBER_APPLICATION, ...args)
export const makeMemberApplicationApplyPath = (...args) =>
  makeMemberApplicationPath(PATH.APPLY, ...args)
export const makeMemberPortalPath = (...args) =>
  makePath(PATH.MEMBER_PORTAL, ...args)
export const makeMembershipsPath = (...args) =>
  makeMemberPortalPath(PATH.MEMBERSHIPS, ...args)
export const makeMembershipIdPath = (...args) =>
  makeMembershipsPath('/', ...args)
export const makeProgramsPortalPath = (...args) =>
  makePath(PATH.PROGRAMS_PORTAL, ...args)
export const makeProgramsApplicationPath = (...args) =>
  makeProgramsPortalPath(PATH.APPLY, ...args)
export const makeMarketingSitePath = (...args) => makePath(PATH.SEATOW, ...args)

// Additional PATH constants. Note that these must be defined *after* PATH is
// defined such that PATH has a value...
export const GIFT_APPLICATION_STEP_LINKS = [
  {
    stepName: 'Delivery',
    path: makeGiftStepsPath(PATH.DELIVERY),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Mail-Card.svg',
  },
  {
    stepName: 'Recipient Information',
    path: makeGiftStepsPath(PATH.RECIPIENT),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Account.svg',
  },
  {
    stepName: 'Payment',
    path: makeGiftStepsPath(PATH.PAYMENT),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Payment.svg',
  },
  {
    stepName: 'Review Your Order',
    path: makeGiftStepsPath(PATH.REVIEW_ORDER),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Dashboard.svg',
  },
]

export const MEMBER_APPLICATION_STEP_LINKS = [
  {
    stepName: 'Contact Info',
    path: makeMemberApplicationApplyPath(PATH.CONTACT),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Account.svg',
  },
  {
    stepName: 'Primary Boat Information',
    path: makeMemberApplicationApplyPath(PATH.BOATS),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Prim-Boat.svg',
  },
  {
    stepName: 'Payment',
    path: makeMemberApplicationApplyPath(PATH.PAYMENT),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Payment.svg',
  },
  {
    stepName: 'Review Your Order',
    path: makeMemberApplicationApplyPath(PATH.REVIEW_ORDER),
    stepIcon: '/assets/images/icons/with-background/ST_YIcon_Dashboard.svg',
  },
]

export const MEMBER_PORTAL_NAVIGATION_LINKS = [
  {
    icon: '/assets/images/icons/transparent/ST_Icon_Dashboard.svg',
    menuItemName: 'Membership Dashboard',
    path: makeMemberPortalPath(PATH.MEMBERSHIPS),
  },
  {
    icon: '/assets/images/icons/transparent/ST_Icon_Account.svg',
    menuItemName: 'Account Details & Preferences',
    path: makeMemberPortalPath(PATH.ACCOUNT),
  },
]

export const MEMBER_PORTAL_QUICKLINKS = [
  {
    linkName: 'Update Account Preferences',
    path: makeMemberPortalPath(PATH.ACCOUNT),
  },
  {
    linkName: 'Update Contacts',
    path: makeMemberPortalPath(PATH.ACCOUNT),
  },
  {
    linkName: 'Give a Gift Membership',
    path: makeMemberPortalPath(PATH.SEA_TOW_AS_A_GIFT),
  },
  {
    linkName: 'FAQ',
    path: 'https://www.seatow.com/faq',
  },
  {
    linkName: 'Download the Sea Tow App',
    path: 'https://www.seatow.com/app',
  },
  {
    linkName: 'Sea Tow Blog',
    path: 'https://www.seatow.com/blog',
  },
]

export const MEMBER_PORTAL_URL = makeApplicationPath(PATH.MEMBER_PORTAL)

export const PROGRAMS_NAVIGATION_LINKS = [
  {
    icon: '/assets/images/icons/transparent/ST_Icon_Dashboard.svg',
    menuItemName: 'Dashboard',
    path: makeProgramsPortalPath(PATH.PROGRAMS_DASHBOARD),
  },
  {
    icon: '/assets/images/icons/transparent/ST_Icon_Other.svg',
    menuItemName: 'Savings Club Offers',
    path: makeProgramsPortalPath(PATH.SAVINGS_CLUB_OFFERS),
  },
  {
    icon: '/assets/images/icons/transparent/ST_Icon_Account.svg',
    menuItemName: 'Account Details & Preferences',
    path: makeProgramsPortalPath(PATH.ACCOUNT),
  },
]

export const PROGRAMS_QUICKLINKS = [
  {
    linkName: 'Visit seatow.com',
    path: PATH.SEATOW,
  },
  {
    linkName: 'Navigating the Program Portal',
    path: 'https://youtu.be/TUo8glJDgGw',
  },
  {
    linkName: 'Setting up your Account Profile',
    path: 'https://youtu.be/dUYo8dPR-nw',
  },
  {
    linkName: 'Updating your Password',
    path: 'https://youtu.be/SjwJyl2TmR8',
  },
]

export const SAVINGS_CLUB_INFO_LINKS = [
  {
    linkName: 'Creating a Savings Club Offer',
    path: 'https://youtu.be/GN6CYVbhpqY',
  },
  {
    linkName: 'Editing or Removing a Savings Club Offer',
    path: 'https://youtu.be/EafPZPKtgnw',
  },
]

export const PROGRAMS_PORTAL_URL = makeApplicationPath(PATH.PROGRAMS_PORTAL)

//////////////////////////////////////////////////////////////////////////////

export const PHONE_REGION_CODE = {
  [COUNTRY.CANADA]: 'CA',
  [COUNTRY.MEXICO]: 'MX',
  [COUNTRY.UNITED_STATES]: 'US',
}

export const QUERY_KEY = {
  ACCOUNT_ID: 'accountId',
  ACCOUNT_TYPE: 'accountType',
  APPLICATION: 'app',
  BACK: 'back',
  CATEGORY: 'category',
  COUNTRY: 'country',
  FORM_OPEN: 'formOpen',
  GCLID: 'gclid',
  MEMBERSHIP_EXPIRATION_DATE: 'membershipExpirationDate',
  MEMBERSHIP_NUMBER: 'membershipNumber',
  MSCLKID: 'msclkid',
  PAGE: 'page',
  REFERRAL: 'refId',
  SEARCH_TERM: 'q',
  SORT: 'sort',
  OFFER_ID: 'offerId',
  RETURN_TO: 'returnTo',
  STATUS: 'status',
  TEST_VARIANT: 'testvariant', // *TODO: Update query once decided by Marketing
  USERNAME: 'userName',
  ZIP: 'zip',
}

// Constants that use the QUERY_KEY in their definition...
export const SAVINGS_CLUB_SORT = {
  FEATURED: 'Featured',
  DISTANCE_FROM_ME: 'Distance from Me',
}

export const INITIAL_SAVINGS_CLUB_SEARCH = {
  [QUERY_KEY.CATEGORY]: 'All',
  [QUERY_KEY.SORT]: SAVINGS_CLUB_SORT.FEATURED,
}

export const OPTIONAL_SAVINGS_CLUB_SEARCH_KEYS = [QUERY_KEY.SEARCH_TERM]

/////////////////////////////////////////////////////////////////////////////
export const REACT_APP_API_REQUEST_TOKEN =
  process.env.REACT_APP_API_REQUEST_TOKEN
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const REACT_APP_GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const REACT_APP_SEATOW_API = process.env.REACT_APP_SEATOW_API
export const REACT_APP_SEATOW_AUTH0_CLIENTID =
  process.env.REACT_APP_SEATOW_AUTH0_CLIENTID

export const SEATOW_AUTO_RENEW_CONTACT_EMAIL = 'autorenew@seatow.com'
export const SEATOW_PROGRAMS_CONTACT_EMAIL = 'workwithseatow@seatow.com'
export const SEATOW_PHONE = '800-4-SEATOW'
export const SEATOW_PHONE_DIGITS = '800-473-2869'
export const SEATOW_SFS_OPT_IN_NUMBER = '883383'
export const SEATOW_TRAILER_CARE_CONTACT_EMAIL = 'trailercare@seatow.com'
export const SEATOW_INFO_CONTACT_EMAIL = 'info@seatow.com'
export const SEATOW_URL = 'www.seatow.com'

export const SHIPPING_METHOD = {
  STANDARD: 'Shipping & Handling',
  SECOND_DAY_AIR: '2nd Day Air',
  NEXT_DAY_AIR: 'Next Day Air',
}

// Constants that use the SHIPPING_METHOD in their definition...
export const SHIPPING_COST = {
  [SHIPPING_METHOD.STANDARD]: 8,
  [SHIPPING_METHOD.SECOND_DAY_AIR]: 16,
  [SHIPPING_METHOD.NEXT_DAY_AIR]: 35,
}

export const SHIPPING_METHOD_SELECTION = [
  {
    key: `Standard ($${SHIPPING_COST[SHIPPING_METHOD.STANDARD]})`,
    value: SHIPPING_METHOD.STANDARD,
  },
  {
    key: `2nd Day Air ($${SHIPPING_COST[SHIPPING_METHOD.SECOND_DAY_AIR]})`,
    value: SHIPPING_METHOD.SECOND_DAY_AIR,
  },
  {
    key: `Next Day Air ($${SHIPPING_COST[SHIPPING_METHOD.NEXT_DAY_AIR]})`,
    value: SHIPPING_METHOD.NEXT_DAY_AIR,
  },
]

export const USER_RULES = {
  ARB_REQUIRED: "ARB Required",
}

///////////////////////////////////////////////////////////////////////////////

export const USER_METADATA = {
  INVALID_ACCOUNT_ID: 'INVALID',
  MEMBER_ACCOUNT_ID: 'https://seatow.com/member_account_id',
  PARTNER_ACCOUNT_ID: 'https://seatow.com/partner_account_id',
  TEMPORARY_PARTNER_ACCOUNT_ID:
    'https://seatow.com/temporary_partner_account_id',
  STRATEGY: 'https://seatow.com/strategy',
}

///////////////////////////////////////////////////////////////////////////////

export const SITE_METADATA = {
  MEMBERS: {
    metaTitle: 'Login | Manage and Renew Your Membership | Sea Tow',
    keywords: 'Sea Tow Membership',
    description:
      'Renew or upgrade your Sea Tow Membership. Manage your account, address, billing, payment, and preferences online. Give a Sea Tow Gift Membership. Login now.',
  },
  MEMBERS_RENEW: {
    metaTitle: 'Renew Your Membership | On-Water Assistance | Sea Tow',
    keywords: 'Sea Tow Membership',
    description:
      'A Sea Tow Membership gives you 24/7 on-water assistance and boat towing from expert Captains. Renew your Sea Tow Membership today.',
  },
  MEMBERS_GIFT: {
    metaTitle: 'The Gift of a Sea Tow Membership | Gifts for Boaters | Sea Tow',
    keywords: 'Gifts for Boaters',
    description:
      'Give the gift of peace-of-mind on the water with a Sea Tow membership. Sea Tow has been the most trusted name on for on-water assistance for nearly forty years.',
  },
  PROGRAMS_APPLY: {
    metaTitle: 'Apply for Sea Tow Affiliate Programs | Sea Tow',
    keywords: 'Affiliate Programs',
    description:
      'Promote your business to Sea Tow members by providing deals or discounts on your products and services that are exclusive to active Sea Tow members. Apply as an affiliate today!',
  },
  TRAILER_CARE: {
    metaTitle: 'Trailer & Towing Care | 24/7 Roadside Assistance | Sea Tow',
    keywords: 'Trailer Care',
    description:
      'Sea Tow Trailer Care offers you 24/7 roadside assistance for your trailer and towing vehicle. Services include jump start and fuel delivery, towing vehicle lockout. and more.',
  },
  SELECT_MEMBERSHIP_RENEW: {
    metaTitle: 'Login | Renew Your Membership | Sea Tow',
    keywords: 'Sea Tow Membership',
    description:
      'Renew your Sea Tow Membership online. Manage your account, address, billing, payment, and preferences online. Login now.',
  },
  PROGRAMS: {
    metaTitle: 'Affiliate Program Opportunities | Sea Tow ',
    keywords: 'Affiliate Programs',
    description:
      'Promote your business to Sea Tow members by providing deals or discounts on your products that are exclusive to active Sea Tow members. Learn more about our affiliate programs.',
  },
  MEMBERS_JOIN: {
    metaTitle: 'Become a Sea Tow Member | Sea Tow',
    keywords: 'Sea Tow Member',
    description:
      'Try our membership advisor to see the benefits and privileges of a Sea Tow Membership. Members enjoy 24/7 dispatch assistance, fuel delivery, and boat towing,  Join today!',
  },
  BASE: {
    metaTitle: 'Boat Towing & 24/7 On-Water Assistance | Sea Tow',
    keywords: 'Boat Towing',
    description:
      'Providing leading on-water assistance to boaters since 1983. A Sea Tow Membership includes a full-suite of on-water assistance services for every boat you own, rent, or borrow.',
  },
}
