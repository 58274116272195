import { handleActions } from 'redux-actions'
import { compose } from 'recompose'
import { get, unset } from 'lodash/fp'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'savingsClub'
const slice = 'root.memberPortal.savingsClub'

const initialState = {}
const reducer = handleActions(
  {
    [actions.clearOfferDetail]: unsetState('offerDetail'),
    [actions.clearSavingsClubOffers]: unsetState('offers'),
    [actions.resetSearch]: (state) =>
      compose(
        unset('searchCriteria'),
        unset('searchResults'),
        unset('currentPage')
      )(state),

    [actions.setSearchCategory]: setState(
      'searchCriteria',
      ({ payload: category }, state) => {
        const currentSearchCriteria = get('searchCriteria', state)

        return { ...currentSearchCriteria, category }
      }
    ),

    [actions.setCurrentPage]: setState('currentPage', ({ payload: page }) =>
      Number(page)
    ),
    [actions.setSearchCriteria]: setState('searchCriteria'),

    [apiActions.fetchOfferDetail]: setOnSuccess('offerDetail'),
    [apiActions.fetchRandomOffers]: setOnSuccess('offers'),
    [apiActions.searchSavingsClub]: setOnSuccess('searchResults'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentPage: select('currentPage'),
  offerDetail: select('offerDetail'),
  offers: select('offers'),
  searchCriteria: select('searchCriteria'),
  searchResults: select('searchResults'),
}

export { reducer, selectors, reducerKey }
