import { handleActions } from 'redux-actions'
// import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
// import * as apiActions from 'api-actions'

const reducerKey = 'referral'
// const slice = 'root.referral'

const initialState = {}

const reducer = handleActions(
  {
    // [apiActions.fetchReferrals]: setOnSuccess('referrals'),
    // [apiActions.fetchReferral]: setOnSuccess('referral'),
    // [actions.clearReferral]: unsetState('referral'),
  },
  initialState
)

// const select = selectorForSlice(slice)

const selectors = {
  // referrals: select('referrals'),
  // referral: select('referral'),
}

export { reducer, selectors, reducerKey }
