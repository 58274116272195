import { createAction } from 'redux-actions'

// Product Prices ---------------------------
export const clearMembershipPrices = createAction('CLEAR_MEMBERSHIP_PRICES')
export const clearTrailerCarePrices = createAction('CLEAR_TRAILER_CARE_PRICES')
export function clearProductPrices() {
  return function (dispatch) {
    dispatch(clearMembershipPrices())
    dispatch(clearTrailerCarePrices())
  }
}

// *TODO: Using Test Variant as placeholder until we hear back from the Sea Tow
// Marketing Team what the terminology should be.
// Test Variant --------------------------------
export const setTestVariant = createAction('SET_TEST_VARIANT')
// clear state and LS
export const clearTestVariant = createAction('CLEAR_TEST_VARIANT')
// clear LS
export const clearTestVariantLS = createAction('CLEAR_TEST_VARIANT_LS')
// clear both
export function clearTestVariantAndLS() {
  return function (dispatch) {
    dispatch(clearTestVariant())
    dispatch(clearTestVariantLS())
  }
}

// Referral Id --------------------------------
export const setReferralInfo = createAction('SET_REFERRAL_INFO')
// clear both state and LS
export const clearReferralInfo = createAction('CLEAR_REFERRAL_INFO')

// Discount Codes ---------------------------
export const setActivationCode = createAction('SET_ACTIVATION_CODE')
export const setPromoCode = createAction('SET_PROMO_CODE')
// clear renewal details
export const clearMembershipDiscountDetails = createAction(
  'CLEAR_MEMBERSHIP_DISCOUNT_DETAILS',
)
// clear state
export const clearDiscountCodeState = createAction('CLEAR_DISCOUNT_CODE_STATE')
// clear LS
export const clearDiscountCodeLS = createAction('CLEAR_DISCOUNT_CODE_LS')
// clear all
export function clearDiscountCodeStateAndLS() {
  return function (dispatch) {
    dispatch(clearDiscountCodeState())
    dispatch(clearDiscountCodeLS())
    dispatch(clearMembershipDiscountDetails())
  }
}

// Google Ads -------------------------------
export const setGCLid = createAction('SET_GCLID')

// clear state and LS
export const clearGCLid = createAction('CLEAR_GCLID')
// clear LS
export const clearGCLidLS = createAction('CLEAR_GCLID_LS')
// clear both
export function clearGCLidAndLS() {
  return function (dispatch) {
    dispatch(clearGCLid())
    dispatch(clearGCLidLS())
  }
}

// Bing Ads (clear LS)-------------------------------
export const clearMSClickIdLS = createAction('CLEAR_MS_CLICK_ID_LS')

// Membership Selections ---------------------------
export const setSelectedMembershipType = createAction(
  'SET_SELECTED_MEMBERSHIP_TYPE',
)
export const setSelectedTrailerCareType = createAction(
  'SET_SELECTED_TRAILER_CARE_TYPE',
)

// clear state
export const clearMembershipSelectionsState = createAction(
  'CLEAR_MEMBERSHIP_SELECTIONS_STATE',
)
// clear LS
export const clearMembershipSelectionsLS = createAction(
  'CLEAR_MEMBERSHIP_SELECTIONS_LS',
)

// clear both membership selection and LS
export const clearMembershipSelectionAndLS = () => {
  return function (dispatch) {
    dispatch(clearMembershipSelectionsState())
    dispatch(clearMembershipSelectionsLS())
  }
}

// Set Application Id (initial account, contact, membership creation) ---------------------------
export const setApplicationAccountId = createAction(
  'SET_APPLICATION_ACCOUNT_ID',
)
export const setApplicationMembershipId = createAction(
  'SET_APPLICATION_MEMBERSHIP_ID',
)

// set state directly
export const setMembership = createAction('SET_MEMBERSHIP')
export const setTrialMembershipType = createAction('SET_TRIAL_MEMBERSHIP_TYPE')

// clear state
export const clearAccount = createAction('CLEAR_ACCOUNT')
export const clearContact = createAction('CLEAR_CONTACT')
export const clearMembership = createAction('CLEAR_MEMBERSHIP')
export const clearApplicationAccountId = createAction(
  'CLEAR_APPLICATION_ACCOUNT_ID',
)
export const clearApplicationMembershipId = createAction(
  'CLEAR_APPLICATION_MEMBERSHIP_ID',
)
export function clearApplicationAccountState() {
  return function (dispatch) {
    dispatch(clearAccount())
    dispatch(clearContact())
    dispatch(clearMembership())
    dispatch(clearApplicationAccountId())
    dispatch(clearApplicationMembershipId())
  }
}
// clear LS
export const clearApplicationAccountLS = createAction(
  'CLEAR_APPLICATION_ACCOUNT_LS',
)

// Payment Step ---------------------------
export const setBillingOptions = createAction('SET_BILLING_OPTIONS')
export const setReferredByMemberNumber = createAction('SET_REFERRED_BY')

export const clearBillingOptions = createAction('CLEAR_BILLING_OPTIONS')

// Reset ---------------------------
// clear out all application LS
// to be used within ExistingMemberApplicationAccountProvider as we
// only want to clear the LS shopping cart values, not state here
export function clearMemberApplicationLS() {
  return function (dispatch) {
    dispatch(clearApplicationAccountLS())
    dispatch(clearDiscountCodeLS())
    dispatch(clearGCLidLS())
    dispatch(clearMembershipSelectionsLS())
    dispatch(clearMSClickIdLS())
    dispatch(clearTestVariantLS())
  }
}

// clear out all application LS and shopping cart state
// to be used on registration after final member application charge,
// on start of renewal/upgrade flows (membership selection step) and
// on completion or unmount of renewal/upgrade flows
// to reduce risk of carry over to New Member flow
export function clearShoppingCart() {
  return function (dispatch) {
    dispatch(clearBillingOptions())
    dispatch(clearMembershipSelectionsState())
    dispatch(clearDiscountCodeState())
    dispatch(clearGCLid())
    dispatch(clearMemberApplicationLS())
    dispatch(clearMSClickIdLS())
    dispatch(clearTestVariant())
    dispatch(clearReferralInfo())
  }
}

// at the start of renewal/upgrade flows, set flag to true to reset shopping cart
// values to match the current membership/trailer care values on the membership,
// set to false after reset
export const setResetShoppingCart = createAction('SET_RESET_SHOPPING_CART')
