import React from 'react'
import PropTypes from 'prop-types'
import { API_KEY_MAP, AccountType, MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { subDays } from 'date-fns'
import { SectionHeader } from 'components'
import { MembershipPageHeader } from 'memberships/components'
import { NextInvoice } from '../components'
import { PaymentMethodEditForm } from '../forms'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import {
  AUTORENEW_BEFORE_EXPIRATION_DAYS,
  PATH,
  makeMembershipIdPath,
} from 'config'
import {
  apiValuesWithMappedKeys,
  formatISODate,
  handleSubmitFailWithFlashMessage,
  parseISODate,
  withApiAuth,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  membership: MembershipType.isRequired,
  enrollAutoRenewal: PropTypes.func.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
}

function AutoRenewEnrollment({
  account,
  enrollAutoRenewal,
  fetchAllMembershipsForAccount,
  membership,
}) {
  const {
    communication_member_renewal_amount__c: renewalCost,
    sfid: membershipId,
    membership_expiration_date__c: membershipExpirationDateString,
    account__r__heroku_external_id__c,
    membership_contact__r__heroku_external_id__c: contactId,
    heroku_external_id__c,
  } = membership
  const membershipExpirationDate = parseISODate(membershipExpirationDateString)
  const autoRenewalDate = subDays(
    membershipExpirationDate,
    AUTORENEW_BEFORE_EXPIRATION_DAYS
  )
  const autoRenewalDateString = formatISODate(autoRenewalDate)
  const { sfid: accountId } = account
  const history = useHistory()

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Billing"
        backLinkTo={makeMembershipIdPath(membershipId, PATH.BILLING)}
        membership={membership}
      />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader
                sectionHeaderName="Enroll in Auto Renewal"
                sectionDescription="Auto Renewal gives you peace of mind that your membership won’t expire."
              />
              <div className="group-block">
                <NextInvoice
                  autorenews
                  renewalCost={renewalCost}
                  invoiceTermDate={autoRenewalDateString}
                  withInvoiceTerms
                />
                <hr />
              </div>
              <PaymentMethodEditForm
                onSubmit={(autoRenewFormValues) => {
                  const autoRenewApiValues = apiValuesWithMappedKeys(
                    autoRenewFormValues,
                    API_KEY_MAP.CREDIT_CARD_KEY_MAP
                  )

                  return enrollAutoRenewal({
                    ...autoRenewApiValues,
                    amount: renewalCost,
                    arb_start_date: autoRenewalDateString,
                    membershipid: membershipId,
                    account__r__heroku_external_id__c,
                    contact__r__heroku_external_id__c: contactId,
                    membership__r__heroku_external_id__c: heroku_external_id__c,
                  })
                }}
                onSubmitSuccess={async () => {
                  await fetchAllMembershipsForAccount(accountId)
                  history.replace(
                    makeMembershipIdPath(membershipId, PATH.BILLING)
                  )
                }}
                onSubmitFail={handleSubmitFailWithFlashMessage}
                renewalDateString={autoRenewalDateString}
                submitButtonText="Submit"
                withTerms
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

AutoRenewEnrollment.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  enrollAutoRenewal: apiActions.enrollAutoRenewal,
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AutoRenewEnrollment)
