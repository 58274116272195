import React from 'react'
import PropTypes from 'prop-types'
import {
  SavingsClubOfferType,
  OFFER_LOCALITY,
  OFFER_REDEMPTION_TYPE,
} from 'types'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'
import { Tooltip } from 'react-tooltip'
import { makeMemberPortalPath, PATH, QUERY_KEY } from 'config'
import { formatDistance, getOfferTypeDescription, makeQueryPath } from 'utils'
import { OfferCategoryIcon } from 'components'

const propTypes = {
  country: PropTypes.string.isRequired,
  savingsClubOffer: SavingsClubOfferType.isRequired,
}

function OfferCard({ country, savingsClubOffer }) {
  const {
    company_logo__c: companyLogo,
    company_name__c: companyName,
    distance_from_zip: distance,
    featured__c: isFeatured,
    in_person_redemption__c: inPerson,
    locality_of_offer__c: locality,
    offer_description__c: offerDescription,
    online_redemption__c: online,
    primary_category__c: category,
    secondary_category__c: allSecondaryCategoriesString,
    sfid: offerId,
  } = savingsClubOffer

  return (
    <>
      <Link
        to={makeQueryPath(
          makeMemberPortalPath(PATH.SAVINGS_CLUB, PATH.OFFERS),
          {
            [QUERY_KEY.OFFER_ID]: offerId,
          }
        )}
      >
        <div className="card card-preview">
          {isFeatured && (
            <p className="featured-banner">
              <span>Featured</span>
            </p>
          )}
          <CategoryLabel
            {...{ allSecondaryCategoriesString, category, offerId }}
          />
          <h2 className="title">{companyName}</h2>
          {companyLogo ? (
            <div className="preview-image">
              <img src={companyLogo} />
            </div>
          ) : (
            <div className="preview-image">
              <OfferCategoryIcon category={category} />
            </div>
          )}
          <ul className="inline-list">
            {online && <li>{OFFER_REDEMPTION_TYPE.ONLINE}</li>}
            {inPerson && (
              <li>
                {OFFER_REDEMPTION_TYPE.IN_PERSON}
                {distance &&
                  locality === OFFER_LOCALITY.LOCAL &&
                  `- ${formatDistance(distance, country)} away`}
              </li>
            )}
          </ul>
          <span className="offer">
            {getOfferTypeDescription(savingsClubOffer)}
          </span>
          <p>{offerDescription}</p>
        </div>
      </Link>
    </>
  )
}

OfferCard.propTypes = propTypes

export default OfferCard

function additionalCategoriesFromString(secondaryCategoriesString) {
  if (isNil(secondaryCategoriesString)) return []

  return secondaryCategoriesString.split(';')
}

function tooltipFromAdditionalCategories(additionalCategories) {
  if (additionalCategories.length === 0) return ''

  return additionalCategories.join('<br />')
}

function CategoryLabel({ allSecondaryCategoriesString, category, offerId }) {
  const additionalCategories = additionalCategoriesFromString(
    allSecondaryCategoriesString
  )
  const totalAdditionalCategories = additionalCategories.length
  const additionalCategoriesSummary =
    totalAdditionalCategories > 0 ? ` + ${totalAdditionalCategories}` : ''
  const categoryTooltip = tooltipFromAdditionalCategories(additionalCategories)
  const tooltipId = `offer-category-${offerId}}`

  return (
    <>
      <p data-tooltip-id={tooltipId} data-tooltip-content={categoryTooltip}>
        {category}
        {additionalCategoriesSummary}
      </p>
      <Tooltip id={tooltipId} effect="solid" multiline />
    </>
  )
}
