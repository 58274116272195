import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Spinner } from 'lp-components'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { MembershipType } from 'types'
import { IMAGE_PATH, SEATOW_PHONE_DIGITS } from 'config'
import { formatUSMonthDayYearDateString, parseQuery, withApiAuth } from 'utils'

const propTypes = {
  clearMembershipAndAor: PropTypes.func.isRequired,
  fetchMembershipAndAor: PropTypes.func.isRequired,
  isMonthlyAccountType: PropTypes.bool,
  membershipAndAor: MembershipType,
}

function MembershipCardViewPrint({
  clearMembershipAndAor,
  fetchMembershipAndAor,
  isMonthlyAccountType,
  membershipAndAor,
}) {
  const { search } = useLocation()
  const { membershipId } = parseQuery(search)

  useEffect(() => {
    fetchMembershipAndAor(membershipId)

    return () => clearMembershipAndAor()
  }, [])

  if (!membershipAndAor) return <Spinner />

  const { aor_details: aor, membership } = membershipAndAor
  const {
    account_name,
    membership_expiration_date__c,
    membership_number__c,
    membership_type__c,
  } = membership
  const { x24_hour_phone__c } = aor

  return (
    <section className="print-and-view">
      <div className="membership-card">
        <MembershipCardHeader />
        <div className="membership-card-content">
          <MemberCardBody
            cardExpiration={formatUSMonthDayYearDateString(
              membership_expiration_date__c
            )}
            memberName={account_name}
            memberNumber={membership_number__c}
            memberType={membership_type__c}
            showExpirationDate={!isMonthlyAccountType}
          />
          <MemberCardSidePanel aor24HourPhone={x24_hour_phone__c} />
        </div>
      </div>
      <Button
        className="B-primary printer-left-button-icon"
        onClick={() => window.print()}
      >
        Print Page
      </Button>
    </section>
  )
}

MembershipCardViewPrint.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isMonthlyAccountType: globalSelectors.isMonthlyAccountType(state),
    membershipAndAor: selectors.membershipAndAor(state),
  }
}

const mapDispatchToProps = {
  clearMembershipAndAor: actions.clearMembershipAndAor,
}

const mapApiAuthToProps = {
  fetchMembershipAndAor: apiActions.fetchMembershipAndAor,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(MembershipCardViewPrint)

function MembershipCardHeader() {
  return <img src={IMAGE_PATH.SEATOW_MEMBER_CARD_LOGO} alt="Sea Tow" />
}

function MemberCardBody({
  cardExpiration,
  memberName,
  memberNumber,
  memberType,
  showExpirationDate,
}) {
  return (
    <div className="member-card-body">
      <p className="membership-type">{memberType}</p>
      <p className="membership-number">
        Membership #<span>{memberNumber}</span>
      </p>
      {showExpirationDate && (
        <div className="label-and-value">
          <p className="label">Valid Thru</p>
          <p className="value">{cardExpiration}</p>
        </div>
      )}
      <p className="name">{memberName}</p>
    </div>
  )
}

function MemberCardSidePanel({ aor24HourPhone }) {
  return (
    <div className="member-card-sidepanel">
      <div className="label-and-value">
        <p className="label">24 HR. Call Center</p>
        <p className="value">{SEATOW_PHONE_DIGITS}</p>
      </div>
      <div className="label-and-value">
        <p className="label">Local Dispatch</p>
        <p className="value">{aor24HourPhone}</p>
      </div>
      <p className="emergency-text">
        In an emergency
        <br /> always contact
        <br /> the U.S. Coast
        <br /> Guard first
        <br /> on VHF 16
      </p>
    </div>
  )
}
