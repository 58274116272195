import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProductPrices } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import * as apiActions from 'api-actions'
import { Spinner } from 'lp-components'

const propTypes = {
  children: PropTypes.node.isRequired,
  clearProductPrices: PropTypes.func.isRequired,
  fetchProductPrices: PropTypes.func.isRequired,
  membershipPrices: ProductPrices,
  trailerCarePrices: ProductPrices,
}

function ProductPricesProvider({
  membershipPrices,
  trailerCarePrices,
  fetchProductPrices,
  clearProductPrices,
  children,
}) {
  const hasLoadedPrices = !!(membershipPrices && trailerCarePrices)

  useEffect(() => {
    fetchProductPrices()

    return () => clearProductPrices()
  }, [])

  if (!hasLoadedPrices) return <Spinner />

  return children
}

ProductPricesProvider.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membershipPrices: memberSelectors.membershipPrices(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {
  clearProductPrices: memberActions.clearProductPrices,
  fetchProductPrices: apiActions.fetchProductPrices,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProductPricesProvider
)
