import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GiftPaymentForm } from '../forms'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { COUNTRY, PATH, makeGiftStepsPath } from 'config'
import {
  DigitalRecipientType,
  GiftContactType,
  MailedRecipientContactType,
} from 'types'
import { handleSubmitFailWithFlashMessage } from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  billingContact: GiftContactType,
  deliveryMethod: PropTypes.string.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  mailedToGifter: PropTypes.bool.isRequired,
  recipientContact: PropTypes.oneOfType([
    DigitalRecipientType,
    MailedRecipientContactType,
  ]).isRequired,
  setBillingContact: PropTypes.func.isRequired,
  setCreditCard: PropTypes.func.isRequired,
}

const DEFAULT_BILLING_CONTACT = {
  billingSameAsShipping: true,
  country: COUNTRY.UNITED_STATES,
}

function Payment({
  autoCompleteAddress,
  billingContact,
  deliveryMethod,
  fetchPlaceDetails,
  mailedToGifter,
  recipientContact,
  setBillingContact,
  setCreditCard,
}) {
  const history = useHistory()

  return (
    <>
      <GiftPaymentForm
        {...{
          autoCompleteAddress,
          deliveryMethod,
          fetchPlaceDetails,
          mailedToGifter,
          recipientContact,
        }}
        initialValues={{
          billingContact: billingContact ?? DEFAULT_BILLING_CONTACT,
        }}
        onSubmit={({ creditCard, billingContact }) => {
          setCreditCard(creditCard)
          setBillingContact(billingContact)
        }}
        onSubmitFail={handleSubmitFailWithFlashMessage}
        onSubmitSuccess={() =>
          history.push(makeGiftStepsPath(PATH.REVIEW_ORDER))
        }
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    billingContact: selectors.billingContact(state),
    deliveryMethod: selectors.deliveryMethod(state),
    mailedToGifter: selectors.mailedToGifter(state),
    recipientContact: selectors.recipientContact(state),
  }
}

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
  fetchPlaceDetails: apiActions.fetchPlaceDetails,
  setBillingContact: actions.setBillingContact,
  setCreditCard: actions.setCreditCard,
}

Payment.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(Payment)
