import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'membershipCardViewPrint'
const slice = 'root.memberPortal.membershipCardViewPrint'
const initialState = {}
const reducer = handleActions(
  {
    [actions.clearMembershipAndAor]: unsetState('membershipAndAor'),

    [apiActions.fetchMembershipAndAor]: setOnSuccess('membershipAndAor'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  membershipAndAor: select('membershipAndAor'),
}

export { reducer, selectors, reducerKey }
