import React from 'react'
import PropTypes from 'prop-types'
import { TRAILER_CARE_DETAIL, TRAILER_CARE_TYPES, ProductPrices } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { TrailerCareAddOnButton } from 'member-portal/components'
import { formatCurrency } from 'utils'

const propTypes = {
  trailerCarePrices: ProductPrices.isRequired,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
}

function TrailerCareAddOn({
  trailerCarePrices,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
}) {
  return (
    <div className="group-block">
      <h3 className="group-title">Trailer Care Add-on</h3>
      <p>Built on generations of customer care when we’re needed most, Trailer Care™ by Sea Tow<sup>®</sup> is 24-hour roadside assistance for your trailer and your towing vehicle. Exclusive to Sea Tow Members, annual plans offer something to meet all your boat trailering needs.</p>
      <div className="flex-vertical add-on-buttons">
        {TRAILER_CARE_TYPES.map((type) => {
          const details = TRAILER_CARE_DETAIL[type]
          return (
            <TrailerCareAddOnButton
              key={type}
              trailerCareType={details?.marketingTitle || type}
              isSelected={type === selectedTrailerCareType}
              handleClick={() => setSelectedTrailerCareType(type)}
            >
              <div className="product-details">
                <span className="radio"></span>
                <div className="text-block">
                  <h3>{details?.marketingTitle || type}</h3>
                  {details && (
                  <ul>
                    <li><em>Up to {formatCurrency(details.coverageLimit, 0)} per
                    incident</em></li>
                  </ul>
                  )}
                </div>
                {details && (
                <div className="price-block">
                  <p className="price">
                    <span>{formatCurrency(trailerCarePrices[type])}</span>
                    <span className="time"> / Year</span>
                  </p>
                </div>
                )}
              </div>
            </TrailerCareAddOnButton>
          )
        })}
      </div>
    </div>
  )
}

TrailerCareAddOn.propTypes = exact(propTypes)

export default pure(TrailerCareAddOn)
