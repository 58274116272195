import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Button } from 'lp-components'
import { ButtonLink } from 'components'
import { MEMBER_APPLICATION_QUESTION, MEMBERSHIP_TYPE } from 'types'

const propTypes = {
  postSelectionPath: PropTypes.string.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
}

function MembershipQuestionnaire({
  postSelectionPath,
  setSelectedMembershipType,
}) {
  const [currentQuestion, setCurrentQuestion] = useState(
    MEMBER_APPLICATION_QUESTION.COMMERCIAL_OR_RECREATIONAL
  )

  return (
    <div className="form-block">
      {currentQuestion ===
        MEMBER_APPLICATION_QUESTION.COMMERCIAL_OR_RECREATIONAL && (
        <CommercialOrRecreationalQuestion
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
      {currentQuestion === MEMBER_APPLICATION_QUESTION.PROFESSIONAL && (
        <ProfessionalQuestion
          postSelectionPath={postSelectionPath}
          setSelectedMembershipType={setSelectedMembershipType}
        />
      )}
      {currentQuestion === MEMBER_APPLICATION_QUESTION.RECREATIONAL && (
        <RecreationalQuestion
          postSelectionPath={postSelectionPath}
          setCurrentQuestion={setCurrentQuestion}
          setSelectedMembershipType={setSelectedMembershipType}
        />
      )}
      {currentQuestion === MEMBER_APPLICATION_QUESTION.LAKE && (
        <LakeQuestion
          postSelectionPath={postSelectionPath}
          setSelectedMembershipType={setSelectedMembershipType}
        />
      )}
    </div>
  )
}

MembershipQuestionnaire.propTypes = exact(propTypes)

export default pure(MembershipQuestionnaire)

function CommercialOrRecreationalQuestion({ setCurrentQuestion }) {
  return (
    <>
      <h2>Do you ever use your boat(s) for any of the following:</h2>
      <ul className="bullets">
        <li>Charters</li>
        <li>Rent it Out</li>
        <li>Have someone pay you to use your boat</li>
        <li>Are paid to operate the boat</li>
      </ul>
      <div className="icon-button-group">
        <Button
          onClick={() =>
            setCurrentQuestion(MEMBER_APPLICATION_QUESTION.PROFESSIONAL)
          }
          className="icon-button"
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Yes.svg"
            alt=""
          />
          Yes
        </Button>
        <Button
          onClick={() =>
            setCurrentQuestion(MEMBER_APPLICATION_QUESTION.RECREATIONAL)
          }
          className="icon-button"
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_No.svg"
            alt=""
          />
          No
        </Button>
      </div>
    </>
  )
}

function ProfessionalQuestion({
  postSelectionPath,
  setSelectedMembershipType,
}) {
  return (
    <>
      <h2>
        Do you want membership for a single boat, or service for you on any
        vessel you operate?
      </h2>
      <div className="icon-button-group">
        <ContinueButton
          onClick={() =>
            setSelectedMembershipType(MEMBERSHIP_TYPE.COMMERCIAL_CARD)
          }
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Prim-Boat.svg"
            alt=""
          />
          Single Vessel
        </ContinueButton>
        <ContinueButton
          onClick={() =>
            setSelectedMembershipType(MEMBERSHIP_TYPE.PROFESSIONAL_MARINER_CARD)
          }
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Multiple-Boats.svg"
            alt=""
          />
          Me on Multiple Vessels
        </ContinueButton>
      </div>
    </>
  )
}

function RecreationalQuestion({
  postSelectionPath,
  setCurrentQuestion,
  setSelectedMembershipType,
}) {
  return (
    <>
      <h2>Throughout the year what type of water do you boat in?</h2>
      <div className="icon-button-group">
        <ContinueButton
          onClick={() => setSelectedMembershipType(MEMBERSHIP_TYPE.GOLD_CARD)}
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Salt.svg"
            alt=""
          />
          Salt
        </ContinueButton>
        <Button
          onClick={() => setCurrentQuestion(MEMBER_APPLICATION_QUESTION.LAKE)}
          className="icon-button"
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Fresh.svg"
            alt=""
          />
          Fresh
        </Button>
        <ContinueButton
          onClick={() => setSelectedMembershipType(MEMBERSHIP_TYPE.GOLD_CARD)}
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Salt-and-Fresh.svg"
            alt=""
          />
          Both
        </ContinueButton>
      </div>
    </>
  )
}

function LakeQuestion({ postSelectionPath, setSelectedMembershipType }) {
  return (
    <>
      <h2>
        Do you boat in Florida, Alaska, Hawaii, Puerto Rico, Virgin Islands or
        on an inland lake or river that flows to the coast (Eg. Hudson or
        Delaware Rivers)?
      </h2>
      <div className="icon-button-group">
        <ContinueButton
          onClick={() => setSelectedMembershipType(MEMBERSHIP_TYPE.GOLD_CARD)}
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Yes.svg"
            alt=""
          />
          Yes
        </ContinueButton>
        <ContinueButton
          onClick={() => setSelectedMembershipType(MEMBERSHIP_TYPE.LAKE_CARD)}
          postSelectionPath={postSelectionPath}
        >
          <img
            src="/assets/images/icons/with-background/ST_YIcon_No.svg"
            alt=""
          />
          No
        </ContinueButton>
      </div>
    </>
  )
}

function ContinueButton({ onClick, postSelectionPath, children }) {
  return (
    <ButtonLink
      to={postSelectionPath}
      onClick={onClick}
      className="button-primary icon-button"
    >
      {children}
    </ButtonLink>
  )
}
