import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  name: PropTypes.string,
  state: PropTypes.string.isRequired,
}

function HomePortAddress({ address, city, country, name, state }) {
  return (
    <div className="address">
      <p>
        {name && (
          <>
            {name}
            <br />
          </>
        )}
        {address && `${address}, `}
        {`${city}, ${state}, ${country}`}
      </p>
    </div>
  )
}

HomePortAddress.propTypes = exact(propTypes)

export default pure(HomePortAddress)
