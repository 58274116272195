import React from 'react'
import { MembershipBoatSelection } from 'member-portal/components'
import {
  makeMemberApplicationPath,
  PATH,
  makeMemberApplicationApplyPath,
} from 'config'

const propTypes = {}

function NewMemberBoatSelection() {
  return (
    <MembershipBoatSelection
      nextBoatStep={makeMemberApplicationPath(PATH.BOATS, PATH.HOME_PORT_TYPE)}
      nextApplicationStep={makeMemberApplicationApplyPath(PATH.PAYMENT)}
    />
  )
}

NewMemberBoatSelection.propTypes = propTypes

export default NewMemberBoatSelection
