import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DigitalRecipient, MailedRecipient } from '../components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import {
  DigitalRecipientType,
  GIFT_DELIVERY_METHOD,
  MailedRecipientContactType,
} from 'types'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  giftMessage: PropTypes.string,
  giftWrapped: PropTypes.bool.isRequired,
  mailedToGifter: PropTypes.bool.isRequired,
  recipientContact: PropTypes.oneOfType([
    DigitalRecipientType,
    MailedRecipientContactType,
  ]),
  setGiftMessage: PropTypes.func.isRequired,
  setGiftWrapped: PropTypes.func.isRequired,
  setMailedToGifter: PropTypes.func.isRequired,
  setRecipientContact: PropTypes.func.isRequired,
  setShippingMethod: PropTypes.func.isRequired,
  shippingMethod: PropTypes.string,
}

function Recipient({
  autoCompleteAddress,
  deliveryMethod,
  fetchPlaceDetails,
  giftMessage,
  giftWrapped,
  mailedToGifter,
  recipientContact,
  setGiftMessage,
  setGiftWrapped,
  setMailedToGifter,
  setRecipientContact,
  setShippingMethod,
  shippingMethod,
}) {
  if (deliveryMethod === GIFT_DELIVERY_METHOD.DIGITAL) {
    return (
      <DigitalRecipient
        {...{
          giftMessage,
          recipientContact,
          setGiftMessage,
          setRecipientContact,
        }}
      />
    )
  }

  return (
    <MailedRecipient
      {...{
        autoCompleteAddress,
        fetchPlaceDetails,
        giftMessage,
        giftWrapped,
        mailedToGifter,
        recipientContact,
        setGiftMessage,
        setGiftWrapped,
        setMailedToGifter,
        setRecipientContact,
        setShippingMethod,
        shippingMethod,
      }}
    />
  )
}

function mapStateToProps(state) {
  return {
    deliveryMethod: selectors.deliveryMethod(state),
    giftMessage: selectors.giftMessage(state),
    giftWrapped: selectors.giftWrapped(state),
    mailedToGifter: selectors.mailedToGifter(state),
    recipientContact: selectors.recipientContact(state),
    shippingMethod: selectors.shippingMethod(state),
  }
}

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
  fetchPlaceDetails: apiActions.fetchPlaceDetails,
  setGiftMessage: actions.setGiftMessage,
  setGiftWrapped: actions.setGiftWrapped,
  setMailedToGifter: actions.setMailedToGifter,
  setRecipientContact: actions.setRecipientContact,
  setShippingMethod: actions.setShippingMethod,
}

Recipient.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(Recipient)
