import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'

const propTypes = {
  name: PropTypes.string.isRequired,
}

function MobilePushNotificationLabel({ name }) {
  return (
    <span>
      <label htmlFor={name}>
        I would like confirmations and reminders regarding my Sea Tow membership
        via push notifications to my mobile phone from the Sea Tow app, and
        occasional promotional outreach. View{' '}
        <ExternalLink
          className="external-link-in-text"
          href="https://seatow.com/terms/"
          showIcon={false}
        >
          Terms
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink
          className="external-link-in-text"
          href="https://seatow.com/privacy/"
          showIcon={false}
        >
          Privacy
        </ExternalLink>
        .
      </label>
    </span>
  )
}

MobilePushNotificationLabel.propTypes = propTypes

export default MobilePushNotificationLabel
