import { useContext } from 'react'
import { ConfirmContext } from 'contexts'

// Accesses the context from ConfirmContextProvider
// and adds a Promise to allow for confirm / cancel callbacks
function useConfirm() {
  const [confirm, setConfirm] = useContext(ConfirmContext)

  const isConfirmed = ({
    heading,
    prompt,
    confirmLabel,
    cancelLabel,
    ...rest
  }) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        heading,
        prompt,
        confirmLabel,
        cancelLabel,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        ...rest,
      })
    })
    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false })
        return true
      },
      () => {
        setConfirm({ ...confirm, isOpen: false })
        return false
      }
    )
  }
  return {
    ...confirm,
    isConfirmed,
  }
}

export default useConfirm
