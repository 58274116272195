import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'lp-components'
import { PageHeader, PartnerAccountSummary, SavingsClubOffer } from 'components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { makeMemberPortalPath, PATH, QUERY_KEY } from 'config'
import { SavingsClubOfferDetailType, SearchCriteriaType } from 'types'
import { makeQueryPath, parseQuery, withApiAuth } from 'utils'

const propTypes = {
  clearOfferDetail: PropTypes.func.isRequired,
  fetchOfferDetail: PropTypes.func.isRequired,
  offerDetail: SavingsClubOfferDetailType,
  searchCriteria: SearchCriteriaType.isRequired,
}

function SavingsClubOfferShow({
  clearOfferDetail,
  fetchOfferDetail,
  offerDetail,
  searchCriteria,
}) {
  const { search } = useLocation()
  const query = parseQuery(search)
  const offerId = query[QUERY_KEY.OFFER_ID]

  useEffect(() => {
    fetchOfferDetail(offerId)

    return () => clearOfferDetail()
  }, [offerId])

  if (!offerDetail) return <Spinner />

  const { account, offer, additional_offers } = offerDetail
  const { name } = account

  return (
    <>
      <PageHeader
        backLinkText="Back to Savings Club"
        backLinkTo={makeQueryPath(
          makeMemberPortalPath(PATH.SAVINGS_CLUB),
          searchCriteria
        )}
        headerText="Savings Club"
      />
      <div className="content-block-container">
        <div className="content-with-aside-wrapper">
          <aside>
            <PartnerAccountSummary account={account} />
          </aside>

          <div className="content-with-aside">
            <section>
              <SavingsClubOffer offer={offer} withFeaturedBadge />
            </section>

            {additional_offers.length > 0 && (
              <section>
                <h2>Additional Offers from "{name}"</h2>
                <ul className="horizonal-cards-list">
                  {additional_offers.map((additionalOffer) => {
                    const { id } = additionalOffer

                    return (
                      <li key={id}>
                        <SavingsClubOffer
                          offer={additionalOffer}
                          withFeaturedBadge
                        />
                      </li>
                    )
                  })}
                </ul>
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

SavingsClubOfferShow.propTypes = propTypes

function mapStateToProps(state) {
  return {
    offerDetail: selectors.offerDetail(state),
    searchCriteria: selectors.searchCriteria(state),
  }
}

const mapDispatchToProps = {
  clearOfferDetail: actions.clearOfferDetail,
}

const mapApiAuthToProps = {
  fetchOfferDetail: apiActions.fetchOfferDetail,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SavingsClubOfferShow)
