import React from 'react'
import { MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MembershipPageHeader } from 'memberships/components'
import { SearchAddressSection } from '../components'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as actions from '../actions'
import { PATH, makeMembershipIdPath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
}

function SearchPortAddress({ membership }) {
  const { sfid: membershipId } = membership

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Boat & Home Port Information"
        backLinkTo={makeMembershipIdPath(membershipId, PATH.BOATS)}
        membership={membership}
      />
      <SearchAddressSection
        nextStep={makeMembershipIdPath(
          membershipId,
          PATH.BOATS,
          PATH.HOME_PORT_LOCATION
        )}
        previousStep={makeMembershipIdPath(
          membershipId,
          PATH.BOATS,
          PATH.HOME_PORT_TYPE
        )}
      />
    </>
  )
}

SearchPortAddress.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  addHomePortType: actions.addHomePortType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchPortAddress
)
