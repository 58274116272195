import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from 'lp-components'
import { SearchResultsPanel } from 'components'
import {
  clearFields,
  Field,
  FormSection,
  initialize,
  untouch,
} from 'redux-form'
import { COUNTRY } from 'config'
import { debounce } from 'lodash'
import {
  formatSubpremise,
  getLocalityFromGooglePlace,
  isContact2Path,
  stateListFromCountry,
} from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  country: PropTypes.string,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  streetAddress: PropTypes.string,
}

function AddressFields({
  autoCompleteAddress,
  change,
  country,
  dispatch,
  fetchPlaceDetails,
  streetAddress,
}) {
  const [searchResults, updateSearchResults] = useState(null)
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false)
  const [manualEntryMode, setManualEntryMode] = useState(false)
  const [addressInPlace, setAddressInPlace] = useState(false)

  const closeResultsPanel = () => setResultsPanelOpen(false)
  const openResultsPanel = () => setResultsPanelOpen(true)

  const isContact2View = isContact2Path()

  const updateAddressValues = useCallback(async (searchResult) => {
    const googlePlace = await fetchPlaceDetails(searchResult.place_id)
    const {
      streetNumber,
      street,
      city,
      state,
      country,
      postalCode,
      subpremise,
    } = getLocalityFromGooglePlace(googlePlace)

    if (subpremise) {
      const formattedSubpremise = formatSubpremise(subpremise)
      change(
        'account.streetAddress',
        `${streetNumber} ${street}, ${formattedSubpremise}`,
      )
    } else {
      change('account.streetAddress', `${streetNumber} ${street}`)
    }
    change('account.city', city)
    change('account.state', state)
    change('account.country', country)
    change('account.postalCode', postalCode)
    setAddressInPlace(true)
  }, [])

  const handleManualEntry = () => {
    setManualEntryMode(true)
    setResultsPanelOpen(false)
    dispatch(untouch('MemberContactInfoForm', 'account.streetAddress'))
    dispatch(
      clearFields(
        'MemberContactInfoForm',
        false,
        false,
        'account.streetAddress',
      ),
    )

    dispatch(
      initialize(
        'MemberContactInfoForm',
        {
          'account.city': null,
          'account.state': null,
          'account.postalCode:': null,
        },
        true,
      ),
    )
  }

  useEffect(() => {
    if (streetAddress) {
      setAddressInPlace(true)
    }
  }, [streetAddress])

  return (
    <div>
      <FormSection name="account">
        <Field
          autoComplete="off"
          component={Input}
          id="address-search-input"
          label="Mailing Address"
          name="streetAddress"
          onChange={
            (manualEntryMode || addressInPlace) && isContact2View
              ? undefined
              : debounce(async (_, newSearchQuery) => {
                const normalizedSearchQuery = newSearchQuery
                  .split(' ')
                  .join('+')
                const placeSearchResult = await autoCompleteAddress(
                  normalizedSearchQuery,
                )

                updateSearchResults(placeSearchResult.results)
                openResultsPanel()
              }, 100)
          }
          placeholder={manualEntryMode ? '' : 'Start typing address'}
          required
          requiredIndicator="*"
        />
        {(!isContact2View || !manualEntryMode) && (
          <SearchResultsPanel
            closePanel={closeResultsPanel}
            hideLocationName={true}
            isPanelOpen={resultsPanelOpen}
            searchResults={searchResults}
            updateFormValues={updateAddressValues}
          />
        )}
        {(manualEntryMode || addressInPlace || !isContact2View) && (
          <>
            <div className="row">
              <div className="col-6">
                <Field
                  component={Select}
                  label="Country"
                  name="country"
                  onChange={() => change('state', '')}
                  options={Object.values(COUNTRY)}
                  placeholder="Country"
                  required
                  requiredIndicator="*"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Field
                  component={Input}
                  label="City"
                  name="city"
                  required
                  requiredIndicator="*"
                />
              </div>
              <div className="col-3">
                <Field
                  component={Select}
                  label="State"
                  name="state"
                  options={stateListFromCountry(country)}
                  placeholder="State"
                  required
                  requiredIndicator="*"
                />
              </div>
              <div className="col-3">
                <Field
                  component={Input}
                  label="Postal Code"
                  name="postalCode"
                  required
                  requiredIndicator="*"
                />
              </div>
            </div>
          </>
        )}
        {isContact2View && !(manualEntryMode || addressInPlace) && (
          <button
            className="link-secondary"
            onClick={handleManualEntry}
            type="button"
          >
            Enter Address Manually
          </button>
        )}
      </FormSection>
    </div>
  )
}

AddressFields.propTypes = propTypes

export default AddressFields
