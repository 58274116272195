import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Header, Footer, SkipNavLink, SiteMetadata } from 'components'
import { selectors as globalSelectors } from 'global-reducer'
import { ContactType } from 'types'
import {
  PROGRAMS_NAVIGATION_LINKS,
  SEATOW_PROGRAMS_CONTACT_EMAIL,
  SITE_METADATA,
} from 'config'
import { scrollToTop } from 'utils'

const propTypes = {
  primaryContact: ContactType.isRequired,
  children: PropTypes.node.isRequired,
}

function Layout({ primaryContact, children }) {
  const { pathname } = useLocation()
  const { metaTitle, description, keywords } = SITE_METADATA.PROGRAMS
  // Scroll to top of page when route changes
  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <div>
      <SiteMetadata
        metaTitle={metaTitle}
        description={description}
        keywords={keywords}
      />
      <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>
      <Header
        applicationLinks={PROGRAMS_NAVIGATION_LINKS}
        contactLink={`mailto:${SEATOW_PROGRAMS_CONTACT_EMAIL}`}
        primaryContact={primaryContact}
      />
      <main id="main-content">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = propTypes

function mapStateToProps(state) {
  return {
    primaryContact: globalSelectors.primaryContact(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
