import { OFFER_TYPE } from 'types'
import { formatCurrency } from 'utils'

export default function getOfferTypeDescription({
  dollars_off__c: dollarsOff,
  percent_off__c: percentOff,
  type_of_offer__c: offerType,
  type_of_offer_other__c: otherDescription,
}) {
  switch (offerType) {
    case OFFER_TYPE.DOLLARS_OFF:
      return `${formatCurrency(dollarsOff)} off`
    case OFFER_TYPE.PERCENTAGE_OFF:
      return `${percentOff}% off`
    case OFFER_TYPE.OTHER:
      return `${otherDescription}`
  }
}
