import React from 'react'
import { MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { MembershipBoatSelection } from 'member-portal/components'
import { makeMembershipIdPath, PATH, makePath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
}

function RenewalBoatSelection({ membership }) {
  const basePath = makeMembershipIdPath(membership.sfid, PATH.RENEW_MEMBERSHIP)

  return (
    <MembershipBoatSelection
      nextBoatStep={makePath(basePath, PATH.BOATS, PATH.HOME_PORT_TYPE)}
      nextApplicationStep={makePath(basePath, PATH.PAYMENT)}
    />
  )
}

RenewalBoatSelection.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalBoatSelection
)
