import { isAfter, parse } from 'date-fns'
import { parseISODate } from 'utils'

// Determines whether the the card expiration date is after the renewal date
const ccExpirationDateAfterProvidedDate = (
  providedDateString,
  cardExpirationDateString
) => {
  const providedDate = parseISODate(providedDateString)
  const cardExpirationDate = parse(
    cardExpirationDateString,
    'MM/yyyy',
    new Date()
  )

  return isAfter(cardExpirationDate, providedDate)
}

export default ccExpirationDateAfterProvidedDate
