import { combineReducers } from 'redux'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './global-reducer'
import {
  reducer as memberPortalReducer,
  reducerKey as memberPortalReducerKey,
} from './member-portal'
import {
  reducer as programsPortalReducer,
  reducerKey as programsPortalReducerKey,
} from './programs-portal'
import {
  reducer as seaTowReducer,
  reducerKey as seaTowReducerKey,
} from './sea-tow'
import {
  reducer as styleguideReducer,
  reducerKey as styleguideReducerKey,
} from './styleguide'

const reducerKey = 'root'

const reducer = combineReducers({
  [globalReducerKey]: globalReducer,
  [memberPortalReducerKey]: memberPortalReducer,
  [programsPortalReducerKey]: programsPortalReducer,
  [seaTowReducerKey]: seaTowReducer,
  [styleguideReducerKey]: styleguideReducer,
})

export { reducer, reducerKey }
