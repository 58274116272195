import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { HEROKU_ID_KEY, MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { stripNoneTrailerCareType } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  applicationAccountId: PropTypes.string,
  applicationMembershipId: PropTypes.string,
  membership: MembershipType,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string,
  shoppingCartMembershipType: PropTypes.string,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function MembershipUpdater({
  children,
  applicationAccountId,
  applicationMembershipId,
  membership,
  selectedMembershipType,
  selectedTrailerCareType,
  shoppingCartMembershipType,
  updateMembershipApplicationAccount,
}) {
  const selectedTrailerCareTypeForCart = stripNoneTrailerCareType(
    selectedTrailerCareType
  )

  const membershipExists = !!membership
  const cartMembershipType = membership?.shopping_cart_membership_type__c
  const cartTrailerCareType = membership?.shopping_cart_trailer_care_type__c

  const membershipNeedsUpdating = useMemo(() => {
    if (!membershipExists) return false

    // When the membership has already been created,
    // make sure it's kept up to date with any selection changes
    if (
      cartMembershipType !== selectedMembershipType ||
      cartTrailerCareType !== selectedTrailerCareTypeForCart
    )
      return true

    return false
  }, [
    membershipExists,
    cartMembershipType,
    cartTrailerCareType,
    selectedMembershipType,
    selectedTrailerCareTypeForCart,
  ])

  useEffect(() => {
    if (membershipNeedsUpdating) {
      updateMembershipApplicationAccount({
        account: {
          [HEROKU_ID_KEY]: applicationAccountId,
        },
        membership: {
          [HEROKU_ID_KEY]: applicationMembershipId,
          shopping_cart_membership_type__c: shoppingCartMembershipType,
          shopping_cart_trailer_care_type__c: selectedTrailerCareTypeForCart,
        },
      })
    }
  }, [applicationAccountId, applicationMembershipId, membershipNeedsUpdating])

  return children
}

MembershipUpdater.propTypes = propTypes

function mapStateToProps(state) {
  return {
    applicationAccountId: memberSelectors.applicationAccountId(state),
    applicationMembershipId: memberSelectors.applicationMembershipId(state),
    membership: memberSelectors.membership(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    shoppingCartMembershipType: memberSelectors.shoppingCartMembershipType(
      state
    ),
  }
}

const mapDispatchToProps = {
  updateMembershipApplicationAccount:
    apiActions.updateMembershipApplicationAccount,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MembershipUpdater
)
