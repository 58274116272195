import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Helmet } from 'react-helmet'
import { IMAGE_PATH, isProduction } from 'config'
import { buildSiteUrl } from 'utils'

const propTypes = {
  canonicalUrlPath: PropTypes.string,
  description: PropTypes.string,
  metaTitle: PropTypes.string.isRequired,
  keywords: PropTypes.string,
}

function SiteMetadata({
  canonicalUrlPath,
  description = 'Boat towing and on-water service with 24/7 assistance from expert Captains. Sea Tow offers free towing for members. Salvage and boat recovery. Call 800-4-SEATOW.',
  metaTitle,
  keywords,
}) {
  const url = canonicalUrlPath
    ? buildSiteUrl(canonicalUrlPath)
    : window.location.href

  return (
    <Helmet defer={false}>
      <title>{metaTitle}</title>
      {isProduction() && (
        <script
          type="text/javascript"
          src="//cdn-4.convertexperiments.com/v1/js/10041340-10041380.js"
        />
      )}

      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={url} />

      {/* OpenGraph Metadata */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={IMAGE_PATH.SEATOW_LOGO} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
    </Helmet>
  )
}

SiteMetadata.propTypes = exact(propTypes)

export default pure(SiteMetadata)
