import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isNil } from 'lodash'
import classnames from 'classnames'
import { ExternalLink } from 'components'
import { AorType } from 'types'
import { IMAGE_PATH } from 'config'

const propTypes = {
  aor: AorType.isRequired,
  headingText: PropTypes.string,
}

function Aor({ aor, headingText }) {
  const {
    aor_description__c,
    aor_email__c,
    aor_home_page_url__c,
    facebook__c,
    instagram__c,
    linkedin__c,
    owner_photo_url__c,
    website_local_contact_display__c,
    x24_hour_phone__c,
    youtube__c,
  } = aor

  return (
    <>
      <div className="image-block">
        <img
          className={classnames({ placeholder: isNil(owner_photo_url__c) })}
          alt=""
          src={owner_photo_url__c ?? IMAGE_PATH.DEFAULT_AOR_IMAGE}
        />
      </div>
      <h3>
        {website_local_contact_display__c ?? 'Your Local Sea Tow Captain'}
      </h3>
      {headingText ? <h3>{headingText}</h3> : <div />}
      <ExternalLink href={aor_home_page_url__c}>
        Sea Tow {aor_description__c}
      </ExternalLink>
      <a href={`tel:${x24_hour_phone__c}`} className="link-small">
        {x24_hour_phone__c}
      </a>
      <a href={`mailto:${aor_email__c}`} className="link-small">
        {aor_email__c}
      </a>

      <ul className="social-links">
        {instagram__c && (
          <li>
            <ExternalLink href={instagram__c} aria-label="Instagram">
              <img src="/assets/images/social-icons/instagram.svg" alt="" />
            </ExternalLink>
          </li>
        )}
        {facebook__c && (
          <li>
            <ExternalLink href={facebook__c} aria-label="Facebook">
              <img src="/assets/images/social-icons/facebook.svg" alt="" />
            </ExternalLink>
          </li>
        )}
        {linkedin__c && (
          <li>
            <ExternalLink href={linkedin__c} aria-label="LinkedIn">
              <img src="/assets/images/social-icons/linkedin.svg" alt="" />
            </ExternalLink>
          </li>
        )}
        {youtube__c && (
          <li>
            <ExternalLink href={youtube__c} aria-label="YouTube">
              <img src="/assets/images/social-icons/youtube.svg" alt="" />
            </ExternalLink>
          </li>
        )}
      </ul>
    </>
  )
}

Aor.propTypes = exact(propTypes)

export default pure(Aor)
