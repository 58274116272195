function calculateDiscountDollarsOff(originalCost, discountCodeDetails) {
  if (!discountCodeDetails) return 0

  const { dollarsOff, percentOff } = discountCodeDetails
  if (dollarsOff) return dollarsOff
  if (percentOff) return originalCost * (percentOff / 100)
  return 0
}

export default calculateDiscountDollarsOff
