import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'

const propTypes = {
  trailerCareType: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

function TrailerCareAddOnButton({
  trailerCareType,
  isSelected,
  handleClick,
  children,
}) {
  return (
    <button
      className={classnames('button-primary-outline', {
        selected: isSelected,
      })}
      onClick={handleClick}
      aria-label={`Add ${trailerCareType}`}
    >
      {children}
    </button>
  )
}

TrailerCareAddOnButton.propTypes = exact(propTypes)

export default pure(TrailerCareAddOnButton)
