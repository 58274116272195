import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { HOME_PORT_TYPE } from 'types'
import { SectionHeader } from 'components'
import { ProgressSteps } from '../components'
import * as apiActions from 'api-actions'
import { SearchPortAddressForm } from '../forms'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
  previousStep: PropTypes.string.isRequired,
}

function SearchAddressSection({ autoCompleteAddress, nextStep, previousStep }) {
  const history = useHistory()
  const location = useLocation()
  const { homePortName, homePortType } = location.state.portWizardData
  const helperText = helperTextByHomePortType(homePortType)

  return (
    <>
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <ProgressSteps activeStep={2} totalSteps={3} />
              <SectionHeader
                sectionHeaderName={headerTextByHomePortType(homePortType)}
                sectionDescription={`After your search, you'll be shown a map where you can adjust the pin to your extact home port location.`}
              />
              {helperText && (
                <p>
                  <em>{helperText}</em>
                </p>
              )}
              <SearchPortAddressForm
                autoCompleteAddress={autoCompleteAddress}
                initialValues={{ homePortName }}
                onSubmit={({ homePortLocation, homePortName }) => {
                  const homePortLocality = getSearchAddressFromGooglePlace(
                    homePortLocation
                  )
                  const newLocation = {
                    pathname: nextStep,
                    state: {
                      portWizardData: {
                        ...location.state.portWizardData,
                        homePortName,
                        homePortLocality,
                      },
                      formData: location.state.formData,
                      returnUrl: location.state.returnUrl,
                      boat: location.state.boat,
                    },
                  }
                  history.push(newLocation)
                }}
                previousFormStep={{
                  pathname: previousStep,
                  state: {
                    portWizardData: {},
                    formData: location.state.formData,
                    returnUrl: location.state.returnUrl,
                    boat: location.state.boat,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

SearchAddressSection.propTypes = propTypes

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
}

export default compose(connect(null, mapDispatchToProps))(SearchAddressSection)

const getSearchAddressFromGooglePlace = (googlePlace) => {
  if (!googlePlace) return {}
  const {
    formatted_address,
    geometry: {
      location: { lat, lng },
    },
    types,
  } = googlePlace
  return { formatted_address, lat, lng, types }
}

const headerTextByHomePortType = (homePortType) => {
  switch (homePortType) {
    case HOME_PORT_TYPE.MARINA:
      return 'Search Marina Name or Address'
    case HOME_PORT_TYPE.TRAILER:
      return 'Name of Trailer Ramp You Use a Majority of the Time'
    case HOME_PORT_TYPE.MOORING:
      return 'Search Mooring Body of Water or Facility'
    case HOME_PORT_TYPE.HOME_DOCK:
      return 'Address of Home Dock'
    case HOME_PORT_TYPE.OTHER:
      return 'Search for Name of Marina, Facility, or Body of Water'
    default:
      return 'Invalid Homeport Type'
  }
}

const helperTextByHomePortType = (homePortType) => {
  switch (homePortType) {
    case HOME_PORT_TYPE.TRAILER:
      return 'Search Tip:  Enter the full name including “ramp” or “launch” to find the exact location'
  }
}
