import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { PATH } from 'config'

const propTypes = {}

function Routes() {
  return (
    <Layout>
      <Switch>
        <Route path={PATH.CLIENT_ERROR}>
          <Views.ClientError />
        </Route>
        <Route path={PATH.SERVER_ERROR}>
          <Views.ServerError />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes

export default Routes
