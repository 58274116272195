import React from 'react'
import PropTypes from 'prop-types'
import { ACCOUNT_DETAIL_TYPE, isMemberBusinessAccountType } from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, Input, MaskedInput, Checkbox } from 'lp-components'
import {
  Field,
  formValues,
  FormSection,
  propTypes as formPropTypes,
} from 'redux-form'
import { AUTOCOMPLETE_ADDRESS_FORM_VALIDATOR, PHONE_REGION_CODE } from 'config'
import { parsePhoneNumber } from 'utils'
import AddressFields from '../member_application/components/AddressFields'

const propTypes = {
  ...formPropTypes,
  accountDetailType: PropTypes.string,
  autoCompleteAddress: PropTypes.func.isRequired,
  country: PropTypes.string,
  fetchPlaceDetails: PropTypes.func.isRequired,
}

function MemberContactInfoForm({
  accountDetailType,
  autoCompleteAddress,
  change,
  country,
  dispatch,
  fetchPlaceDetails,
  handleSubmit,
  initialValues,
  submitting,
}) {
  const isBusinessAccount = isMemberBusinessAccountType(accountDetailType)
  const { account: { streetAddress } = {} } = initialValues || {}

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormSection name="account">
        <p>
          Your contact info should match the name on your boat registration.
        </p>
        <Field
          component={Checkbox}
          label="Is this a business account?"
          name="accountDetailType"
          format={() => isBusinessAccount}
          parse={(checked) => {
            if (checked) return ACCOUNT_DETAIL_TYPE.CUSTOMER_BUSINESS
            return ACCOUNT_DETAIL_TYPE.CUSTOMER_RETAIL
          }}
          onChange={() => change('account.businessName', null)}
        />
        {isBusinessAccount && (
          <Field
            component={Input}
            label="Business Name"
            name="businessName"
            required
            requiredIndicator="*"
          />
        )}
      </FormSection>
      <FormSection name="contact">
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="First Name"
              name="firstName"
              required
              requiredIndicator="*"
            />
          </div>
          <div className={'col-6'}>
            <Field
              component={Input}
              label="Last Name"
              name="lastName"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
        <Field
          component={Input}
          label="Email"
          name="email"
          required
          requiredIndicator="*"
        />
        <Field
          component={MaskedInput}
          label="Mobile Phone Number"
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 0, 3, 4],
            delimiters: ['(', ')', ' ', '-'],
            phoneRegionCode: PHONE_REGION_CODE[country],
          }}
          name="mobilePhone"
          parse={parsePhoneNumber}
          placeholder="(800) 555-1212"
          required
          requiredIndicator="*"
        />
      </FormSection>
      <AddressFields
        {...{
          autoCompleteAddress,
          change,
          country,
          dispatch,
          fetchPlaceDetails,
          streetAddress,
        }}
      />
      <div className="button-group">
        <SubmitButton {...{ submitting }}>Save & Continue</SubmitButton>
      </div>
    </form>
  )
}

MemberContactInfoForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'MemberContactInfoForm',
    constraints: {
      'contact.firstName': { presence: { allowEmpty: false } },
      'contact.lastName': { presence: { allowEmpty: false } },
      'contact.email': {
        presence: { allowEmpty: false },
        email: true,
      },
      'contact.mobilePhone': { presence: { allowEmpty: false } },
      'account.businessName': { presence: { allowEmpty: false } },
      'account.streetAddress': AUTOCOMPLETE_ADDRESS_FORM_VALIDATOR,
      'account.country': { presence: { allowEmpty: false } },
      'account.city': { presence: { allowEmpty: false } },
      'account.state': { presence: { allowEmpty: false } },
      'account.postalCode': { presence: { allowEmpty: false } },
    },
  }),
  formValues({
    country: 'account.country',
    accountDetailType: 'account.accountDetailType',
  }),
)(MemberContactInfoForm)
