import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as membershipSelectors } from 'memberships/reducer'
import { MembershipSelection } from 'member-portal/components'
import {
  AllMembershipsType,
  MEMBER_APPLICATION_FLOW,
  MEMBERSHIP_TYPE,
} from 'types'
import { PATH, makePath } from 'config'

const propTypes = {
  allMemberships: AllMembershipsType,
}

function MembershipUpgradesSelectYourMembership({ allMemberships }) {
  const { memberships } = allMemberships

  return (
    <>
      <MembershipSelection
        isUpgrade
        memberApplicationFlow={MEMBER_APPLICATION_FLOW.MEMBERSHIP_UPGRADE}
        memberships={filterLakeMemberships(memberships)}
        nextStep={makePath(PATH.UPGRADES, PATH.MEMBERSHIP, PATH.SELECTION)}
      />
    </>
  )
}

MembershipUpgradesSelectYourMembership.propTypes = propTypes

function mapStateToProps(state) {
  return {
    allMemberships: membershipSelectors.allMemberships(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MembershipUpgradesSelectYourMembership
)

const filterLakeMemberships = (memberships) => {
  return memberships.filter(
    ({ membership_type__c }) => membership_type__c === MEMBERSHIP_TYPE.LAKE_CARD
  )
}
