import { formValuesWithMappedKeys } from 'utils'

// The initial values will come from location.state.formData if the
// homeport was updated by the homeport wizard. Otherwise, the initial
// values will come from the existing 'boat'.
function setInitialValues(location, apiKeyMap) {
  const { boat, formData } = location.state

  if (formData) return formData
  return formValuesWithMappedKeys(boat, apiKeyMap)
}

export default setInitialValues
