import formatISODate from './formatISODate'
import { addYears, isPast, parseISO } from 'date-fns'

function calculateFutureMembershipExpirationDate(dateString, yearsCount = 1) {
  if (!dateString) return ''

  let dateObject = parseISO(dateString)
  if (isPast(dateObject)) dateObject = new Date()

  return formatISODate(addYears(dateObject, yearsCount))
}

export default calculateFutureMembershipExpirationDate
