import { TRAILER_CARE_TYPE_NONE } from 'types'

// If no trailer care has been selected,
// API should receive `null` to clear out any previously stored value

function stripNoneTrailerCareType(trailerCareType) {
  if (trailerCareType !== TRAILER_CARE_TYPE_NONE) return trailerCareType

  return null
}

export default stripNoneTrailerCareType
