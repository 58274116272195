import { isEmpty, pick } from 'lodash'
import { QUERY_KEY } from 'config'

export default function searchParametersFromQuery(query) {
  if (isEmpty(query)) return {}

  return pick(query, [
    QUERY_KEY.CATEGORY,
    QUERY_KEY.COUNTRY,
    QUERY_KEY.SEARCH_TERM,
    QUERY_KEY.SORT,
    QUERY_KEY.ZIP,
  ])
}
