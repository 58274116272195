import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation } from 'react-router-dom'

const propTypes = {
  to: PropTypes.string.isRequired,
  search: PropTypes.string,
}

function RedirectWithQueryParams({ to, search = null }) {
  const location = useLocation()
  return (
    <Redirect
      to={{
        pathname: to,
        search: search || location.search,
      }}
    />
  )
}

RedirectWithQueryParams.propTypes = propTypes

export default React.memo(RedirectWithQueryParams)
