import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Button, Input, SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  close: PropTypes.func.isRequired,
  ...formPropTypes,
}

function LocationSelectorForm({ close, handleSubmit, pristine, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        component={Input}
        label="Postal Code"
        name="zip"
        required
        requiredIndicator="*"
      />
      <div className="button-group">
        <Button onClick={close} className="button-primary-outline">
          Cancel
        </Button>
        <SubmitButton
          {...{ pristine, submitting }}
        >
          Update
        </SubmitButton>
      </div>
    </form>
  )
}

LocationSelectorForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'LocationSelectorForm',
    constraints: {
      zip: { presence: { allowEmpty: false } },
    },
  })
)(LocationSelectorForm)
