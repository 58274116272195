import React from 'react'
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom'
import {
  MarketingCodeProvider,
  MembershipUpdater,
} from 'member-portal/components'
import { ExistingMemberApplicationAccountProvider } from 'memberships/components'
import * as Views from './views'
import Layout from './Layout'
import RenewalApplicationLayout from './RenewalApplicationLayout'
import { makePath, PATH } from 'config'

const propTypes = {}

function Routes() {
  const { path } = useRouteMatch()
  const boatPath = makePath(path, PATH.BOATS)

  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, PATH.CONFIRM_AND_LOGIN)}>
          <Views.RenewalConfirmation />
        </Route>

        <Route>
          <MarketingCodeProvider isRenewal>
            <ExistingMemberApplicationAccountProvider>
              <Switch>
                <Route exact path={makePath(path, PATH.SELECTION)}>
                  <Views.RenewalSelectedMembershipType />
                </Route>
                <Route exact path={makePath(path, PATH.RENEWAL_QUESTIONNAIRE)}>
                  <Views.RenewalMembershipQuestionnaire />
                </Route>
                {/* Boat Routes */}
                <Route exact path={makePath(boatPath, PATH.HOME_PORT_TYPE)}>
                  <Views.RenewalHomePortType />
                </Route>
                <Route exact path={makePath(boatPath, PATH.HOME_PORT_ADDRESS)}>
                  <Views.RenewalSearchAddress />
                </Route>
                <Route exact path={makePath(boatPath, PATH.HOME_PORT_LOCATION)}>
                  <Views.RenewalLocatePort />
                </Route>
                <Route>
                  <ApplicationStepRoutes />
                </Route>
              </Switch>
            </ExistingMemberApplicationAccountProvider>
          </MarketingCodeProvider>
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes

export default Routes

function ApplicationStepRoutes() {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  return (
    <Switch>
      <Route exact path={makePath(path, PATH.ROOT)}>
        <Redirect to={makePath(pathname, PATH.CONTACT)} />
      </Route>

      <Route>
        <MembershipUpdater>
          <RenewalApplicationLayout>
            <Switch>
              <Route exact path={makePath(path, PATH.CONTACT)}>
                <Views.RenewalContactInfo />
              </Route>
              <Route exact path={makePath(path, PATH.BOATS)}>
                <Views.RenewalBoatSelection />
              </Route>
              <Route exact path={makePath(path, PATH.PAYMENT)}>
                <Views.RenewalPayment />
              </Route>
              <Route exact path={makePath(path, PATH.REVIEW_ORDER)}>
                <Views.RenewalReview />
              </Route>
            </Switch>
          </RenewalApplicationLayout>
        </MembershipUpdater>
      </Route>
    </Switch>
  )
}
