import React from 'react'
import PropTypes from 'prop-types'
import { Logo } from 'components'
import { PATH } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function Layout({ children }) {
  return (
    <>
      <nav className="page-navigation">
        <div className="page-navigation-block-container">
          <div className="inner">
            <Logo logoLink={PATH.SEATOW} />
          </div>
        </div>
      </nav>
      {children}
    </>
  )
}

Layout.propTypes = propTypes

export default Layout
