import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as memberActions from 'member-actions'
import { makeMembershipIdPath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
  nextStep: PropTypes.string.isRequired,
  setResetShoppingCart: PropTypes.func.isRequired,
}

function SingleMembershipRedirect({
  membership,
  nextStep,
  setResetShoppingCart,
}) {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const { sfid: membershipId } = membership

    setResetShoppingCart(true)
    history.push({
      ...location,
      pathname: makeMembershipIdPath(membershipId, nextStep),
    })
  }, [membership, nextStep])

  return null
}

SingleMembershipRedirect.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  setResetShoppingCart: memberActions.setResetShoppingCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SingleMembershipRedirect
)
