import { MEMBERSHIP_TYPE, PERMISSIONS } from 'types'

const hasPermission = (membershipType, permissionName) => {
  const membershipPermissions = {
    [MEMBERSHIP_TYPE.COMMERCIAL_CARD]: [],
    [MEMBERSHIP_TYPE.FLEET_CARD]: [],
    [MEMBERSHIP_TYPE.GOLD_CARD]: [PERMISSIONS.MULTIPLE_BOATS],
    [MEMBERSHIP_TYPE.LAKE_CARD]: [PERMISSIONS.MULTIPLE_BOATS],
    [MEMBERSHIP_TYPE.LAKE_FLEET_CARD]: [],
    [MEMBERSHIP_TYPE.PROFESSIONAL_MARINER_CARD]: [PERMISSIONS.MULTIPLE_BOATS],
    [MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP]: [],
  }

  return membershipPermissions[membershipType]?.includes(permissionName)
}

export default hasPermission
