import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNil } from 'lodash'
import classnames from 'classnames'
import { clearMessages, getLatestMessage, flashMessageType } from 'redux-flash'
import { LinkButton } from 'components'
import activeIcon from 'utility-icons/completed.svg'
import warnIcon from 'utility-icons/warn.svg'

const propTypes = {
  clearMessages: PropTypes.func.isRequired,
  flash: flashMessageType,
}

function FlashMessageContainer({ clearMessages, flash }) {
  if (isNil(flash)) return null

  const { isError } = flash
  const isSuccess = !isError

  return (
    <div>
      {flash && (
        <div
          className={classnames(
            'flash-message',
            { success: isSuccess },
            { failure: isError }
          )}
        >
          <div className="text-block">
            {isSuccess ? (
              <img src={activeIcon} alt="" className="status-icon" />
            ) : (
              <img src={warnIcon} alt="" className="status-icon" />
            )}
            <p dangerouslySetInnerHTML={ { __html: flash.message }}></p>
          </div>
          <LinkButton
            onClick={() => clearMessages()}
            className="link-secondary"
          >
            Dismiss
          </LinkButton>
        </div>
      )}
    </div>
  )
}

FlashMessageContainer.propTypes = propTypes

function mapStateToProps(state) {
  return {
    flash: getLatestMessage(state),
  }
}

const mapDispatchToProps = {
  clearMessages: clearMessages,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessageContainer)
