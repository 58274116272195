import React from 'react'
// import PropTypes from 'prop-types'
import { MembershipType } from 'types'
import { isAutoRenewDisabled } from 'utils'

const propTypes = {
  membership: MembershipType.isRequired,
}

function MembershipNotifications({ membership }) {
  const message = generateNotificationMessage(membership)

  if (!message) return null

  return (
    <div className="notification">
      <p className="text-block">{message}</p>
    </div>
  )
}

MembershipNotifications.propTypes = propTypes

export default React.memo(MembershipNotifications)

const generateNotificationMessage = (membership) => {
  const {
    membership_expiration_date__c: expirationDateString,
    auto_renew__c: autoRenew,
  } = membership
  const isMembershipExpiringSoon =
    isAutoRenewDisabled(expirationDateString) && autoRenew

  switch (true) {
    case isMembershipExpiringSoon:
      return 'Looks like your membership is set to expire in 10 days or less.  Please renew your membership and set up Automatic Renewal at the same time.'
    default:
      return null
  }
}
