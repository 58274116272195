import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { MdSearch } from 'react-icons/md'
import { Input, SubmitButton } from 'lp-components'

const propTypes = {
  ...formPropTypes,
}

function BusinessSearchForm({ handleSubmit, prisitne, submitting }) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="filter-block search-form"
    >
      <Field
        aria-label="Search business name"
        component={Input}
        label={false}
        name="searchTerm"
        placeholder="Search by business name..."
      />
      <SubmitButton {...{ prisitne, submitting }} className="link-secondary">
        <MdSearch />
      </SubmitButton>
    </form>
  )
}

BusinessSearchForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'BusinessSearchForm',
    constraints: {},
  })
)(BusinessSearchForm)
