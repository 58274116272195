import React from 'react'
import { compose } from 'recompose'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { Input, SubmitButton, Textarea } from 'lp-components'
import { GIFT_MESSAGE_LENGTH } from 'config'

const propTypes = {
  ...formPropTypes,
}

function DigitalRecipientForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block">
        <FormSection name="recipientContact">
          <div className="row">
            <div className="col-6">
              <Field
                component={Input}
                label="First Name"
                name="firstName"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-6">
              <Field
                component={Input}
                label="Last Name"
                name="lastName"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
          <div className="row">
            <Field
              component={Input}
              label="Email"
              name="email"
              required
              requiredIndicator="*"
            />
          </div>
        </FormSection>

        <Field
          component={Textarea}
          label="Gift Message"
          maxLength={GIFT_MESSAGE_LENGTH}
          name="giftMessage"
          placeholder="Optional gift message"
        />
      </div>
      <SubmitButton {...{ submitting }}>Confirm and Continue</SubmitButton>
    </form>
  )
}

DigitalRecipientForm.propTypes = propTypes

export default compose(
  lpForm({
    name: 'DigitalRecipientForm',
    constraints: {
      ['recipientContact.email']: { email: true, presence: true },
      ['recipientContact.firstName']: { presence: { allowEmpty: false } },
      ['recipientContact.lastName']: { presence: { allowEmpty: false } },
    },
  })
)(DigitalRecipientForm)
