import { isEmpty } from 'lodash'

function parseNumberFromString(string) {
  if (!string) return string

  // Remove all non-digit and non-decimal point characters
  const digits = string.replace(/[^0-9.]/g, '')

  // If, after removal, either an empty string or a single decimal point
  // remains, return 0
  if (isEmpty(digits) || digits === '.') return 0

  return Number(digits)
}

export default parseNumberFromString
