import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import GoogleMapReact from 'google-map-react'
import { pure } from 'recompose'
import { DEFAULT_MAP_ZOOM, REACT_APP_GOOGLE_MAPS_API_KEY } from 'config'
import { handleGoogleApiLoaded } from 'utils'

const propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}

function HomePortMap({ lat, lng }) {
  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAPS_API_KEY }}
        center={{
          lat: lat,
          lng: lng,
        }}
        defaultZoom={DEFAULT_MAP_ZOOM}
        onGoogleApiLoaded={({ map, maps }) => {
          const markerIsDraggable = false
          handleGoogleApiLoaded(map, maps, lat, lng, markerIsDraggable)
        }}
        yesIWantToUseGoogleMapApiInternals
      ></GoogleMapReact>
    </div>
  )
}

HomePortMap.propTypes = exact(propTypes)

export default pure(HomePortMap)
