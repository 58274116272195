import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { AccountType, API_KEY_MAP } from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashErrorMessage } from 'redux-flash'
import { useHistory } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { PageHeader } from 'components'
import { OfferForm } from '../forms'
import { SEATOW_URL, PATH, makeProgramsPortalPath } from 'config'
import { apiValuesWithMappedKeys, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  createOffer: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

function NewOffer({ account, createOffer, flashErrorMessage }) {
  const history = useHistory()
  const { public_facing_url__c: websiteUrl, sfid: accountId } = account

  return (
    <>
      <PageHeader
        headerText="Savings Club"
        backLinkTo={makeProgramsPortalPath(PATH.SAVINGS_CLUB_OFFERS)}
        backLinkText="Back to Savings Club"
      />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <header className="secondary-header">
                <h2>Create a New Offer</h2>
                <p>
                  Once you create a new offer, it will go to us for review. Once
                  approved your offer will go live in the Sea Tow Savings Club
                  listings on <a href={`http://${SEATOW_URL}`}>seatow.com</a>.
                </p>
              </header>
              <OfferForm
                initialValues={{ websiteUrl }}
                onSubmit={(formValues) => {
                  const mappedApiValues = apiValuesWithMappedKeys(
                    {
                      ...formValues,
                      accountId,
                    },
                    API_KEY_MAP.OFFER_KEY_MAP
                  )
                  return createOffer(mappedApiValues)
                }}
                onSubmitSuccess={async () => {
                  history.replace(
                    makeProgramsPortalPath(
                      PATH.SAVINGS_CLUB_OFFERS,
                      PATH.CONFIRM_OFFER
                    )
                  )
                }}
                onSubmitFail={({ _error }) => {
                  _error && flashErrorMessage(_error)
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

NewOffer.propTypes = exact(propTypes)

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashErrorMessage,
}

const mapApiAuthToProps = {
  createOffer: apiActions.createOffer,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(NewOffer)
