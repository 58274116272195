import {
  createGetRequest,
  createPatchRequest,
  createPostRequest,
  createDeleteRequest,
} from 'lp-redux-api'
import {
  PROMO_MEMBER_APPLICATION_FLOW,
  PARTNER_PROGRAM,
  MEMBERSHIP_STATUS,
} from 'types'

// NOTE: all API action creators have a 'bearerToken' parameter as their last
// parameter value. This parameter value is provided by the withApiAuth()
// HOC, which "wraps" all API action creators and obtains, from Auth0, the access
// token needed for all Sea Tow API calls.

// [ Account ]--------------------------------------------------------------
export const createContact = createPostRequest(
  'CREATE_CONTACT',
  (params, bearerToken) => ({
    bearerToken,
    url: '/contacts',
    body: params,
  })
)

export const deleteContact = createDeleteRequest(
  'DELETE_CONTACT',
  (contact, bearerToken) => ({
    bearerToken,
    url: '/contacts',
    body: { sfid: contact.sfid, id: contact.id },
  })
)

export const fetchAccount = createGetRequest(
  'FETCH_ACCOUNT',
  (accountId, bearerToken) => ({
    bearerToken,
    url: `/accounts/${accountId}`,
  })
)

export const fetchContacts = createGetRequest(
  'FETCH_CONTACTS',
  (accountId, bearerToken) => ({
    bearerToken,
    url: `/contacts/all/${accountId}`,
  })
)

export const requestPasswordChange = createPostRequest(
  'REQUEST_PASSWORD_CHANGE',
  (user, bearerToken) => ({
    bearerToken,
    url: '/auth0/createpasswordticket',
    body: user,
  })
)

export const updateAccount = createPatchRequest(
  'UPDATE_ACCOUNT',
  (params, bearerToken) => ({
    bearerToken,
    url: '/accounts',
    body: params,
  })
)

export const updateContact = createPatchRequest(
  'UPDATE_CONTACT',
  (params, bearerToken) => ({
    bearerToken,
    url: '/contacts',
    body: params,
  })
)

export const updateUsername = createPatchRequest(
  'UPDATE_USERNAME',
  (user, bearerToken) => ({
    bearerToken,
    url: '/auth0/changeusername',
    body: user,
  })
)

export const uploadLogo = createPostRequest(
  'UPLOAD_LOGO',
  (logo, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/uploadlogotos3',
    body: logo,
  })
)

// [ AOR ]------------------------------------------------------------------
export const fetchAor = createPostRequest(
  'FETCH_AOR',
  (params, bearerToken) => ({
    bearerToken,
    url: '/utility/latlongaor',
    body: params,
  })
)

// [ ARB ]------------------------------------------------------------------

export const prepareOpportunityAndOLIs = createPostRequest(
  'PREPARE_OPPORTUNITY_AND_OLIS',
  (billing) => ({
    url: '/prepareopportunityandolis',
    body: billing,
  })
)

export const addPaymentToOpportunity = createPostRequest(
  'ADD_PAYMENT_TO_OPPORTUNITY',
  (billing) => ({
    url: '/addpaymenttoopportunity',
    body: {
      ...billing,
      create_source__c: process.env.REACT_APP_CREATE_SOURCE,
    },
  })
)

export const enrollAutoRenewal = createPostRequest(
  'ENROLL_AUTO_RENEWAL',
  (autoRenewal, bearerToken) => ({
    bearerToken,
    url: '/authorizenet/cancelexistingmakenew',
    body: autoRenewal,
  })
)

export const updateARBCreditCard = createPostRequest(
  'UPDATE_ARB_CREDIT_CARD',
  (creditCard, bearerToken) => ({
    bearerToken,
    url: '/authorizenet/cancelexistingmakenew',
    body: creditCard,
  })
)

export const unenrollAutoRenewal = createDeleteRequest(
  'UNENROLL_AUTO_RENEWAL',
  (autoRenewal, bearerToken) => ({
    bearerToken,
    url: '/authorizenet/cancelarbfrommembershipid',
    body: autoRenewal,
  })
)

// [Authentication/Authorization]-------------------------------------------
export const verifyMembership = createPostRequest(
  'VERIFY_MEMBERSHIP',
  (membership, bearerToken) => ({
    bearerToken,
    url: '/utility/verify',
    body: membership,
  })
)

// [Billing]----------------------------------------------------------------
export const updateBillingAddress = createPatchRequest(
  'UPDATE_BILLING_ADDRESS',
  (billingAddress, bearerToken) => ({
    bearerToken,
    url: '/accounts',
    body: billingAddress,
  })
)

// [ Boats ]--------------------------------------------------------------
export const updateBoat = createPatchRequest('UPDATE_BOAT', (params) => ({
  url: '/boats',
  body: params,
}))

export const createBoat = createPostRequest('CREATE_BOAT', (params) => ({
  url: '/boats',
  body: params,
}))

export const deleteBoat = createDeleteRequest(
  'DELETE_BOAT',
  (boat, bearerToken) => ({
    bearerToken,
    url: '/boats',
    body: { sfid: boat.sfid, id: boat.id },
  })
)

export const setOldInactiveAndSetNewPrimary = createPatchRequest(
  'CHANGE_PRIMARY',
  (oldBoat, newBoat, reasonFormRemoval, bearerToken) => ({
    bearerToken,
    url: '/boats/deleteoldsetnew/',
    body: {
      new_primary_boat: {
        id: newBoat.id,
      },
      deleted_boat: {
        reason_for_removal: reasonFormRemoval,
        id: oldBoat.id,
      },
    },
  })
)

// [ Google Maps ]------------------------------------------------------------------
export const autoCompleteAddress = createGetRequest(
  'AUTO_COMPLETE_ADDRESS',
  (input) => ({
    url: `/google_places?input=${input}`,
  })
)

export const fetchPlaceDetails = createGetRequest(
  'FETCH_PLACE_DETAILS',
  (placeId) => ({
    url: `/googleplacesdetails/${placeId}`,
  })
)

export const reverseGeoAddress = createGetRequest(
  'REVERSE_ADDRESS_LOOKUP_WITH_LATLONG',
  (lat, lng) => ({
    url: `/geocoding?latlng=${lat},${lng}`,
  })
)

// [ Membership Card ]------------------------------------------------------
export const fetchMembershipCardOrderStatus = createGetRequest(
  'FETCH_MEMBERSHIP_CARD_ORDER_STATUS',
  (membershipId, bearerToken) => ({
    bearerToken,
    url: `/utility/fulfillment/${membershipId}`,
  })
)

export const requestCardReprint = createPostRequest(
  'REQUEST_CARD_REPRINT',
  (membership, bearerToken) => ({
    bearerToken,
    url: '/utility/reprint',
    body: membership,
  })
)

// [ Memberships ]----------------------------------------------------------
export const fetchAllMembershipsForAccount = createGetRequest(
  'FETCH_ALL_MEMBERSHIPS_FOR_ACCOUNT',
  (accountId, bearerToken) => ({
    bearerToken,
    url: `/dashboard/${accountId}`,
  })
)

export const fetchMembershipAndAor = createGetRequest(
  'FETCH_MEMBERSHIP_AND_AOR',
  (membershipId, bearerToken) => ({
    bearerToken,
    url: `/membershipandaor/${membershipId}`,
  })
)

// [ Membership Kit ]------------------------------------------------------
export const applyForMembershipKit = createPostRequest(
  'APPLY_FOR_MEMBERSHIP_KIT',
  (contact, bearerToken) => {
    const contactWithService = {
      ...contact,
      service: PARTNER_PROGRAM.MEMBERSHIP_KITS,
    }

    return {
      bearerToken,
      url: `/tasks`,
      body: contactWithService,
    }
  }
)

// [ Membership Upgrades ]---------------------------------------------------
export const chargeMembershipUpgrade = createPostRequest(
  'CHARGE_MEMBERSHIP_UPGRADE',
  (upgrade, bearerToken) => ({
    bearerToken,
    url: '/upgrades',
    body: upgrade,
  })
)

// [ New Member Application ]-------------------------------------------------
export const createMembershipApplicationAccount = createPostRequest(
  'CREATE_MEMBERSHIP_APPLICATION_ACCOUNT',
  ({ account, contact, membership }) => ({
    url: '/utility/baseaccount/',
    body: {
      account,
      contact,
      membership__c: membership,
    },
  })
)

export const fetchActivationCodeDetails = createPostRequest(
  'FETCH_ACTIVATION_CODE_DETAILS',
  (activationCode) => ({
    url: '/utility/activationcode/',
    body: {
      activation_code: activationCode,
    },
  })
)

export const fetchMembershipApplicationAccount = createPostRequest(
  'FETCH_MEMBERSHIP_APPLICATION_ACCOUNT',
  (accountId, membershipId) => ({
    url: `/utility/getbaseaccountfromhcextid/`,
    body: {
      account: {
        heroku_external_id__c: accountId,
      },
      membership__c: {
        heroku_external_id__c: membershipId,
      },
    },
  })
)

export const fetchProductPrices = createGetRequest(
  'FETCH_PRODUCT_PRICES',
  () => ({
    url: '/products',
  })
)

export const fetchPromoCodeDetails = createPostRequest(
  'FETCH_PROMO_CODE_DETAILS',
  ({ promoCode, isRenewal, membershipStatus }) => ({
    url: '/utility/promos/',
    body: {
      promotion_code: promoCode,
      flow_type:
        membershipStatus === MEMBERSHIP_STATUS.EXPIRED
          ? PROMO_MEMBER_APPLICATION_FLOW.REACQUIRED
          : isRenewal
            ? PROMO_MEMBER_APPLICATION_FLOW.RENEW
            : PROMO_MEMBER_APPLICATION_FLOW.NEW,
    },
  })
)

export const updateMembershipApplicationAccount = createPatchRequest(
  'UPDATE_MEMBERSHIP_APPLICATION_ACCOUNT',
  ({ account, contact, membership }) => ({
    url: '/utility/baseaccount/',
    body: {
      account,
      contact,
      membership__c: membership,
    },
  })
)

export const updateMembershipAssociatedAccount = createPostRequest(
  'UPDATE_MEMBERSHIP_ASSOCIATED_ACCOUNT',
  (email, memberNumber) => ({
    url: `/auth0reassociation/${email}/${memberNumber}`,
    body: {},
  })
)

export const verifyUniquePrimaryEmail = createGetRequest(
  'VERIFY_UNIQUE_PRIMARY_EMAIL',
  (email) => ({
    url: `/utility/primarycontactemailexists/${email}`,
  })
)

// [ Programs Participant Application ]------------------------------------------------
export const applyAsAParticipant = createPostRequest(
  'APPLY_AS_NEW_PARTNER',
  (participant, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/requestapproval/',
    body: participant,
  })
)

// [ Offers ]----------------------------------------------------------------
export const createOffer = createPostRequest(
  'CREATE_OFFER',
  (offer, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/',
    body: offer,
  })
)

export const fetchRandomOffers = createGetRequest(
  'FETCH_RANDOM_OFFERS',
  (bearerToken) => ({
    bearerToken,
    url: '/savingsclub/3randomnationaldeals/',
  })
)

export const updateOffer = createPatchRequest(
  'UPDATE_OFFER',
  (offer, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/',
    body: offer,
  })
)

export const createAndLinkAccount = createPostRequest(
  'CREATE_AND_LINK_ACCOUNT',
  (account, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/createandlinkaccount',
    body: account,
  })
)

export const swapMetadata = createPostRequest(
  'LINK_ACCOUNT',
  (account, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/swapmetadata',
    body: account,
  })
)

// [ Programs ]-------------------------------------------------------
export const fetchAllProgramsForPartner = createGetRequest(
  'FETCH_ALL_PROGRAMS_FOR_PARTNER',
  (accountId, bearerToken) => ({
    bearerToken,
    url: `/savingsclubdashboard/${accountId}`,
  })
)

// [ Referrals ]------------------------------------------------------------
export const createReferral = createPostRequest(
  'CREATE_REFERRAL',
  (referral, bearerToken) => ({
    bearerToken,
    url: '/referrals',
    body: referral,
  })
)

export const fetchReferral = createGetRequest(
  'FETCH_REFERRAL',
  (referralId, bearerToken) => ({
    bearerToken,
    url: `/referrals/${referralId}`,
  })
)

// [ Savings Club ]---------------------------------------------------------
export const fetchOfferDetail = createGetRequest(
  'FETCH_OFFER_DETAIL',
  (offerId, bearerToken) => ({
    bearerToken,
    url: `/savingsclub/detail/${offerId}`,
  })
)

export const searchSavingsClub = createPostRequest(
  'SEARCH_SAVINGS_CLUB',
  (search, bearerToken) => ({
    bearerToken,
    url: '/savingsclub/search',
    body: search,
  })
)

// [ Sea Care ]------------------------------------------------------------
export const applyForSeaCare = createPostRequest(
  'APPLY_FOR_SEA_CARE',
  (contact, bearerToken) => {
    const contactWithService = {
      ...contact,
      service: PARTNER_PROGRAM.SEA_CARE,
    }

    return {
      bearerToken,
      url: `/tasks`,
      body: contactWithService,
    }
  }
)

// [ Sea Tow as a Gift ]----------------------------------------------------
export const sendSeaTowGift = createPostRequest(
  'SEND_SEA_TOW_GIFT',
  (gift) => ({
    url: '/kitsandgifts',
    body: gift,
  })
)

// [ Trailer Care Addition ]-------------------------------------------------
export const addTrailerCare = createPostRequest(
  'ADD_TRAILER_CARE',
  (trailerCare, bearerToken) => ({
    bearerToken,
    url: '/midmembershiptrailercare',
    body: trailerCare,
  })
)