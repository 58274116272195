import React from 'react'
import { pure } from 'recompose'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Modal } from 'lp-components'
import { PATH, makeMarketingSitePath, makeMemberPortalPath } from 'config'

const propTypes = {}

function RegistrationError() {
  const history = useHistory()
  const { logout } = useAuth0()
  const { state } = useLocation()
  const logoutAndReturnToApp = () =>
    logout({
      logoutParams: {
        returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT),
      },
    })

  return (
    <Modal onClose={logoutAndReturnToApp}>
      <h2>Registration Failure</h2>
      <p>
        You did not provide a valid membership and/or membership expiration
        date. You cannot use the Sea Tow Member Portal without a valid
        membership.
      </p>
      <div className="button-group">
        <Button
          onClick={() =>
            history.replace(
              makeMemberPortalPath(PATH.REGISTRATION, PATH.EXISTING_MEMBER),
              state,
            )
          }
        >
          Retry Registration
        </Button>
        <Button onClick={logoutAndReturnToApp}>OK</Button>
      </div>
    </Modal>
  )
}

RegistrationError.propTypes = propTypes

export default pure(RegistrationError)
