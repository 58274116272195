import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AccountType, MembershipType, HEROKU_ID_KEY } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import * as memberActions from 'member-actions'

const propTypes = {
  account: AccountType,
  children: PropTypes.node.isRequired,
  clearMemberApplicationLS: PropTypes.func.isRequired,
  clearShoppingCart: PropTypes.func.isRequired,
  fetchMembershipApplicationAccount: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  resetShoppingCart: PropTypes.bool.isRequired,
  setResetShoppingCart: PropTypes.func.isRequired,
  selectedMembershipType: PropTypes.string,
  selectedTrailerCareType: PropTypes.string,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function ExistingMemberApplicationAccountProvider({
  account,
  children,
  clearMemberApplicationLS,
  clearShoppingCart,
  fetchMembershipApplicationAccount,
  membership,
  resetShoppingCart,
  selectedMembershipType,
  selectedTrailerCareType,
  setResetShoppingCart,
  updateMembershipApplicationAccount,
}) {
  const applicationAccountId = membership.account__r__heroku_external_id__c
  const applicationMembershipId = membership[HEROKU_ID_KEY]

  useEffect(() => {
    if (resetShoppingCart) {
      // clear out LS values so we have a clean shopping cart
      clearMemberApplicationLS()
      // make sure shopping cart, LS and redux values are set to
      // the existing membership values when first starting flow
      updateMembershipApplicationAccount({
        account: {
          [HEROKU_ID_KEY]: applicationAccountId,
        },
        membership: {
          [HEROKU_ID_KEY]: applicationMembershipId,
          shopping_cart_membership_type__c: membership.membership_type__c,
          shopping_cart_trailer_care_type__c: membership.trailer_care_type__c,
        },
      })
      setResetShoppingCart(false)
    } else {
      fetchMembershipApplicationAccount(
        applicationAccountId,
        applicationMembershipId
      )
    }

    // clear both LS and Shopping Cart State on unmount
    return () => clearShoppingCart()
  }, [applicationAccountId, applicationMembershipId])

  // This fetch returns the application's account, membership and contact.
  // Since membership is already in state, then if account now exists,
  // contact should also exist (not checked for here).
  if (!account || !selectedMembershipType || !selectedTrailerCareType) {
    return <Spinner />
  }

  return children
}

ExistingMemberApplicationAccountProvider.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    membership: memberSelectors.membership(state),
    resetShoppingCart: memberSelectors.resetShoppingCart(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
  }
}

const mapDispatchToProps = {
  fetchMembershipApplicationAccount:
    apiActions.fetchMembershipApplicationAccount,
  clearMemberApplicationLS: memberActions.clearMemberApplicationLS,
  clearShoppingCart: memberActions.clearShoppingCart,
  setResetShoppingCart: memberActions.setResetShoppingCart,
  updateMembershipApplicationAccount:
    apiActions.updateMembershipApplicationAccount,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExistingMemberApplicationAccountProvider
)
