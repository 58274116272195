import React from 'react'

// Note: this component is only used for providing example markup to the styleguide. 
// Do not import it directly into the application.

function Spinner () {
  return (
    <div>
      <div id="spinner"></div>
    </div>
  )
}

export default Spinner