import React from 'react'
import {
  OFFER_REDEMPTION_TYPE,
  OFFER_ONLINE_REDEMPTION_OPTION,
  OFFER_IN_PERSON_REDEMPTION_OPTION,
  SavingsClubOfferType,
} from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  offer: SavingsClubOfferType.isRequired,
}

function onlineRedemptionInstructions({
  online_promo_code__c: promoCode,
  options_for_online_redemption__c: onlineRedemptionOption,
  options_for_online_redemption_other__c: onlineRedemptionInstructions,
}) {
  if (onlineRedemptionOption === OFFER_ONLINE_REDEMPTION_OPTION.PROMO_CODE)
    return promoCode.toUpperCase()
  if (onlineRedemptionOption === OFFER_ONLINE_REDEMPTION_OPTION.OTHER)
    return onlineRedemptionInstructions
  return onlineRedemptionOption
}

function inPersonRedemptionInstructions({
  options_for_in_store_redemption__c: inPersonRedemptionOption,
  options_for_in_store_redemption_other__c: inPersonRedemptionInstructions,
}) {
  if (inPersonRedemptionOption === OFFER_IN_PERSON_REDEMPTION_OPTION.OTHER)
    return inPersonRedemptionInstructions
  return inPersonRedemptionOption
}

function OfferRedemptionInstructions({ offer }) {
  const {
    online_promo_code__c: promoCode,
    online_redemption__c: online,
    in_person_redemption__c: inPerson,
  } = offer

  return (
    <ul className="inline-list">
      {online && (
        <li>
          {OFFER_REDEMPTION_TYPE.ONLINE}
          {promoCode && (
            <>
              &nbsp;- {OFFER_ONLINE_REDEMPTION_OPTION.PROMO_CODE}
              <strong>&nbsp;{onlineRedemptionInstructions(offer)}</strong>
            </>
          )}
        </li>
      )}
      {inPerson && (
        <li>
          {OFFER_REDEMPTION_TYPE.IN_PERSON} -{' '}
          <strong>&nbsp;{inPersonRedemptionInstructions(offer)}</strong>
        </li>
      )}
    </ul>
  )
}

OfferRedemptionInstructions.propTypes = exact(propTypes)

export default pure(OfferRedemptionInstructions)
