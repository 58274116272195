import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink, StatusIndicator } from 'components'
import { STATUS_CLASS, SavingsClubType } from 'types'
import { PATH, makeProgramsPortalPath } from 'config'

const propTypes = {
  offers: SavingsClubType.isRequired,
}

function SavingsClub({ offers }) {
  const { active, awaiting_approval, prior } = offers
  const totalActiveOffers = active.length
  const totalAwaitingApprovalOffers = awaiting_approval.length
  const totalPriorOffers = prior.length

  return (
    <div className="card card image-card with-inline-image">
      <div className="image-block">
        <img
          src="/assets/images/imagery/programs-portal/savings-club.jpg"
          alt=""
        />
      </div>
      <div className="text-block">
        <h3>Savings Club</h3>
        <span className="statuses">
          <StatusIndicator
            statusClass={STATUS_CLASS.OK}
            statusText={`${totalActiveOffers} Active Offers`}
          />
          <StatusIndicator
            statusClass={STATUS_CLASS.WARNING}
            statusText={`${totalAwaitingApprovalOffers} Awaiting Approval`}
          />
          <StatusIndicator
            statusClass={STATUS_CLASS.NOT_OK}
            statusText={`${totalPriorOffers} Prior Offers`}
          />
        </span>
        <p>
          Promote your business to Sea Tow members by providing deals or
          discounts on your products and services that are exclusive to active
          Sea Tow members.
        </p>
        <ButtonLink
          className="button-primary"
          to={makeProgramsPortalPath(PATH.SAVINGS_CLUB_OFFERS)}
        >
          Manage Offers
        </ButtonLink>
      </div>
    </div>
  )
}

SavingsClub.propTypes = exact(propTypes)

export default pure(SavingsClub)
