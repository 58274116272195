import { handleActions } from 'redux-actions'
import { set } from 'lodash/fp'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { handleResponse, setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import { USER_METADATA } from 'config'

const reducerKey = 'programsPortal'
const slice = 'root.programsPortal'

const initialState = {}

const reducer = handleActions(
  {
    [actions.clearAllProgramsForPartner]: unsetState('allPrograms'),
    [actions.clearPermanentAccountId]: unsetState('permanentAccountId'),

    [apiActions.fetchAllProgramsForPartner]: setOnSuccess('allPrograms'),
    [apiActions.swapMetadata]: handleResponse(
      // Success path...
      (state, { payload }) => {
        const {
          data: { account_id },
        } = payload

        return set('permanentAccountId', account_id, state)
      },
      // Failure path...
      (state) =>
        set('permanentAccountId', USER_METADATA.INVALID_ACCOUNT_ID, state)
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  allPrograms: select('allPrograms'),
  permanentAccountId: select('permanentAccountId'),
  savingsClubProgram: select('allPrograms.savings_club'),
}

export { reducer, selectors, reducerKey }
