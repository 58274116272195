import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isNil } from 'lodash'
import { isBefore } from 'date-fns'
import { Button } from 'lp-components'
import { ButtonLink } from 'components'
import CategoryCards from './CategoryCards'
import OfferCards from './OfferCards'
import { MEMBERSHIP_STATUS, MembershipType } from 'types'
import { makeMembershipsPath, makeMemberPortalPath, PATH } from 'config'
import { formatUSMonthDayYearDate, parseISODateTime, useToggle } from 'utils'

const propTypes = {
  memberships: PropTypes.arrayOf(MembershipType).isRequired,
}

function SavingsClubDirectory({ memberships }) {
  const hasActiveOrPendingMemberships = memberships.some(
    ({ membership_status__c }) =>
      membership_status__c === MEMBERSHIP_STATUS.ACTIVE ||
      MEMBERSHIP_STATUS.PENDING_ACTIVATION,
  )

  return (
    <section>
      <header className="with-actions">
        <h2 className="title">Savings Club</h2>
        <div className="actions">
          <ButtonLink to={makeMemberPortalPath(PATH.SAVINGS_CLUB)}>
            View All Offers
          </ButtonLink>
        </div>
      </header>
      {hasActiveOrPendingMemberships ? (
        <SavingsClubContent />
      ) : (
        <SavingsClubUnavailable memberships={memberships} />
      )}
    </section>
  )
}

SavingsClubDirectory.propTypes = exact(propTypes)

export default pure(SavingsClubDirectory)

function SavingsClubContent() {
  const [categoriesVisible, toggleCategoriesVisible] = useToggle()
  return (
    <>
      <div className="card-preview-list">
        <OfferCards />
      </div>
      <header className="with-actions">
        <h3 className="title">Categories you can save on:</h3>
        <div className="actions">
          <Button
            className="link-secondary continue-link"
            onClick={toggleCategoriesVisible}
          >
            View All Categories
          </Button>
        </div>
      </header>
      <div className="category-preview-list">
        <CategoryCards categoriesVisible={categoriesVisible} />
      </div>
    </>
  )
}

function SavingsClubUnavailable({ memberships }) {
  const allFutureMemberships = memberships.filter(
    ({ membership_status__c }) =>
      membership_status__c === MEMBERSHIP_STATUS.FUTURE_START,
  )
  const earliestFutureMembershipDate = allFutureMemberships.reduce(
    (
      earliestMembershipDate,
      { membership_effective_date__c: membershipStartDateString },
    ) => {
      const membershipStartDate = parseISODateTime(membershipStartDateString)

      // If the earliest membership date is empty it means we are comparing against
      // the initial (unset) start date...
      if (isNil(earliestMembershipDate) || isNil(membershipStartDate)) {
        return membershipStartDate
      } else {
        return isBefore(membershipStartDate, earliestMembershipDate)
          ? membershipStartDate
          : earliestMembershipDate
      }
    },
    null,
  )

  return (
    <div>
      {isNil(earliestFutureMembershipDate) ? (
        <>
          <p>
            <strong>
              You are missing out on some great Sea Tow Savings Club offers!
            </strong>
          </p>
          <ButtonLink
            to={makeMembershipsPath(
              PATH.SELECT_YOUR_MEMBERSHIP,
              PATH.RENEW_MEMBERSHIP,
            )}
          >
            Renew Today
          </ButtonLink>
        </>
      ) : (
        <p>
          <strong>
            Your Sea Tow Savings Club benefits will start on{' '}
            {formatUSMonthDayYearDate(earliestFutureMembershipDate)}{' '}
          </strong>
        </p>
      )}
    </div>
  )
}
