import { DISCOUNT_CODE_TYPE, DISCOUNT_CODE_AUTO_RENEW_REQUIRED } from 'types'

// Returns discount code details object (either activation or promo) in the shape of the AppliedDiscountCodeType
// Not all fields will be populated depending on the type of discount

function mapToAppliedDiscountCodeType(discountCodeDetails) {
  if (!discountCodeDetails) return discountCodeDetails

  const {
    hide_on_website__c,
    name,
    ongoing_promo_type__c,
    promo_standardized__c,
    promotion_details__c,
    promo_legal__c,
    description__c,
    value_discount_in_dollars__c,
    value_discount_in_percentage__c,
    value__c,
    sfid,
    related_product,
    valid_on_business_type__c,
    valid_on_membership__c,
    usage_rules__c,
  } = discountCodeDetails

  // only promo codes (and all promo codes) have this 'promo_standardized__c' value
  const type = promo_standardized__c
    ? DISCOUNT_CODE_TYPE.PROMO
    : DISCOUNT_CODE_TYPE.ACTIVATION

  const autoRenewRequired = DISCOUNT_CODE_AUTO_RENEW_REQUIRED.includes(
    ongoing_promo_type__c
  )

  return {
    autoRenewRequired,
    isPromoCodeHidden: hide_on_website__c,
    name,
    description: description__c,
    dollarsOff: value_discount_in_dollars__c ?? value__c ?? 0,
    percentOff: value_discount_in_percentage__c,
    type,
    id: sfid,
    promo_legal: promo_legal__c ?? '',
    promotion_details: promotion_details__c ?? '',
    related_product,
    valid_on_business_type: valid_on_business_type__c,
    valid_on_membership: valid_on_membership__c,
    usage_rules: usage_rules__c,
  }
}

export default mapToAppliedDiscountCodeType
