import { isNil } from 'lodash'
import { format } from 'date-fns'

const formatUSMonthDayYearDate = (date) => {
  if (isNil(date)) return 'N/A'

  return format(date, 'MM/dd/yyyy')
}

export default formatUSMonthDayYearDate
