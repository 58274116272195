import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { isNil } from 'lodash'
import { lpForm } from 'lp-form'
import { Button, Select, SubmitButton } from 'lp-components'
import {
  Field,
  SubmissionError,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { BOAT_STATUS, BoatType } from 'types'

const propTypes = {
  activeNonPrimaryBoats: PropTypes.arrayOf(BoatType),
  isPrimaryBoat: PropTypes.bool.isRequired,
  newPrimary: PropTypes.string,
  toggleShowRemoveBoatModal: PropTypes.func.isRequired,
  ...formPropTypes,
}

function RemoveBoatForm({
  activeNonPrimaryBoats,
  handleSubmit,
  isPrimaryBoat,
  newPrimary,
  pristine,
  submitting,
  toggleShowRemoveBoatModal,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        component={Select}
        label="Reason for Removing"
        options={removalReasonOptions()}
        placeholder="Select a reason"
        name="removalReason"
        required
      />

      {isPrimaryBoat && (
        <Field
          component={Select}
          label="Assign a New Primary Boat"
          options={newPrimaryBoatOptions(activeNonPrimaryBoats)}
          placeholder="Select a boat"
          name="newPrimary"
          required
        />
      )}

      <div className="button-group">
        <Button
          className="button-primary-outline"
          onClick={toggleShowRemoveBoatModal}
        >
          Cancel
        </Button>

        <SubmitButton
          className="button-warn"
          invalid={isPrimaryBoat && isNil(newPrimary)}
          {...{ pristine, submitting }}
        >
          Remove this Boat
        </SubmitButton>
      </div>
    </form>
  )
}

RemoveBoatForm.propTypes = propTypes

const beforeSubmit = (formValues, { isPrimaryBoat }) => {
  const { newPrimary } = formValues

  if (isPrimaryBoat && isNil(newPrimary)) {
    throw new SubmissionError({
      newPrimary: 'You must select a new primary boat',
    })
  }

  return formValues
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'RemoveBoatForm',
    constraints: {
      removalReason: { presence: true },
    },
  }),
  formValues('newPrimary')
)(RemoveBoatForm)

const removalReasonOptions = () => {
  return Object.values(BOAT_STATUS).filter(
    (status) => status !== BOAT_STATUS.ACTIVE
  )
}

const newPrimaryBoatOptions = (boats) => {
  return boats.map((boat) => {
    const {
      boat_make__c,
      name__c,
      name_of_boat_club__c,
      no_boat_reason__c,
      id: boatId,
    } = boat

    return {
      key: name_of_boat_club__c ?? no_boat_reason__c ?? name__c ?? boat_make__c,
      value: boatId,
    }
  })
}
