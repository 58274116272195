import React from 'react'
import PropTypes from 'prop-types'
import { SiteMetadata } from 'components'
import { SITE_METADATA } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
}

function Layout({ children }) {
  const { metaTitle, description, keywords } = SITE_METADATA.TRAILER_CARE
  return (
    <>
      <SiteMetadata
        metaTitle={metaTitle}
        description={description}
        keywords={keywords}
      />
      <div className="image-and-form-layout purchase-flow">
        <div className="split-image-block">
          <img
            src="/assets/images/imagery/purchase-flow/trailer-care.jpg"
            alt=""
          />
        </div>
        {children}
      </div>
    </>
  )
}

Layout.propTypes = propTypes

export default Layout
