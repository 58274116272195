import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import UpgradeButton from './UpgradeButton'
import RenewButton from './RenewButton'

const propTypes = {
  membershipType: PropTypes.string.isRequired,
  isUpgrade: PropTypes.bool,
  nextStepPath: PropTypes.string.isRequired,
  children: PropTypes.node,
}

function MembershipSelectionButton({
  membershipType,
  isUpgrade = false,
  nextStepPath,
  children,
}) {
  const ButtonLink = isUpgrade ? UpgradeButton : RenewButton

  return (
    <ButtonLink
      to={nextStepPath}
      aria-label={`Select membership to ${isUpgrade ? 'upgrade' : 'renew'}`}
      className="button-primary"
    >
      <div className="text-block">
        <h3>{membershipType}</h3>
        {children}
      </div>
    </ButtonLink>
  )
}

MembershipSelectionButton.propTypes = exact(propTypes)

export default pure(MembershipSelectionButton)
