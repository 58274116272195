import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'membershipCard'
const slice = 'root.memberPortal.memberships.membershipCard'
const initialState = {}
const reducer = handleActions(
  {
    [apiActions.fetchMembershipCardOrderStatus]: setOnSuccess('fulfillments'),
    [actions.clearMembershipCardOrderStatus]: unsetState('fulfillments'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  fulfillments: select('fulfillments'),
}

export { reducer, selectors, reducerKey }
