import React from 'react'
import { PATH, makeMemberApplicationPath } from 'config'
import { SearchAddressSection } from 'memberships/boats/components'

const propTypes = {}

function NewMemberSearchAddress() {
  return (
    <>
      <SearchAddressSection
        nextStep={makeMemberApplicationPath(
          PATH.BOATS,
          PATH.HOME_PORT_LOCATION
        )}
        previousStep={makeMemberApplicationPath(
          PATH.BOATS,
          PATH.HOME_PORT_TYPE
        )}
      />
    </>
  )
}

NewMemberSearchAddress.propTypes = propTypes

export default NewMemberSearchAddress
