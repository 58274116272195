import React from 'react'
import PropTypes from 'prop-types'
import { isNil, snakeCase } from 'lodash'
import { Button } from 'lp-components'
import { ExternalLink } from 'components'
import { PartnerLogo } from 'programs-portal/components'
import { BusinessInformationForm } from '../forms'
import { API_KEY_MAP, AccountType } from 'types'
import {
  apiValuesWithMappedKeys,
  formatPhoneNumber,
  formValuesWithMappedKeys,
  useToggle,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  formOpen: PropTypes.bool,
  updateAccount: PropTypes.func.isRequired,
}

function BusinessInformation({
  account,
  fetchAccount,
  formOpen,
  updateAccount,
}) {
  const [
    businessInformationFormOpen,
    toggleBusinessInformationForm,
  ] = useToggle(formOpen)
  const {
    billingcity,
    billingcountry,
    billingpostalcode,
    billingstate,
    billingstreet,
    company_logo__c,
    name,
    partner_description__c,
    partner_facebook__c,
    partner_instagram__c,
    partner_linkedin__c,
    phone,
    public_facing_email_address__c,
    public_facing_url__c,
    sfid: accountId,
    shippingcity,
    shippingcountry,
    shippingpostalcode,
    shippingstate,
    shippingstreet,
  } = account
  const refreshAccount = () => fetchAccount(accountId)

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Bus-Info.svg"
          alt=""
          className="icon"
        />
        <h2>Business Information</h2>
        <div className="actions">
          <Button
            onClick={toggleBusinessInformationForm}
            className="link-primary"
          >
            (Edit)
          </Button>
        </div>
      </header>
      <div className="card-inner">
        {businessInformationFormOpen ? (
          <BusinessInformationForm
            accountId={accountId}
            initialValues={formInitialValues(account)}
            onSubmit={(formValues) => {
              const apiValues = apiValuesWithMappedKeys(
                formValues,
                API_KEY_MAP.BUSINESS_INFORMATION_KEYMAP
              )
              apiValues.sfid = account.sfid
              apiValues.id = account.id
              return updateAccount(apiValues)
            }}
            onSubmitSuccess={() => {
              refreshAccount()
              toggleBusinessInformationForm()
            }}
            refreshAccount={refreshAccount}
            toggleBusinessInformationForm={toggleBusinessInformationForm}
          />
        ) : (
          <div className="read-only">
            <div className="row">
              <div className="col-6">
                <h3>Business Name</h3>
                <p>{name}</p>
              </div>
              <div className="col-6">
                <h3>Email</h3>
                <p>{public_facing_email_address__c}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h3>Physical Address</h3>
                <p>
                  {shippingstreet}
                  <br></br>
                  {`${shippingcity}, ${shippingstate} ${shippingpostalcode}`}
                  <br></br>
                  {shippingcountry}
                </p>
              </div>
              <div className="col-6">
                <h3>Primary Phone Number</h3>
                <p>{formatPhoneNumber(phone)}</p>
              </div>
            </div>

            {/* Note that a value for "billingstreet" indicates a mailing */}
            {/* address different than the public address. */}
            {billingstreet && (
              <div className="row">
                <div className="col-6">
                  <h3>Mailing Address</h3>
                  <p>
                    {billingstreet}
                    <br></br>
                    {`${billingcity}, ${billingstate} ${billingpostalcode}`}
                    <br></br>
                    {billingcountry}
                  </p>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-6">
                <h3>Website</h3>
                <p>
                  {public_facing_url__c ? (
                    <ExternalLink href={public_facing_url__c}>
                      {formatHostname(public_facing_url__c)}
                    </ExternalLink>
                  ) : (
                    '—'
                  )}
                </p>
              </div>
              <div className="col-6">
                <h3>Facebook</h3>
                <p>
                  {partner_facebook__c ? (
                    <ExternalLink href={partner_facebook__c}>
                      {formatHostnameAndPath(partner_facebook__c)}
                    </ExternalLink>
                  ) : (
                    '—'
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h3>Instagram</h3>
                <p>
                  {partner_instagram__c ? (
                    <ExternalLink href={partner_instagram__c}>
                      {formatHostnameAndPath(partner_instagram__c)}
                    </ExternalLink>
                  ) : (
                    '—'
                  )}
                </p>
              </div>
              <div className="col-6">
                <h3>LinkedIn</h3>
                <p>
                  {partner_linkedin__c ? (
                    <ExternalLink href={partner_linkedin__c}>
                      {formatHostnameAndPath(partner_linkedin__c)}
                    </ExternalLink>
                  ) : (
                    '—'
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div>
                <h3>Business Description</h3>
                <p>{partner_description__c}</p>
              </div>
            </div>
            <div className="row">
              <div>
                <h3>Logo</h3>
                <div className="circle-image-block">
                  <PartnerLogo companyLogo={company_logo__c} companyName={name} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

BusinessInformation.propTypes = propTypes

export default BusinessInformation

const formInitialValues = (account) => {
  const initialValues = formValuesWithMappedKeys(
    account,
    API_KEY_MAP.BUSINESS_INFORMATION_KEYMAP
  )
  const { billingstreet, company_logo__c, name } = account

  // If the billing street (which maps to a business' mailing address) has
  // not been set then set the value of "mailingAddressSameAsPublic" in the
  // form to true, meaning that the business' public address and its mailing
  // address are the same.
  initialValues.mailingAddressSameAsPublic = isNil(billingstreet)
  initialValues.logoUrl =
    company_logo__c
      ? [{ name: `${snakeCase(name) || "company"}_logo`, url: company_logo__c }]
      : null

  return initialValues
}

const formatHostname = (url) => {
  if (isNil(url)) return 'N/A'

  const { hostname } = new URL(url)

  return hostname
}

const formatHostnameAndPath = (url) => {
  if (isNil(url)) return 'N/A'

  const { hostname, pathname } = new URL(url)

  return `${hostname}${pathname}`
}
