import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  checked: PropTypes.bool.isRequired,
  priceDifference: PropTypes.number.isRequired,
  toggleMembershipType: PropTypes.func.isRequired,
}

function LakeToGoldUpgradeBox({
  checked,
  priceDifference,
  toggleMembershipType,
}) {
  return (
    <div>
      <h4>
        Get 100% Coverage Fresh or Salt Water for only ${priceDifference} more a
        year.
      </h4>
      <p>
        With your current lake membership you are only covered in fresh bodies
        of water. Get 100% coverage, salt and fresh, with a Gold membership.
      </p>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={({ target: { checked } }) => toggleMembershipType(checked)}
        />
        Upgrade to Gold Card
      </label>
    </div>
  )
}

LakeToGoldUpgradeBox.propTypes = exact(propTypes)

export default React.memo(LakeToGoldUpgradeBox)
