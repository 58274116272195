const apiValuesWithHomePort = (homePort, homePortName, homePortType, aor) => {
  const { city, country, lat, lng, street, state, streetNumber } = homePort

  const { franchisee_aor_link__c: aorId } = aor

  return {
    address: streetNumber ? `${streetNumber} ${street}` : street && street,
    aor: aorId,
    lat: lat,
    lng: lng,
    city: city,
    country: country,
    state: state,
    homePortName: homePortName,
    homePortType: homePortType,
  }
}

export default apiValuesWithHomePort
