import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { MembershipQuestionnaire } from 'member-portal/components'
import { makeMemberApplicationPath, PATH } from 'config'

const propTypes = {
  isTrialMembershipsActive: PropTypes.bool,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
}

function NewMemberQuestionnaire({
  isTrialMembershipsActive,
  promoCodeIsTrial,
  setSelectedMembershipType,
}) {
  if (promoCodeIsTrial && isTrialMembershipsActive) {
    return (
      <Redirect to={makeMemberApplicationPath(PATH.MEMBERSHIP_TYPE_SELECTED)} />
    )
  }

  return (
    <section className="form-block-container questionnaire-flow">
      <div className="questionnaire-flow-inner">
        <header className="header-title">
          <h1>Membership Advisor</h1>
        </header>
        <div className="card card-medium">
          <div className="card-inner">
            <MembershipQuestionnaire
              setSelectedMembershipType={setSelectedMembershipType}
              postSelectionPath={makeMemberApplicationPath(
                PATH.MEMBERSHIP_TYPE_SELECTED
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

NewMemberQuestionnaire.propTypes = propTypes

function mapStateToProps(state) {
  return {
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberQuestionnaire
)
