import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { flashErrorMessage } from 'redux-flash'
import { PageHeader } from 'components'
import { CreateAccountForm } from '../forms'
import * as apiActions from 'api-actions'
import { PATH, QUERY_KEY, makeProgramsApplicationPath } from 'config'
import { apiErrorToErrorMessage, makeQueryPath, parseQuery } from 'utils'

const propTypes = {
  createAndLinkAccount: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

function CreateAccount({ createAndLinkAccount, flashErrorMessage }) {
  const history = useHistory()
  const { search } = useLocation()
  const query = parseQuery(search)
  const status = query[QUERY_KEY.STATUS]
  const userName = query[QUERY_KEY.USERNAME]
  const accountId = query[QUERY_KEY.ACCOUNT_ID]

  return (
    <>
      <PageHeader headerText="Account Creation" />
      <div className="dashboard-block-container">
        <section>
          <div className="card card-small">
            <div className="card-inner">
              <header>
                <h2>One more step, create a login to our program portal.</h2>
              </header>
              <CreateAccountForm
                initialValues={{
                  account_uuid: accountId,
                  email: userName,
                }}
                onSubmit={(formValues) => {
                  return createAndLinkAccount(formValues)
                }}
                onSubmitFail={({ _error }) => {
                  _error && flashErrorMessage(apiErrorToErrorMessage(_error))
                }}
                onSubmitSuccess={() => {
                  history.push(
                    makeQueryPath(makeProgramsApplicationPath(PATH.LOGIN), {
                      [QUERY_KEY.STATUS]: status,
                    })
                  )
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

CreateAccount.propTypes = propTypes

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  createAndLinkAccount: apiActions.createAndLinkAccount,
  flashErrorMessage: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateAccount
)
