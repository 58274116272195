import React from 'react'
import PropTypes from 'prop-types'
import { API_KEY_MAP, HEROKU_ID_KEY, AccountType, ContactType } from 'types'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { MemberContactInfoForm } from '../forms'
import {
  formValuesWithMappedKeys,
  apiValuesWithMappedKeys,
  handleSubmitFailWithFlashMessage,
} from 'utils'

const propTypes = {
  initialAccount: AccountType.isRequired,
  initialContact: ContactType.isRequired,
  applicationMembershipId: PropTypes.string.isRequired,
  autoCompleteAddress: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  successPath: PropTypes.string.isRequired,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function EditMemberContactInfo({
  initialAccount,
  initialContact,
  applicationMembershipId,
  autoCompleteAddress,
  fetchPlaceDetails,
  successPath,
  updateMembershipApplicationAccount,
}) {
  const history = useHistory()
  const applicationAccountId = initialAccount[HEROKU_ID_KEY]

  return (
    <MemberContactInfoForm
      autoCompleteAddress={autoCompleteAddress}
      fetchPlaceDetails={fetchPlaceDetails}
      initialValues={{
        account: formValuesWithMappedKeys(
          initialAccount,
          API_KEY_MAP.ACCOUNT_KEY_MAP
        ),
        contact: formValuesWithMappedKeys(
          initialContact,
          API_KEY_MAP.CONTACT_KEY_MAP
        ),
      }}
      onSubmit={(formValues) => {
        const accountApiValues = apiValuesWithMappedKeys(
          formValues.account,
          API_KEY_MAP.ACCOUNT_KEY_MAP
        )
        const contactApiValues = apiValuesWithMappedKeys(
          formValues.contact,
          API_KEY_MAP.CONTACT_KEY_MAP
        )
        return updateMembershipApplicationAccount({
          account: {
            [HEROKU_ID_KEY]: applicationAccountId,
            ...accountApiValues,
          },
          contact: contactApiValues,
          membership: {
            [HEROKU_ID_KEY]: applicationMembershipId,
          },
        })
      }}
      onSubmitSuccess={() => history.push(successPath)}
      onSubmitFail={handleSubmitFailWithFlashMessage}
    />
  )
}

EditMemberContactInfo.propTypes = propTypes

export default pure(EditMemberContactInfo)
