import { format, parse } from 'date-fns'
// Remove the formatting spaces from the credit expiration date
// Change two digit year to four digit year

const formatCreditCardExpirationDate = (cardExpirationDateString) => {
  if (!cardExpirationDateString) return cardExpirationDateString

  const cleanExpirationDateString = cardExpirationDateString.replace(/\s/g, '')
  const expirationDate = parse(cleanExpirationDateString, 'MM/yy', new Date())

  return format(expirationDate, 'MM/yyyy')
}

export default formatCreditCardExpirationDate
