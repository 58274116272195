import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  MembershipPageHeader,
  MembershipNotifications,
} from 'memberships/components'
import { AutoRenewal, BillingAddress, BillingDetails } from '../components'
import * as apiActions from 'api-actions'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import { AccountType, MembershipType } from 'types'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  unenrollAutoRenewal: PropTypes.func.isRequired,
  updateARBCreditCard: PropTypes.func.isRequired,
  updateBillingAddress: PropTypes.func.isRequired,
}

function Billing({
  account,
  fetchAllMembershipsForAccount,
  membership,
  unenrollAutoRenewal,
  updateARBCreditCard,
  updateBillingAddress,
}) {
  const { sfid: accountId } = account

  return (
    <>
      <MembershipPageHeader membership={membership} />

      <section className="form-block-container">
        <MembershipNotifications membership={membership} />
        <div className="card">
          <BillingDetails
            membership={membership}
            withAutoRenewStatus
            withRenewNowCta
          />
        </div>
        <AutoRenewal
          membership={membership}
          refresh={() => fetchAllMembershipsForAccount(accountId)}
          unenroll={unenrollAutoRenewal}
          update={updateARBCreditCard}
        />
        <BillingAddress
          account={account}
          refresh={() => fetchAllMembershipsForAccount(accountId)}
          update={updateBillingAddress}
        />

        {/* <PaymentHistory /> */}
      </section>
    </>
  )
}

Billing.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
  unenrollAutoRenewal: apiActions.unenrollAutoRenewal,
  updateARBCreditCard: apiActions.updateARBCreditCard,
  updateBillingAddress: apiActions.updateBillingAddress,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(Billing)
