import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { NotificationCenter } from 'components'
import { AccountType, APPROVAL_STATUS, NOTIFICATION_CLASS } from 'types'

const propTypes = {
  account: AccountType.isRequired,
}

function SavingsClubNotificationCenter({ account }) {
  const accountNotifications = generateAccountStatusNotifications(account)
  const notificationCount = accountNotifications.length

  return (
    <NotificationCenter notificationCount={notificationCount}>
      <SavingsClubNotificationMessages
        accountNotifications={accountNotifications}
      />
    </NotificationCenter>
  )
}

SavingsClubNotificationCenter.propTypes = exact(propTypes)

export default pure(SavingsClubNotificationCenter)

function SavingsClubNotificationMessages({ accountNotifications }) {
  return (
    <>
      {accountNotifications.length > 0 && (
        <>
          <h3>Account pending approval</h3>
          {accountNotifications.map(({ message, notificationClass }, index) => (
            <div className={notificationClass} key={index}>
              <p>{message}</p>
            </div>
          ))}
        </>
      )}
    </>
  )
}

const generateAccountStatusNotifications = (account) => {
  const { approval_status__c } = account
  return approval_status__c === APPROVAL_STATUS.AWAITING_APPROVAL ||
    approval_status__c === APPROVAL_STATUS.AWAITING_EDIT_APPROVAL
    ? [
        {
          message:
            'You can begin to create offers as your account is awaiting approval.',
          notificationClass: NOTIFICATION_CLASS.DEFAULT,
        },
      ]
    : []
}
