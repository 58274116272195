import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { UpgradesConfirmationStep } from 'memberships/upgrades/components'
import { AccountType, TRAILER_CARE_DETAIL, TRAILER_CARE_TYPES } from 'types'
import { withApiAuth, formatCurrency } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
}

function TrailerCareUpgradeConfirmation({
  account,
  fetchAllMembershipsForAccount,
  selectedTrailerCareType,
}) {
  const { sfid: accountId } = account
  const trailerCareDetail = TRAILER_CARE_DETAIL[selectedTrailerCareType]
  const { marketingTitle: trailerCareTitle, coverageLimit } = trailerCareDetail
  const confirmationMessage = TRAILER_CARE_TYPES.includes(
    selectedTrailerCareType
  )
    ? `${trailerCareTitle} has successfuly been added to your membership. You now have access to all of the benefits of your selected trailer care option.`
    : `Your membership's trailer care package has been successfully upgraded. You now have access to all of the benefits of your upgraded trailer care package.`

  return (
    <>
      <UpgradesConfirmationStep
        refreshMemberships={() => fetchAllMembershipsForAccount(accountId)}
        confirmationHeader={`Thank you for upgrading to ${trailerCareTitle} with service up to ${formatCurrency(
          coverageLimit,
          0
        )} per incident.`}
        confirmationMessage={confirmationMessage}
      />
    </>
  )
}

TrailerCareUpgradeConfirmation.propTypes = propTypes

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(TrailerCareUpgradeConfirmation)
