import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'
import { STATUS_CLASS } from 'types'

const propTypes = {
  // statusAdditionalText: PropTypes.string,
  statusClass: PropTypes.string,
  statusText: PropTypes.string.isRequired,
}

function StatusIndicator({
  statusClass = STATUS_CLASS.OK,
  /*statusAdditionalText,*/ statusText,
}) {
  return (
    <div className={classnames('status-indicator', statusClass)}>
      <p>{statusText}</p>
    </div>
  )
}

StatusIndicator.propTypes = exact(propTypes)

export default pure(StatusIndicator)
