import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Checkbox, MaskedInput, SubmitButton } from 'lp-components'
import { GiftContactFields } from '../components'
import {
  Field,
  FormSection,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { ADDRESS_FORM_VALIDATOR } from 'config'
import { DigitalRecipientType, MailedRecipientContactType } from 'types'
import {
  formatCreditCardExpirationDate,
  getLocalityFromGooglePlace,
  removeNonDigits,
  formatSubpremise,
} from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  billingSameAsShipping: PropTypes.bool,
  country: PropTypes.string,
  fetchPlaceDetails: PropTypes.func.isRequired,
  mailedToGifter: PropTypes.bool.isRequired,
  recipientContact: PropTypes.oneOfType([
    DigitalRecipientType,
    MailedRecipientContactType,
  ]).isRequired,
  ...formPropTypes,
}

function GiftPaymentForm({
  autoCompleteAddress,
  billingSameAsShipping,
  change,
  country,
  fetchPlaceDetails,
  handleSubmit,
  mailedToGifter,
  submitting,
  recipientContact,
}) {
  const updateAddressValues = useCallback(async (searchResult) => {
    const googlePlace = await fetchPlaceDetails(searchResult.place_id)
    const {
      streetNumber,
      street,
      city,
      state,
      country,
      postalCode,
      subpremise,
    } = getLocalityFromGooglePlace(googlePlace)

    if (subpremise) {
      const formattedSubpremise = formatSubpremise(subpremise)
      change(
        'billingContact.address',
        `${streetNumber} ${street}, ${formattedSubpremise}`
      )
    } else {
      change('billingContact.address', `${streetNumber} ${street}`)
    }
    change('billingContact.city', city)
    change('billingContact.state', state)
    change('billingContact.country', country)
    change('billingContact.postalCode', postalCode)
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block">
        <FormSection name="creditCard">
          <div className="col-6">
            <Field
              component={MaskedInput}
              label="Credit Card Number"
              maskOptions={{ creditCard: true }}
              name="cardNumber"
              placeholder="Credit card number (digits only)"
              parse={removeNonDigits}
              required
              requiredIndicator="*"
            />
          </div>
          <div className="row">
            <div className="col-3">
              <Field
                component={MaskedInput}
                label="Expiration"
                maskOptions={{
                  date: true,
                  datePattern: ['m', 'y'],
                }}
                name="cardExpirationDate"
                placeholder="MM/YY"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-3">
              <Field
                component={MaskedInput}
                label="Security Code"
                maskOptions={{
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralIntegerScale: 4,
                  numeralPositiveOnly: true,
                  numeralThousandsGroupStyle: 'none',
                  stripLeadingZeroes: false,
                }}
                name="securityCode"
                placeholder="CVV"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
        </FormSection>
        <FormSection name="billingContact">
          {mailedToGifter && (
            <Field
              component={Checkbox}
              label="Billing address same as shipping"
              name="billingSameAsShipping"
            />
          )}
          {(!mailedToGifter || !billingSameAsShipping) && (
            <>
              <h4 className="group-title">Your Billing Information</h4>
              <GiftContactFields
                {...{
                  autoCompleteAddress,
                  change,
                  country,
                  updateAddressValues,
                }}
                usageLabel="Billing"
              />
            </>
          )}
          {mailedToGifter && billingSameAsShipping && (
            <GifterContact {...{ recipientContact }} />
          )}
        </FormSection>
      </div>
      <div className="button-group">
        <SubmitButton {...{ submitting }}>Save & Continue</SubmitButton>
      </div>
    </form>
  )
}

function beforeSubmit(formValues, { mailedToGifter, recipientContact }) {
  // Remove the formatting spaces from the credit expiration date
  const { creditCard } = formValues
  const { cardExpirationDate: rawCardExpirationDateString } = creditCard
  const cardExpirationDate = formatCreditCardExpirationDate(
    rawCardExpirationDateString
  )

  // If the gift will be mailed and sent to the gifter and
  // "billingSameAsShipping" has been set to "true", copy the gifter's
  // (recipient) contact information as the billing contact.
  let { billingContact } = formValues
  const { billingSameAsShipping } = billingContact

  if (mailedToGifter && billingSameAsShipping) {
    billingContact = {
      ...billingContact,
      ...recipientContact,
    }
  }

  let normalizedFormValues = {
    ...formValues,
    creditCard: { ...creditCard, cardExpirationDate },
    billingContact,
  }

  return normalizedFormValues
}
GiftPaymentForm.propTypes = propTypes

export default compose(
  lpForm({
    beforeSubmit,
    name: 'GiftPaymentForm',
    constraints: {
      ['billingContact.address']: ADDRESS_FORM_VALIDATOR,
      ['billingContact.city']: { presence: { allowEmpty: false } },
      ['billingContact.country']: { presence: { allowEmpty: false } },
      ['billingContact.email']: { email: true, presence: true },
      ['billingContact.firstName']: { presence: { allowEmpty: false } },
      ['billingContact.lastName']: { presence: { allowEmpty: false } },
      ['billingContact.phone']: { presence: { allowEmpty: false } },
      ['billingContact.postalCode']: { presence: { allowEmpty: false } },
      ['billingContact.state']: { presence: { allowEmpty: false } },
      ['creditCard.cardNumber']: { presence: true, length: { minimum: 13 } },
      ['creditCard.cardExpirationDate']: {
        presence: true,
        length: { is: 5 },
      },
      ['creditCard.securityCode']: {
        presence: true,
        length: { minimum: 3, maximum: 4 },
        numericality: {
          message: 'can contain only numbers',
          onlyInteger: true,
        },
      },
    },
    initialValuesFilters: { reject: ['creditCard.securityCode'] },
    submitFilters: { reject: ['billingContact.billingSameAsShipping'] },
  }),
  formValues({
    billingSameAsShipping: 'billingContact.billingSameAsShipping',
    country: 'billingContact.country',
  })
)(GiftPaymentForm)

function GifterContact({ recipientContact }) {
  const {
    address,
    city,
    firstName,
    lastName,
    postalCode,
    state,
  } = recipientContact
  return (
    <>
      <div className="flex-horizontal">
        <p>
          {firstName} {lastName}
        </p>
        <p>
          {city}, {state}
        </p>
      </div>
      <div className="flex-horizontal">
        <p>{address}</p>
        <p>{postalCode}</p>
      </div>
    </>
  )
}
