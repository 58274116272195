import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

// A button that is styled to appear as a link...
function LinkButton({ children, className = 'link-primary', ...rest }) {
  return (
    <button className={className} {...rest}>
      {children}
    </button>
  )
}

LinkButton.propTypes = propTypes

export default pure(LinkButton)
