import { isEmpty } from 'lodash'
import { parse } from 'date-fns'

const parseISODate = (dateString) => {
  if (isEmpty(dateString)) return null

  return parse(dateString, 'yyyy-MM-dd', new Date())
}

export default parseISODate
