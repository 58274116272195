import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { selectors as memberSelectors } from 'member-reducer'
import { ReviewOrderForm } from 'member-portal/forms'
import {
  AppliedDiscountCodeType,
  BillingType,
  ContactType,
  MembershipType,
  ProductPrices,
  ReferralType,
} from 'types'
import { isNil } from 'lodash'
import { handleSubmitFailWithFlashMessage } from 'utils'

const propTypes = {
  billing: BillingType,
  canEditBoat: PropTypes.bool,
  canEditContactInfo: PropTypes.bool,
  canEditProductSelections: PropTypes.bool,
  confirmAndFinalizePurchase: PropTypes.func.isRequired,
  contact: ContactType,
  discountCodeDetails: AppliedDiscountCodeType,
  isReferralMatch: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membership: MembershipType.isRequired,
  membershipPrices: ProductPrices.isRequired,
  nextStep: PropTypes.string.isRequired,
  paymentPath: PropTypes.string.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  referralCreditsUsed: PropTypes.number,
  referredByMemberNumber: PropTypes.string,
  referralInfo: ReferralType,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  showDonation: PropTypes.bool,
  showMembershipCost: PropTypes.bool,
  showTrailerCareCost: PropTypes.bool,
  trailerCarePrices: ProductPrices.isRequired,
  trialDuration: PropTypes.number,
}

function ReviewStep({
  billing,
  canEditBoat = false,
  canEditContactInfo = false,
  canEditProductSelections = false,
  confirmAndFinalizePurchase,
  contact,
  discountCodeDetails,
  isReferralMatch,
  isTrialMembershipsActive,
  membership,
  membershipPrices,
  nextStep,
  paymentPath,
  promoCodeIsTrial,
  referralCreditsUsed = 0,
  referralInfo,
  referredByMemberNumber,
  selectedMembershipType,
  selectedTrailerCareType,
  showDonation = false,
  showMembershipCost = false,
  showTrailerCareCost = false,
  trailerCarePrices,
  trialDuration,
}) {
  const history = useHistory()

  // If user refreshes page and loses billing information in state, redirects to
  // the Billing Page.
  if (isNil(billing)) {
    return <Redirect to={paymentPath} />
  }

  return (
    <ReviewOrderForm
      {...{
        billing,
        contact,
        discountCodeDetails,
        canEditBoat,
        canEditContactInfo,
        canEditProductSelections,
        isReferralMatch,
        membership,
        membershipPrices,
        paymentPath,
        referralCreditsUsed,
        referralInfo,
        referredByMemberNumber,
        selectedMembershipType,
        selectedTrailerCareType,
        showDonation,
        showMembershipCost,
        showTrailerCareCost,
        trailerCarePrices,
      }}
      {...(isTrialMembershipsActive && {
        promoCodeIsTrial,
        trialDuration,
      })}
      initialValues={{ smsOptIn: true }}
      onSubmit={confirmAndFinalizePurchase}
      onSubmitSuccess={() => {
        history.push(nextStep)
      }}
      onSubmitFail={handleSubmitFailWithFlashMessage}
    />
  )
}

ReviewStep.propTypes = propTypes

function mapStateToProps(state) {
  return {
    billing: memberSelectors.billing(state),
    contact: memberSelectors.contact(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isReferralMatch: memberSelectors.isReferralMatch(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
    referralInfo: memberSelectors.referralInfo(state),
    referredByMemberNumber: memberSelectors.referredByMemberNumber(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
    trialDuration: memberSelectors.trialDuration(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReviewStep)
