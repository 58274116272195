import React from 'react'
import PropTypes from 'prop-types'
import { AccountType, ContactType, MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { EditMemberContactInfo } from 'member-portal/components'
import { makeMembershipIdPath, PATH } from 'config'

const propTypes = {
  initialAccount: AccountType.isRequired,
  initialContact: ContactType.isRequired,
  membershipToRenew: MembershipType.isRequired,
  applicationMembershipId: PropTypes.string.isRequired,
  autoCompleteAddress: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  updateMembershipApplicationAccount: PropTypes.func.isRequired,
}

function RenewalContactInfo({
  initialAccount,
  initialContact,
  membershipToRenew,
  applicationMembershipId,
  autoCompleteAddress,
  fetchPlaceDetails,
  updateMembershipApplicationAccount,
}) {
  const successPath = makeMembershipIdPath(
    membershipToRenew.sfid,
    PATH.RENEW_MEMBERSHIP,
    PATH.BOATS
  )

  return (
    <EditMemberContactInfo
      {...{
        initialAccount,
        initialContact,
        applicationMembershipId,
        autoCompleteAddress,
        fetchPlaceDetails,
        successPath,
        updateMembershipApplicationAccount,
      }}
    />
  )
}

RenewalContactInfo.propTypes = propTypes

function mapStateToProps(state) {
  return {
    initialAccount: memberSelectors.account(state),
    initialContact: memberSelectors.contact(state),
    membershipToRenew: memberSelectors.membership(state),
    applicationMembershipId: memberSelectors.applicationMembershipId(state),
  }
}

const mapDispatchToProps = {
  autoCompleteAddress: apiActions.autoCompleteAddress,
  fetchPlaceDetails: apiActions.fetchPlaceDetails,
  updateMembershipApplicationAccount:
    apiActions.updateMembershipApplicationAccount,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalContactInfo
)
