import React from 'react'
import { HomePortTypeSection } from 'memberships/boats/components/'
import { PATH, makeMemberApplicationPath } from 'config'

const propTypes = {}

function NewMemberHomePortType() {
  return (
    <>
      <HomePortTypeSection
        nextStep={makeMemberApplicationPath(PATH.BOATS, PATH.HOME_PORT_ADDRESS)}
      />
    </>
  )
}

NewMemberHomePortType.propTypes = propTypes

export default NewMemberHomePortType
