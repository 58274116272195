const parseCreditCardAndBillingAddress = (billing) => {
  const {
    account: { address: street, city, state, country, postalCode: postalcode },
    autoRenewalAgreement: auto_renewal_agreement,
    cardNumber: cc_number,
    cardExpirationDate: exp_date,
    securityCode: ccv,
  } = billing
  const billingaddress = {
    street,
    city,
    state,
    postalcode,
    country,
  }
  const creditCardDetails = {
    billingaddress,
    cc_number,
    exp_date,
    ccv,
  }

  if (auto_renewal_agreement) {
    creditCardDetails.auto_renewal_agreement = auto_renewal_agreement
  }

  return creditCardDetails
}

export default parseCreditCardAndBillingAddress
