import { isEmpty } from 'lodash'

// Checkbox group values are returned from the API as a string separated by semicolons,
// but must be an array for the CheckboxGroup component

function checkboxGroupSplit(stringOfValues) {
  if (isEmpty(stringOfValues)) return []

  return stringOfValues.split(';')
}

export default checkboxGroupSplit
