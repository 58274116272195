import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { OfferCategoryImage } from 'components'

const propTypes = {
  savingsClubCategoryPath: PropTypes.string.isRequired,
  savingsClubCategoryType: PropTypes.string.isRequired,
}

function CategoryCard({ savingsClubCategoryPath, savingsClubCategoryType }) {
  return (
    <div className="card background-image">
      <Link to={savingsClubCategoryPath}>
        <OfferCategoryImage category={savingsClubCategoryType} />
        <span>{savingsClubCategoryType}</span>
      </Link>
    </div>
  )
}

CategoryCard.propTypes = propTypes

export default CategoryCard
